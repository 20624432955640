import React from 'react';
import { useFeatureFlags } from '../../../../hooks/useFeatureFlags';
import { useUser } from '../../../../hooks/user';
import Sidebar from './Sidebar.component';
import { filterPermissions } from './Sidebar.helpers';

const SidebarContainer = (props) => {
  const { user } = useUser();
  const { featureFlags } = useFeatureFlags();
  const items = filterPermissions(user, featureFlags);

  return <Sidebar items={items} {...props} />;
};

export default SidebarContainer;
