import get from 'lodash/get';
import { RESOURCE_TYPES, REGIONS } from '../constants';
import filterByFeatureFlag from './filterByFeatureFlag';
import { getUserRegion } from './helpers';

function filterPermissions(elements, user, featureFlags = {}) {
  const {
    resourceType = RESOURCE_TYPES.rec,
    permissions = {},
    userOrganization = {},
  } = user || {};

  const organization = userOrganization?.organization || {};
  const accountType = organization?.account_type || {};
  const region = getUserRegion(user) || REGIONS.mrets;

  return elements.filter((item) => {
    if (item.featureFlag && !featureFlags[item.featureFlag]?.isActive) {
      return false;
    }

    if (item.region) {
      const itemRegions = new Set(
        Array.isArray(item.region) ? item.region : [item.region],
      );

      if (!itemRegions.has(region)) {
        return false;
      }
    }

    if (item.resourceType) {
      const itemResourceTypes = new Set(
        Array.isArray(item.resourceType)
          ? item.resourceType
          : [item.resourceType],
      );

      if (!itemResourceTypes.has(resourceType)) {
        return false;
      }
    }

    if (item.regionalAdmin && permissions.regionalAdmin) {
      return true;
    }

    if (item.accountType) {
      const keys = Object.keys(item.accountType);
      const isAccountTypeValid = keys.reduce((prev, current) => {
        const currentValue = item.accountType[current];

        let isValid = prev;
        if (currentValue?.type === 'hideIf') {
          isValid = isValid && currentValue.value !== accountType[current];
        } else {
          isValid = isValid && currentValue === accountType[current];
        }

        return isValid;
      }, true);

      if (!isAccountTypeValid) {
        return false;
      }
    }

    if (
      item.isOrgAttribute &&
      !user.isOrgAttribute(item.isOrgAttribute, true)
    ) {
      return false;
    }

    // return if no permissions
    if (!item.permissions) {
      return true;
    }

    // return if permissions are empty
    if (item.permissions.length && item.permissions.length === 0) {
      return true;
    }

    if (permissions.regionalAdmin && permissions.adminModeEnabled) {
      return true;
    }

    if (item.organization) {
      const keys = Object.keys(item.organization);
      const isOrganizationValid = keys.reduce((prev, current) => {
        const currentValue = item.organization[current];

        let isValid = prev;
        if (currentValue?.type === 'hideIf') {
          isValid = isValid && currentValue.value !== organization[current];
        } else {
          isValid = isValid && currentValue === organization[current];
        }

        return isValid;
      }, true);

      if (!isOrganizationValid) {
        return false;
      }
    }

    let valid = true;

    item.permissions.forEach((rule) => {
      if (rule && rule.resourceType) {
        const ruleResourceTypes = new Set(
          Array.isArray(rule.resourceType)
            ? rule.resourceType
            : [rule.resourceType],
        );

        if (!ruleResourceTypes.has(resourceType)) {
          valid = false;
        }
      }

      if (valid && rule && rule.type) {
        const isShowIf = rule.type === 'showIf';
        let isValidForRule = rule.strict;

        if (rule.type === 'featureFlag') {
          const result = filterByFeatureFlag([rule], featureFlags);

          valid = result.length === 1;
        } else {
          const values = Array.isArray(rule.value) ? rule.value : [rule.value];
          isValidForRule = values.reduce((isPrevValid, current) => {
            const isCurrentValid = get(permissions, current, false);
            return rule.strict
              ? isPrevValid && isCurrentValid
              : isPrevValid || isCurrentValid;
          }, isValidForRule);

          valid = valid && (isShowIf ? isValidForRule : !isValidForRule);
        }
      }
    });

    return valid;
  });
}

export default filterPermissions;
