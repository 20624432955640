const name = 'issue_to_account';

const shape = {
  name: '',
};

const model = {
  name,
  shape,
};

export default model;
