const name = 'carbon_pathway';

const shape = {
  name: '',
  tool_name: '',
  carbon_intensity: '',
  start_date: '',
  grams_co2_per_dth: 0,
  end_date: '',
  injection_point: '',
  endpoint: '',
  endpoint_description: '',
  document_name: '',
  document_url: '',
  private_document_name: '',
  private_document_url: '',
  certificates: {
    type: 'certificates',
    jsonApi: 'hasMany',
  },
  transaction_details: {
    type: 'transaction_details',
    jsonApi: 'hasMany',
  },
};

const model = {
  name,
  shape,
};

export default model;
