import BaseAPI from './base';

class TicketAPI extends BaseAPI {
  async getTicket(ticketId) {
    const response = await this.jsonApi.find('ticket', ticketId, {
      include: ['ticket_documents', 'request_user'],
    });
    return response;
  }

  async acceptTicket(ticketId) {
    const response = await this.axios.put(
      this.buildUrl(`/tickets/${ticketId}/accept`),
    );
    return response;
  }

  async rejectTicket(ticketId) {
    const response = await this.axios.put(
      this.buildUrl(`/tickets/${ticketId}/reject`),
    );
    return response;
  }

  async closeTicket(ticketId) {
    const response = await this.axios.put(
      this.buildUrl(`/tickets/${ticketId}/close`),
    );
    return response;
  }
}

export default TicketAPI;
