import BaseAPI from '../base';
import { formatQueryData } from '../helpers';

class InterconnectedUtilityAPI extends BaseAPI {
  async getInterconnectedUtilities(queryData) {
    const newQueryData = formatQueryData(queryData);

    const response = await this.jsonApi.findAll('interconnected_utility', {
      ...newQueryData,
    });

    return response;
  }
}

export default InterconnectedUtilityAPI;
