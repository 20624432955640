import BaseAPI from './base';
import { formatQueryData } from './helpers';

class EligibilityBondAPI extends BaseAPI {
  async getEligibilityBonds(queryData) {
    const newQueryData = formatQueryData(queryData);
    const response = await this.jsonApi.findAll('eligibility_bond', {
      ...newQueryData,
      include: ['eligibility'],
      fields: {
        eligibility_bonds: [
          'details',
          'related_generators',
          'start_date',
          'verified_date',
          'good_thru_date',
          'eligibility',
          'certification_number',
          'details',
        ],
      },
    });
    return response;
  }

  async createEligibilityBond(payload) {
    return this.jsonApi.create('eligibility_bond', payload);
  }

  async updateEligibilityBond(payload) {
    return this.jsonApi.update('eligibility_bond', payload);
  }
}

export default EligibilityBondAPI;
