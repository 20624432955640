import BaseAPI from '../base';
import { formatQueryData, getIncludeValues } from '../helpers';
import { GENERATOR_AGGREGATES } from './generatorAggregate.constants';

class GeneratorAggregateAPI extends BaseAPI {
  async getGeneratorAggregates(queryData) {
    const newQueryData = formatQueryData(queryData);

    const response = await this.jsonApi.findAll('generator_aggregate', {
      ...newQueryData,
      include: getIncludeValues(
        newQueryData.unique_by,
        GENERATOR_AGGREGATES[this.resourceType],
      ),
    });

    return response;
  }

  async createGeneratorAggregate(payload) {
    return this.jsonApi.create('generator_aggregate', payload);
  }

  async updateGeneratorAggregate(id, payload) {
    return this.jsonApi.update('generator_aggregate', { id, ...payload });
  }

  async addGeneratorToGeneratorAggregate(generatorAggregateId, generatorId) {
    const response = await this.axios.post(
      this.buildUrl(
        `/generator_aggregates/${generatorAggregateId}/add_generator?include[]=qre`,
      ),
      { generator_id: generatorId },
    );

    const jsonApiResource = this.jsonApi.fromAxios(response);

    return {
      ...response,
      data: jsonApiResource,
    };
  }

  async removeGeneratorToGeneratorAggregate(generatorAggregateId, generatorId) {
    const response = await this.axios.post(
      this.buildUrl(
        `/generator_aggregates/${generatorAggregateId}/remove_generator?include[]=qre`,
      ),
      { generator_id: generatorId },
    );

    const jsonApiResource = this.jsonApi.fromAxios(response);

    return {
      ...response,
      data: jsonApiResource,
    };
  }
}

export default GeneratorAggregateAPI;
