const name = 'certificate_quantity';

const shape = {
  quantity: 0,
  organization_name: '',
  serial_number_start: 0,
  serial_number_end: 0,
  created_at: '',
  updated_at: '',
  notes: '',
  status: '',
  generator_pipeline_connected: false,
  has_carbon_pathways: false,
  certificate: {
    type: 'certificates',
    jsonApi: 'hasOne',
  },
  account: {
    type: 'accounts',
    jsonApi: 'hasOne',
  },
  transaction_detail: {
    type: 'transaction_details',
    jsonApi: 'hasOne',
  },
};

const model = {
  name,
  shape,
};

export default model;
