const name = 'dashboard_notification';

const shape = {
  key: '',
  kind: '',
  priority: '',
  created_at: '',
  message: '',
  notifiable: {
    jsonApi: 'hasOne',
  },
  target: {
    jsonApi: 'hasOne',
  },
};

const model = {
  name,
  shape,
};

export default model;
