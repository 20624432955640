import JsonApi from 'devour-client';
import axios from 'axios';
import get from 'lodash/get';
import { RESOURCE_TYPES, REGIONS_NAMES } from '../constants';
import models from '../models';
import { errorMiddleware } from './middleware';
import { getRegionByDomain } from '../utils/helpers';

const headers = { Accept: 'application/vnd.api+json' };
const requestMiddleware = {
  name: 'add-authentication',
  req: (payload) => {
    const initialHeaders = get(payload, 'req.headers', {});
    const token = sessionStorage.getItem('token');
    const region = REGIONS_NAMES[getRegionByDomain()];
    let reqHeaders = {
      ...initialHeaders,
      'X-Region': region,
    };
    if (token) {
      reqHeaders = {
        ...reqHeaders,
        Authorization: token,
      };
    }
    // eslint-disable-next-line no-param-reassign
    payload.req.headers = {
      ...reqHeaders,
    };

    return payload;
  },
};
const jsonApiErrorMiddleware = {
  name: 'check-expired-session',
  error: errorMiddleware,
};

const createJsonApiInstance = (resourceType = RESOURCE_TYPES.rec) => {
  const jsonApiInstance = new JsonApi({
    apiUrl: `${process.env.REACT_APP_API_ROOT}/v1/public/${resourceType}`,
    logger: false,
  });

  jsonApiInstance.axios = axios;
  jsonApiInstance.headers = headers;
  jsonApiInstance.insertMiddlewareAfter('HEADER', requestMiddleware);
  jsonApiInstance.replaceMiddleware('errors', jsonApiErrorMiddleware);

  const resourceTypeModels = Object.values(models[resourceType]);

  // Define models
  resourceTypeModels.forEach(({ name, shape, options = {} }) => {
    jsonApiInstance.define(name, shape, options);
  });

  jsonApiInstance.customRequest = async (
    method,
    url,
    model,
    params = {},
    data = {},
    meta = {},
  ) => {
    const req = {
      method,
      url,
      model,
      data,
      params,
      meta,
    };
    const response = await jsonApiInstance.runMiddleware(req);
    return response;
  };

  jsonApiInstance.fromAxios = (response) => {
    return jsonApiInstance.deserialize.resource.call(
      jsonApiInstance,
      response.data.data,
      response.data.included,
    );
  };

  return jsonApiInstance;
};

export default createJsonApiInstance;
