import React from 'react';
import PropTypes from 'prop-types';
import Logo from '../Main/Logo';
import { useI18N } from '../../../hooks/i18n';

import './Public.scss';

const currentYear = new Date().getFullYear();

const Public = ({ children }) => {
  const { i18nText } = useI18N();

  return (
    <div className="PublicLayout PublicLayout__Container">
      <div className="PublicLayout__Logo">
        <Logo width="232" height="64" color="#4D4D4D" />
      </div>
      <div className="PublicLayout__Content">{children}</div>
      <div className="PublicLayout__Footer">
        {i18nText('public.footer.copyright', {
          currentYear,
          // eslint-disable-next-line react/no-unstable-nested-components
          a: (chunks) => <a href={i18nText('public.website')}>{chunks}</a>,
        })}
      </div>
    </div>
  );
};

Public.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Public;
