import queryString from 'query-string';
import { RESOURCE_TYPES } from '../constants';

export const getSortOptions = (queryData) => {
  let sortData = {};
  if (queryData.sortBy) {
    const sortByKeys = Object.keys(queryData.sortBy || {});

    const idIndex = sortByKeys.indexOf('id');
    if (idIndex > -1) {
      sortByKeys.splice(idIndex, 1);
      sortByKeys.push('id');
    }

    const sort = sortByKeys
      .map((sb) => `${queryData.sortBy[sb] === 'desc' ? '-' : ''}${sb}`)
      .join(',');
    sortData = { sort };
  }

  return sortData;
};

export const getFilterData = (queryData) => {
  const filtersKeys = Object.keys(queryData.filters || {});

  const filters = filtersKeys.reduce((prev, current) => {
    const currentFilterData = queryData.filters[current];
    const currentFilterValue = currentFilterData?.filterKey
      ? currentFilterData.value
      : currentFilterData;

    let filterKeys = [current];
    if (currentFilterData?.filterKey) {
      filterKeys = Array.isArray(currentFilterData.filterKey)
        ? currentFilterData.filterKey
        : [currentFilterData.filterKey];

      filterKeys = filterKeys.filter((filterKey) => {
        if (typeof filterKey !== 'string' && filterKey.shouldApply) {
          return filterKey.shouldApply(currentFilterValue);
        }
        return true;
      });

      filterKeys = filterKeys.map((filterKey) =>
        typeof filterKey !== 'string' && filterKey.key
          ? filterKey.key
          : filterKey,
      );
    }

    const currentFilterValues = filterKeys.reduce(
      (prevCurrentFilterData, currentFilterKey, index) => {
        let currentFilterKeyValue = currentFilterValue;
        const filterQueryKey = `filter[${currentFilterKey}]`;

        if (currentFilterData?.filterKey) {
          currentFilterKeyValue =
            currentFilterData.filter.isRange &&
            Array.isArray(currentFilterValue)
              ? currentFilterValue[index]
              : currentFilterValue;
        }

        return {
          ...prevCurrentFilterData,
          [filterQueryKey]: currentFilterKeyValue,
        };
      },
      {},
    );

    return {
      ...prev,
      ...currentFilterValues,
    };
  }, {});

  return filters;
};

export const getPageData = (queryData) => {
  let pageData = {};
  if (queryData.page) {
    const {
      page: { number = 1, size = 25 },
    } = queryData;

    pageData = { page: { number, size } };

    if (queryData.page.disabled) {
      pageData = { page: { disabled: true } };
    }
  }

  return pageData;
};

export const getSummaryData = (queryData) => {
  let summaryData = {};
  if (queryData.calc) {
    const {
      calc: { func, attr },
    } = queryData;

    summaryData = { 'calc[func]': func, 'calc[attr]': attr };
  }

  return summaryData;
};

export const formatQueryData = (queryData, override = {}) => {
  const newQueryData = {
    ...queryData,
    ...getSortOptions(queryData),
    ...getFilterData(queryData),
    ...getPageData(queryData),
    ...getSummaryData(queryData),
    ...override,
  };

  delete newQueryData.sortBy;
  delete newQueryData.filters;
  delete newQueryData.calc;

  return newQueryData;
};

export const getSearchParams = (newQueryData, queryParameters) => {
  return newQueryData.unique_by && newQueryData.unique_path
    ? { include: newQueryData.unique_path }
    : queryParameters;
};

export const queryDataToQueryParams = (queryData, override = {}) => {
  const queryDataObj = formatQueryData(queryData, override);

  if (queryDataObj.page) {
    const { number = 1, size = 25 } = queryDataObj.page;
    queryDataObj.page = number;
    queryDataObj.limit = size;
  }

  return queryString.stringify(queryDataObj, { arrayFormat: 'bracket' });
};

export const getIncludeValues = (
  uniqueBy,
  { includeAll, includeUniqueBy },
  resourceType = RESOURCE_TYPES.rec,
) => {
  let includeValues = includeAll?.[resourceType] || includeAll;
  if (uniqueBy) {
    includeValues = includeUniqueBy[uniqueBy] || [];
  }
  return includeValues;
};

export const getFieldsValues = (
  uniqueBy,
  { fieldsAll, fieldsUniqueBy, ...other },
  resourceType = RESOURCE_TYPES.rec,
) => {
  let fieldsValues = fieldsAll?.[resourceType] || fieldsAll;

  if (!fieldsValues) {
    fieldsValues = other?.[resourceType] || other;
    fieldsValues = fieldsValues?.fieldsAll || fieldsValues;
  }
  if (uniqueBy) {
    fieldsValues =
      fieldsUniqueBy?.[uniqueBy] ||
      fieldsValues?.fieldsUniqueBy?.[resourceType]?.[uniqueBy] ||
      fieldsValues?.fieldsUniqueBy?.[uniqueBy] ||
      fieldsValues?.[uniqueBy] ||
      other?.[resourceType]?.fieldsUniqueBy?.[uniqueBy] ||
      {};
  }

  return fieldsValues;
};
