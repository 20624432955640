const name = 'generator_fuel';

const shape = {
  label: '',
  updated_at: '',
  created_at: '',
  remainder_mwh: 0.0,
  use_new_allocations_attributes: '',
  fuel_source: {
    type: 'fuel_sources',
    jsonApi: 'hasOne',
  },
  eligibilities: {
    type: 'eligibilities',
    jsonApi: 'hasMany',
  },
  eligibility_bonds: {
    type: 'eligibility_bonds',
    jsonApi: 'hasMany',
  },
  generator: {
    type: 'generators',
    jsonApi: 'hasOne',
  },
  remainder_adjustments: {
    type: 'generator_fuel_remainder_adjustments',
    jsonApi: 'hasMany',
  },
};

const model = {
  name,
  shape,
};

export default model;
