import BaseAPI from './base';

class FiltersConfigAPI extends BaseAPI {
  async getFiltersConfig(resourceName) {
    const response = await this.axios.get(
      this.buildUrl(`/${resourceName}/filters_config`),
    );

    return response;
  }
}

export default FiltersConfigAPI;
