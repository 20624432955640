import React from 'react';

const HelpIcon = () => {
  return (
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.729492 10.75C0.729492 16.6871 5.54243 21.5 11.4795 21.5C17.4166 21.5 22.2295 16.6871 22.2295 10.75C22.2295 4.81294 17.4166 0 11.4795 0C5.54243 0 0.729492 4.81294 0.729492 10.75ZM20.7295 10.75C20.7295 15.8586 16.5881 20 11.4795 20C6.37086 20 2.22949 15.8586 2.22949 10.75C2.22949 5.64137 6.37086 1.5 11.4795 1.5C16.5881 1.5 20.7295 5.64137 20.7295 10.75Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.96749 6.554C7.64216 7.074 7.47949 7.58733 7.47949 8.094C7.47949 8.33933 7.58216 8.56733 7.78749 8.778C7.99283 8.98867 8.24482 9.094 8.54349 9.094C9.05016 9.094 9.39416 8.79267 9.57549 8.19C9.76749 7.614 10.0022 7.178 10.2795 6.882C10.5568 6.586 10.9888 6.438 11.5755 6.438C12.0768 6.438 12.4862 6.58467 12.8035 6.878C13.1208 7.17133 13.2795 7.53133 13.2795 7.958C13.2795 8.17667 13.2275 8.37933 13.1235 8.566C13.0195 8.75267 12.8915 8.922 12.7395 9.074C12.5875 9.226 12.3408 9.45133 11.9995 9.75C11.6102 10.0913 11.3008 10.386 11.0715 10.634C10.8422 10.882 10.6582 11.17 10.5195 11.498C10.3808 11.826 10.3115 12.214 10.3115 12.662C10.3115 13.0193 10.4062 13.2887 10.5955 13.47C10.7848 13.6513 11.0182 13.742 11.2955 13.742C11.8288 13.742 12.1462 13.4647 12.2475 12.91C12.3062 12.6487 12.3502 12.466 12.3795 12.362C12.4088 12.258 12.4502 12.154 12.5035 12.05C12.5568 11.946 12.6382 11.8313 12.7475 11.706C12.8568 11.5807 13.0022 11.4353 13.1835 11.27C13.8395 10.6833 14.2942 10.266 14.5475 10.018C14.8008 9.77 15.0195 9.47533 15.2035 9.134C15.3875 8.79267 15.4795 8.39534 15.4795 7.942C15.4795 7.366 15.3182 6.83267 14.9955 6.342C14.6728 5.85133 14.2155 5.46333 13.6235 5.178C13.0315 4.89267 12.3488 4.75 11.5755 4.75C10.7435 4.75 10.0155 4.92066 9.39149 5.262C8.76749 5.60334 8.29283 6.034 7.96749 6.554ZM10.5315 16.546C10.7742 16.7567 11.0582 16.862 11.3835 16.862C11.6982 16.862 11.9755 16.7553 12.2155 16.542C12.4555 16.3287 12.5755 16.0353 12.5755 15.662C12.5755 15.326 12.4608 15.0433 12.2315 14.814C12.0022 14.5847 11.7195 14.47 11.3835 14.47C11.0422 14.47 10.7542 14.5847 10.5195 14.814C10.2848 15.0433 10.1675 15.326 10.1675 15.662C10.1675 16.0407 10.2888 16.3353 10.5315 16.546Z"
        fill="white"
      />
    </svg>
  );
};

export default HelpIcon;
