import React from 'react';

const HelpIcon = () => {
  return (
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9961 0H3.47949C1.96071 0 0.729492 1.23122 0.729492 2.75V18.75C0.729492 20.2688 1.96071 21.5 3.47949 21.5H19.4795C20.9983 21.5 22.2295 20.2688 22.2295 18.75V7.44873V6.75C22.2295 6.54101 22.1423 6.3415 21.9889 6.19955L15.5055 0.199546C15.3669 0.071261 15.185 0 14.9961 0ZM20.3199 6.69873L15.7461 2.46596V6.69873H20.3199ZM14.2461 1.5V7.44873C14.2461 7.86294 14.5819 8.19873 14.9961 8.19873H20.7295V18.75C20.7295 19.4404 20.1698 20 19.4795 20H3.47949C2.78914 20 2.22949 19.4404 2.22949 18.75V2.75C2.22949 2.05964 2.78914 1.5 3.47949 1.5H14.2461ZM5.59233 13C5.17811 13 4.84233 12.6642 4.84233 12.25C4.84233 11.8358 5.17811 11.5 5.59233 11.5H17.5923C18.0065 11.5 18.3423 11.8358 18.3423 12.25C18.3423 12.6642 18.0065 13 17.5923 13H5.59233ZM4.84233 16.25C4.84233 16.6642 5.17811 17 5.59233 17H17.5923C18.0065 17 18.3423 16.6642 18.3423 16.25C18.3423 15.8358 18.0065 15.5 17.5923 15.5H5.59233C5.17811 15.5 4.84233 15.8358 4.84233 16.25ZM5.59233 9.11287C5.17811 9.11287 4.84233 8.77709 4.84233 8.36287C4.84233 7.94866 5.17811 7.61287 5.59233 7.61287H11.5923C12.0065 7.61287 12.3423 7.94866 12.3423 8.36287C12.3423 8.77709 12.0065 9.11287 11.5923 9.11287H5.59233Z"
        fill="white"
      />
    </svg>
  );
};

export default HelpIcon;
