import BaseAPI from './base';

class ApiKeyAPI extends BaseAPI {
  async getApiKeys() {
    const response = await this.jsonApi.findAll('api_key');

    return response;
  }

  async createApiKey() {
    const response = await this.jsonApi.create('api_key', {});

    return response;
  }

  async revokeApiKey(apiKey) {
    const url = `${this.jsonApi.urlFor({
      model: 'api_key',
      id: apiKey.id,
    })}/revoke`;

    return this.jsonApi.customRequest('PATCH', url, 'api_key');
  }
}

export default ApiKeyAPI;
