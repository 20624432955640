import { get } from 'lodash';
import BaseAPI from './base';
import saveFileAs from '../utils/saveFileAs';
import { queryDataToQueryParams } from './helpers';
import { MIME_TYPES } from './constants';
import { parseDateToString } from '../utils/helpers';

class DownloadAPI extends BaseAPI {
  async downloadFile(uri, urlAttr) {
    const response = await this.axios.get(uri);

    const responseUrl = get(response.data, urlAttr, null);
    if (response && response.data && responseUrl) {
      window.open(responseUrl, '_blank');
    }
  }

  async downloadImage(uri) {
    const response = await this.axios.get(uri, { responseType: 'blob' });

    return new Promise((resolve, reject) => {
      try {
        const reader = new FileReader();
        reader.onload = (e) => {
          resolve(e.target.result);
        };
        reader.readAsDataURL(response.data);
      } catch (err) {
        reject(err);
      }
    });
  }

  async downloadTableData(uri, filename, queryData, downloadExtension) {
    const queryParamsSuffix = queryDataToQueryParams(queryData);

    const contentType =
      MIME_TYPES[downloadExtension.toLowerCase()] || MIME_TYPES.default;

    const response = await this.axios.get(
      `${uri}.${downloadExtension}?${queryParamsSuffix}`,
      {
        headers: {
          Accept: contentType,
        },
        responseType: 'blob',
      },
    );

    if (response.status === 200) {
      this.downloadResponseFile(response, filename, downloadExtension);
    }

    return response;
  }

  // eslint-disable-next-line class-methods-use-this
  async downloadResponseFile(response, filename, downloadExtension) {
    const contentType =
      MIME_TYPES[downloadExtension.toLowerCase()] || MIME_TYPES.default;
    const date = parseDateToString(new Date(), 'MM-dd-yyyy');
    saveFileAs(
      `${filename}-${date}.${downloadExtension}`,
      response.data,
      contentType,
    );
  }

  async downloadPlainFile(uri, filename, extension) {
    const response = await this.axios.get(uri);

    return this.downloadResponseFile(response, filename, extension);
  }
}

export default DownloadAPI;
