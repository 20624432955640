const name = 'feature_flag';

const shape = {
  key: '',
  status: 0,
};

const model = {
  name,
  shape,
};

export default model;
