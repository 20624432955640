const name = 'certificate';

const shape = {
  name: '',
  start_date: '',
  end_date: '',
  carbon_intensity: '',
  injection_point: '',
  tool_name: '',
  endpoint: '',
  endpoint_description: '',
  created_at: '',
  updated_at: '',
  vintage_date: '',
  import_source: '',
  serial_number_base: '',
  serial_number_start: 0,
  serial_number_end: 0,
  quantity: 0,
  generator: {
    type: 'generators',
    jsonApi: 'hasOne',
  },
  generation_entry: {
    type: 'generation_entries',
    jsonApi: 'hasOne',
  },
  generator_fuel: {
    type: 'generator_fuels',
    jsonApi: 'hasOne',
  },
  thermal_resource: {
    type: 'thermal_resources',
    jsonApi: 'hasOne',
  },
  eligibilities: {
    type: 'eligibilities',
    jsonApi: 'hasMany',
  },
  eligibility_bonds: {
    type: 'eligibility_bonds',
    jsonApi: 'hasMany',
  },
  carbon_pathways: {
    type: 'carbon_pathways',
    jsonApi: 'hasMany',
  },
};

const model = {
  name,
  shape,
};

export default model;
