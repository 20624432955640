import BaseAPI from './base';

class FeatureFlagAPI extends BaseAPI {
  async getFeatureFlags() {
    const response = await this.axios.get(`/v1/public/feature_flags`);

    return response.data.data;
  }

  async getFeatureFlagsDict() {
    const response = await this.getFeatureFlags();

    return generateFeatureFlagDictionary(response);
  }

  async updateFeatureFlag(featureFlagId) {
    const response = await this.axios.put(
      `/v1/public/feature_flags/${featureFlagId}/toggle`,
    );

    return response.data.data;
  }
}

export function generateFeatureFlagDictionary(flags) {
  // Reduce the array of feature flags to a dictionary for easy lookup
  return flags.reduce((prev, cur) => {
    const newVal = { ...prev };

    if (cur?.attributes?.key) {
      newVal[cur.attributes.key] = {
        id: cur.id,
        isActive: cur.attributes.status
          ? cur.attributes.status === 'active'
          : false,
        ...cur.attributes,
      };

      delete newVal[cur.attributes.key].key;
    }

    return newVal;
  }, {});
}

export default FeatureFlagAPI;
