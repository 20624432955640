import BaseAPI from './base';
import { formatQueryData, getIncludeValues } from './helpers';
import { GENERATOR_FUELS, GENERATOR_FUELS_FIELDS } from './constants';

class GeneratorFuelAPI extends BaseAPI {
  async getFuelSources(queryData = {}) {
    const newQueryData = formatQueryData(queryData);

    const response = await this.jsonApi.findAll('generator_fuel', {
      ...newQueryData,
      include: getIncludeValues(
        newQueryData.unique_by,
        GENERATOR_FUELS[this.resourceType],
      ),
      fields: GENERATOR_FUELS_FIELDS[this.resourceType],
    });

    return response;
  }
}

export default GeneratorFuelAPI;
