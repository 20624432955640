const name = 'fuel_type';

const shape = {
  code: '',
  name: '',
  is_renewable: false,
  is_biomass: false,
};

const model = {
  name,
  shape,
};

export default model;
