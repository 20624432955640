import BaseAPI from './base';
import { formatQueryData } from './helpers';

class ProgramParticipantInviteAPI extends BaseAPI {
  async getProgramParticipantInvites(queryData) {
    const newQueryData = formatQueryData(queryData);

    const response = await this.jsonApi.findAll('program_participant_invite', {
      ...newQueryData,
      include: ['program.administrator', 'organization'],
    });

    return response;
  }

  async createProgramParticipantInvite(payload) {
    return this.jsonApi.create('program_participant_invite', payload);
  }

  async cancelProgramParticipantInvite(programParticipantInviteId) {
    const url = `${this.jsonApi.urlFor({
      model: 'program_participant_invite',
      id: programParticipantInviteId,
    })}/cancel`;

    return this.jsonApi.customRequest(
      'POST',
      url,
      'program_participant_invite',
    );
  }

  async acceptProgramParticipantInvite(programParticipantInviteId) {
    const url = `${this.jsonApi.urlFor({
      model: 'program_participant_invite',
      id: programParticipantInviteId,
    })}/accept`;

    return this.jsonApi.customRequest(
      'POST',
      url,
      'program_participant_invite',
    );
  }

  async rejectProgramParticipantInvite(programParticipantInviteId) {
    const url = `${this.jsonApi.urlFor({
      model: 'program_participant_invite',
      id: programParticipantInviteId,
    })}/reject`;

    return this.jsonApi.customRequest(
      'POST',
      url,
      'program_participant_invite',
    );
  }
}

export default ProgramParticipantInviteAPI;
