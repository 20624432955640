import React from 'react';
import PropTypes from 'prop-types';

import './MastHead.scss';

const MastHead = ({ children }) => {
  return (
    <header
      role="banner"
      className="MastHead flex justify-between fixed z-4 top-0 left-0 right-0 h3 font-smoothing"
    >
      {children}
    </header>
  );
};

MastHead.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MastHead;
