const name = 'etag_quantity';

const shape = {
  quantity: '',
  quantity_unit: '',
  status: '',
  created_at: '',
  updated_at: '',
  etag: {
    type: 'etags',
    jsonApi: 'hasOne',
  },
  certificate_quantity: {
    type: 'certificate_quantities',
    jsonApi: 'hasOne',
  },
};

const model = {
  name,
  shape,
};

export default model;
