import React from 'react';

const GenerationIcon = () => {
  return (
    <svg
      width="28"
      height="22"
      viewBox="0 0 28 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.1908 5.78142C20.5342 5.5498 20.6249 5.08365 20.3932 4.74025C18.9061 2.53555 16.6979 0.996652 14.1752 0.344528C11.2132 -0.421176 8.05637 0.0947237 5.46975 1.83942C3.94316 2.86912 2.73046 4.24697 1.91048 5.852C0.143253 9.31118 0.356689 13.4831 2.56892 16.7629C5.88889 21.6849 12.5704 22.9837 17.4924 19.6637C17.8358 19.4321 17.9264 18.966 17.6948 18.6226C17.4632 18.2792 16.997 18.1885 16.6536 18.4202C15.2876 19.3415 13.7642 19.8495 12.2311 19.9728V18.7516C12.2311 18.3374 11.8953 18.0016 11.4811 18.0016C11.0669 18.0016 10.7311 18.3374 10.7311 18.7516V19.9715C8.02822 19.7499 5.44693 18.3473 3.81248 15.9241C2.90242 14.5749 2.38541 13.0506 2.25977 11.5016H3.48108C3.89529 11.5016 4.23108 11.1658 4.23108 10.7516C4.23108 10.3374 3.89529 10.0016 3.48108 10.0016H2.26016C2.35747 8.80884 2.68642 7.63024 3.24626 6.53442C3.95145 5.15406 4.99377 3.9698 6.30854 3.08297C7.65279 2.17627 9.17475 1.65528 10.7311 1.52981V2.75157C10.7311 3.16578 11.0669 3.50157 11.4811 3.50157C11.8953 3.50157 12.2311 3.16578 12.2311 2.75157V1.53119C12.7571 1.57448 13.2818 1.66289 13.7998 1.79679C15.9712 2.35811 17.8694 3.6809 19.1497 5.57904C19.3813 5.92244 19.8474 6.01305 20.1908 5.78142ZM17.4811 14.9645C16.875 14.9645 16.5192 14.2829 16.8658 13.7857L22.0669 6.32274C22.494 5.70992 23.4567 6.02999 23.4318 6.77655L23.2999 10.7361H26.4811C27.0929 10.7361 27.4473 11.4292 27.0891 11.9252L21.12 20.1907C20.6717 20.8114 19.6909 20.4404 19.7655 19.6784L20.2275 14.9645H17.4811ZM21.8482 9.25997L18.9179 13.4645H21.0546C21.4977 13.4645 21.8443 13.8467 21.801 14.2877L21.5291 17.0621L25.0143 12.2361H22.5245C22.1005 12.2361 21.7608 11.8849 21.7749 11.4612L21.8482 9.25997ZM15.1465 10.5016C15.5607 10.5016 15.8965 10.8374 15.8965 11.2516C15.8965 11.6658 15.5607 12.0016 15.1465 12.0016H11.4811C11.0669 12.0016 10.7311 11.6658 10.7311 11.2516V5.75157C10.7311 5.33736 11.0669 5.00157 11.4811 5.00157C11.8953 5.00157 12.2311 5.33736 12.2311 5.75157V10.5016H15.1465Z"
        fill="white"
      />
    </svg>
  );
};

export default GenerationIcon;
