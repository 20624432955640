import BaseAPI from '../base';
import { formatQueryData, getFieldsValues, getIncludeValues } from '../helpers';
import {
  TRANSACTION_DETAIL,
  TRANSACTION_DETAIL_FIELDS,
} from './transactionDetail.constants';

class TransactionDetailAPI extends BaseAPI {
  async getTransactionDetails(queryData) {
    const newQueryData = formatQueryData(queryData);
    const response = await this.jsonApi.findAll('transaction_detail', {
      ...newQueryData,
      include: getIncludeValues(
        newQueryData.unique_by,
        TRANSACTION_DETAIL[this.resourceType],
      ),
      fields: getFieldsValues(
        newQueryData.unique_by,
        TRANSACTION_DETAIL_FIELDS[this.resourceType],
        this.resourceType,
      ),
    });

    return response;
  }

  async getTransactionDetail(transactionDetailID) {
    const response = await this.jsonApi.find(
      'transaction_detail',
      transactionDetailID,
      {
        include: TRANSACTION_DETAIL[this.resourceType].includeAll,
        fields: {
          accounts: ['id', 'name', 'organization'],
          organizations: ['name'],
          fuel_types: ['name'],
          eligibilities: ['short_name', 'long_name', 'slug'],
          generators: ['name', 'mrets_id', 'state_province', 'organization'],
        },
      },
    );

    return response;
  }

  async createTransactionDetail(payload) {
    return this.jsonApi.create('transaction_detail', payload);
  }
}

export default TransactionDetailAPI;
