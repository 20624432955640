const name = 'organization_document';

const shape = {
  description: '',
  created_at: '',
  document_name: '',
  document_url: '',
  organization: {
    type: 'organizations',
    jsonApi: 'hasOne',
  },
};

const model = {
  name,
  shape,
};

export default model;
