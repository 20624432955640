import { RESOURCE_TYPES } from '../../constants';

const constructPermissions = (user, org, def) => {
  if (!user || !org) {
    return def;
  }

  const { read = {}, manage = {}, upload = {} } = user.permissions;

  const userReadPermissions = {
    ...manage,
    ...upload,
  };

  Object.keys(read).forEach((readKey) => {
    if (!userReadPermissions[readKey]) {
      userReadPermissions[readKey] = read[readKey];
    }
  });

  const orgAccountType = {
    ...org.account_type,
  };

  const isSuperAdmin = user.super_admin;
  const isRegionalAdmin = user.regional_admin;

  const userHasAccounts =
    orgAccountType.has_accounts &&
    (userReadPermissions.accounts || isRegionalAdmin);

  const orgHasGenerators =
    orgAccountType.owns_projects || orgAccountType.views_external_projects;

  const orgHasPSECodes = !!org.pse_codes?.length;

  const userHasGenerators =
    (orgHasGenerators && userReadPermissions.generators) || isRegionalAdmin;

  const isRTCOrg =
    org &&
    org.resource_type &&
    org.resource_type.toLowerCase() === RESOURCE_TYPES.rtc;

  const canAccessPrograms =
    orgAccountType.can_participate_in_programs ||
    orgAccountType.has_programs !== 'none';

  const adminModeEnabled = user.admin_mode;

  const organizationRegulator =
    org &&
    org.organization_type &&
    org.organization_type ===
      'Program Administrator/State or Provincial Regulator';

  const programAdministrator =
    org &&
    org.account_type &&
    org.account_type.name === 'Program Administrator';

  const isGeneralAccount = !!(orgAccountType.name === 'General Account');
  const hasGeneratorsToReview =
    user.current_organization?.has_generators_to_review;

  const isReportingEntity =
    org && org.account_type && org.account_type.name === 'Reporting Entity';

  const permissions = {
    superAdmin: isSuperAdmin,
    regionalAdmin: isRegionalAdmin,
    adminMode: user.admin_mode_permission,
    adminModeEnabled,
    readMarkets: userReadPermissions.markets || isRegionalAdmin,
    manageMarkets: manage.markets || isRegionalAdmin,
    readGenerators: userReadPermissions.generators,
    manageGenerators:
      isRegionalAdmin || (orgAccountType.owns_projects && manage.generators),
    manageTransactions: manage.transactions,
    readAccounts: userReadPermissions.accounts,
    manageAccounts: manage.accounts,
    generalAccount: isGeneralAccount,
    readTransactions: userReadPermissions.transactions,
    readOrganization: userReadPermissions.organization,
    manageOrganization: manage.organization,
    readRetirements:
      orgAccountType.retire_recs && userReadPermissions.transactions,
    readReserves:
      orgAccountType.reserve_recs && userReadPermissions.transactions,
    canReserve:
      !user.regulator &&
      orgAccountType.reserve_recs &&
      userReadPermissions.transactions,
    canImportGenerators: orgAccountType.name === 'Registry Administrator',
    readTransactionsAndHasAccounts:
      (userReadPermissions.transactions || userReadPermissions.transactions) &&
      userHasAccounts,
    managePrograms:
      (orgAccountType.has_programs !== 'none' && manage.programs) ||
      isRegionalAdmin,
    hasExternalPrograms:
      orgAccountType.has_programs === 'external' &&
      (userReadPermissions.programs || isRegionalAdmin),
    isMarketAdmin: orgAccountType.has_markets,
    isMarketParticipant: org.has_market_accounts,
    isReportingEntity,
    organizationHasEncumberedQuantities:
      org.has_market_accounts || orgAccountType.has_markets,
    organizationRegulator,
    organizationIsQre: org.qre,
    programAdministrator,
    hasGeneratorsToReview,
    systemReportsAccess: orgAccountType.view_system_reports,
    uploadGeneration:
      orgAccountType.upload_generation &&
      (upload.generation || user.admin_mode_permission),
    viewRecsMenu:
      orgAccountType.view_system_reports ||
      (userHasAccounts && userReadPermissions.transactions),
    viewGeneratorsMenu: userHasGenerators || orgAccountType.view_system_reports,
    adminModeEnabledOrRegulator: adminModeEnabled || organizationRegulator,
    notAdminModeEnabledOrRegulator: !(
      adminModeEnabled || organizationRegulator
    ),
    viewGenerationsMenu:
      userHasGenerators || orgAccountType.view_system_reports,
    organizationOwnsGenerators: orgAccountType.owns_projects,
    viewProgramsMenu: userReadPermissions.programs && canAccessPrograms,
    viewAPIDocumentation: orgAccountType.api_access && userReadPermissions.api,
    viewAccounts: userHasAccounts,
    viewCarbonPathways: isRTCOrg,
    viewDocumentDownload: isRTCOrg,
    viewETags: orgHasPSECodes,
  };

  // Certificates
  permissions.canReadCertificates =
    permissions.regionalAdmin || !permissions.isMarketAdmin;
  permissions.canRetireCertificates =
    permissions.canReadCertificates && !organizationRegulator;
  permissions.canManageCertificates =
    permissions.regionalAdmin || !permissions.isMarketAdmin;

  // Generators
  permissions.canReadGenerators =
    permissions.regionalAdmin ||
    (permissions.readGenerators && !permissions.isMarketAdmin);
  permissions.canManageGenerators =
    permissions.regionalAdmin ||
    (permissions.manageGenerators && !permissions.isMarketAdmin);

  // Transactions
  permissions.canReadTransactions =
    permissions.regionalAdmin ||
    (permissions.readTransactions && !permissions.isMarketAdmin);
  permissions.canManageTransactions =
    permissions.regionalAdmin ||
    (permissions.manageTransactions && !permissions.isMarketAdmin);

  // Accounts
  permissions.canReadAccounts =
    permissions.regionalAdmin ||
    (permissions.readAccounts && !permissions.isMarketAdmin);
  permissions.canManageAccounts =
    permissions.regionalAdmin ||
    (permissions.manageAccounts && !permissions.isMarketAdmin);

  // Markets
  permissions.canReadMarkets =
    permissions.isMarketParticipant &&
    (permissions.regionalAdmin ||
      permissions.adminModeEnabledOrRegulator ||
      permissions.readMarkets);
  permissions.canManageMarkets =
    permissions.isMarketParticipant &&
    (permissions.regionalAdmin ||
      permissions.adminModeEnabledOrRegulator ||
      permissions.manageMarkets);

  const booleanPermissions = Object.keys(permissions).reduce(
    (prev, current) => ({
      ...prev,
      [current]: !!permissions[current],
    }),
    {},
  );

  return booleanPermissions;
};

export default constructPermissions;
