import BaseAPI from './base';

class ProgramFuelAPI extends BaseAPI {
  async createProgramFuel(payload) {
    return this.jsonApi.create('program_fuel', payload);
  }

  async saveProgramFuelFeedatocks(programFuelId, payload) {
    return this.axios.post(
      this.buildUrl(`/program_fuels/${programFuelId}/relationships/feedstocks`),
      payload,
    );
  }
}

export default ProgramFuelAPI;
