import BaseAPI from './base';

class AuthAPI extends BaseAPI {
  async signIn(userEmail, password) {
    const response = await this.axios.post(`/api/auth/sign_in`, {
      user: {
        email: userEmail,
        password,
      },
    });

    return {
      data: response.data.data,
      authorization: response.headers.authorization,
    };
  }

  async forgotPassword(email) {
    const form = new FormData();
    form.append('user[email]', email);

    const headers = {
      'Content-Type': 'multipart/form-data',
    };

    const response = await this.axios.post('/api/auth/password', form, {
      headers,
    });

    return response;
  }

  async resetPassword(token, password) {
    const form = new FormData();
    form.append('user[reset_password_token]', token);
    form.append('user[password]', password);
    form.append('user[password_confirmation]', password);

    const headers = {
      'Content-Type': 'multipart/form-data',
    };

    const response = await this.axios.put('/api/auth/password', form, {
      headers,
    });

    return response;
  }

  async unlockAccount(token) {
    const response = await this.axios.get(
      `/api/auth/unlock?unlock_token=${token}`,
    );

    return response;
  }

  async checkTokenValidation(token) {
    const response = await this.axios.get(`/api/validate_token/${token}`);

    return response;
  }
}

export default AuthAPI;
