import React from 'react';

import './Spinner.scss';

const Spinner = () => {
  return (
    <svg
      className="a-mrets-loading--splash w3 h3 translateZ0 Spinner"
      aria-hidden="true"
      width="41"
      height="43"
      viewBox="0 0 41 43"
      title="MRets Spinner"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Loading…</title>
      <g fill="none" fillRule="evenodd">
        <path
          d="M26.574 16.764c-.244-2.074-1.092-4.38-2.374-6.19-1.278-1.863-3.138-3.272-5.268-4.144-2.148-.92-4.52-1.34-6.88-1.22-2.342.173-4.746.857-6.75 2.165l-.025.015c-.122.08-.288.047-.368-.078-.077-.117-.05-.27.053-.352 3.96-3.294 9.694-3.99 14.633-2.365 2.496.822 4.856 2.36 6.537 4.49 1.715 2.136 2.822 4.566 3.28 7.405.123.78-.406 1.517-1.18 1.642-.78.128-1.51-.405-1.636-1.188l-.007-.052-.016-.128z"
          className="Spinner__Path Spinner__Path--secondary"
        />
        <path
          d="M28.597 24.007c1.515-1.427 2.856-3.484 3.543-5.595.73-2.14.755-4.483.187-6.725-.54-2.286-1.613-4.452-3.11-6.295C27.69 3.6 25.715 2.06 23.48 1.214l-.027-.01c-.138-.052-.208-.21-.156-.347.05-.128.184-.2.314-.167 4.987 1.252 8.948 5.483 10.577 10.444.825 2.51.996 5.33.293 7.96-.695 2.653-1.98 4.994-3.98 7.048-.552.564-1.452.574-2.015.018-.563-.553-.57-1.458-.02-2.025l.038-.04.094-.088z"
          className="Spinner__Path Spinner__Path--secondary"
        />
        <path
          d="M22.66 30.002c2.004.54 4.45.602 6.593.075 2.195-.503 4.184-1.717 5.782-3.385 1.642-1.665 2.908-3.724 3.673-5.976.71-2.25.97-4.75.506-7.106l-.005-.03c-.03-.147.066-.288.21-.317.134-.026.264.052.305.183 1.57 4.922.088 10.538-3.242 14.546-1.69 2.03-3.982 3.664-6.57 4.447-2.608.805-5.264.94-8.053.312-.768-.175-1.25-.94-1.08-1.714.172-.77.935-1.256 1.703-1.086l.052.013.124.037z"
          className="Spinner__Path Spinner__Path--secondary"
        />
        <path
          d="M13.23 27.3c.223 2.075 1.048 4.395 2.312 6.214 1.258 1.876 3.102 3.304 5.224 4.2 2.137.94 4.506 1.385 6.866 1.288 2.345-.146 4.755-.804 6.773-2.09l.024-.016c.123-.08.29-.044.367.08.075.12.047.27-.057.356-3.995 3.25-9.735 3.886-14.656 2.208-2.488-.848-4.833-2.41-6.493-4.557-1.69-2.155-2.772-4.595-3.198-7.437-.116-.786.418-1.514 1.196-1.63.78-.12 1.505.42 1.622 1.202l.007.052.013.13z"
          className="Spinner__Path Spinner__Path--primary"
        />
        <path
          d="M12.165 18.858c-1.637 1.287-3.16 3.21-4.03 5.25-.924 2.065-1.158 4.396-.797 6.682.334 2.322 1.206 4.58 2.53 6.553 1.36 1.923 3.19 3.638 5.337 4.682l.026.015c.134.065.19.225.126.358-.06.125-.202.18-.327.138-4.854-1.704-8.42-6.276-9.594-11.368-.594-2.573-.51-5.4.428-7.952.93-2.58 2.42-4.794 4.6-6.654.6-.515 1.496-.44 2.007.16.51.607.437 1.51-.162 2.024l-.042.034-.1.078z"
          className="Spinner__Path Spinner__Path--primary"
        />
        <path
          d="M18.65 13.172c-1.956-.696-4.39-.952-6.568-.597-2.225.33-4.303 1.383-6.026 2.92-1.77 1.532-3.188 3.487-4.13 5.67-.88 2.188-1.335 4.66-1.055 7.045l.003.03c.02.15-.088.28-.233.298-.138.016-.26-.073-.29-.203-1.183-5.032.734-10.515 4.365-14.25 1.84-1.89 4.252-3.337 6.895-3.91 2.664-.604 5.318-.528 8.052.317.752.232 1.172 1.036.942 1.793-.23.754-1.03 1.18-1.783.947l-.048-.015-.122-.045z"
          className="Spinner__Path Spinner__Path--primary"
        />
      </g>
    </svg>
  );
};

export default Spinner;
