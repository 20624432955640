class Config {
  constructor() {
    this.appVersion = process.env.REACT_APP_VERSION || '';
    this.appName = process.env.REACT_APP_NAME || '';
    this.appApiRoot = process.env.REACT_APP_API_ROOT || '';
  }
}

const config = new Config();

export default config;
