import { get } from 'lodash';
import {
  INCOMING_TRANSACTION_TYPES,
  OUTGOING_TRANSACTION_TYPES,
} from './Transfers.constants';

export const findSourceOrganization = (row) => {
  const fromOrg = get(row, 'from_organization', null);
  const certificateOrg = get(row, 'certificate.generator.organization', null);
  const transactionType = get(row, 'user_transaction.transaction_type', null);
  const sourceOrgCellConstants = ['issuance', 'issuance rollback'];

  let value = fromOrg;
  if (sourceOrgCellConstants.includes(transactionType)) {
    value = certificateOrg;
  }
  value = value || {};
  return {
    id: value.id || null,
    name: value.name || 'N/A',
  };
};

export const findDestinationOrganization = (row) => {
  const toAccountOrg = get(row, 'to_account.organization', null);
  const toOrg = get(row, 'to_organization', null);

  const value = toOrg || toAccountOrg || {};
  return {
    id: value.id || null,
    name: value.name || 'N/A',
  };
};

export const applyQuantityModifiers = (data, currentOrgId) => {
  return data.map((transactionDetail) => {
    const transactionType =
      transactionDetail?.user_transaction?.transaction_type;

    let quantityModifier = '';
    if (
      transactionDetail?.to_organization?.id === currentOrgId &&
      INCOMING_TRANSACTION_TYPES.indexOf(transactionType) >= 0
    ) {
      quantityModifier = '+';
    } else if (
      transactionDetail?.from_organization?.id === currentOrgId &&
      OUTGOING_TRANSACTION_TYPES.indexOf(transactionType) >= 0
    ) {
      quantityModifier = '-';
    }

    return {
      ...transactionDetail,
      quantityModifier,
    };
  });
};

export const formatTransactionType = (transactionType) => {
  return transactionType.replace(' ', '-');
};
