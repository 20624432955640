const name = 'thermal_resource';

const shape = {
  code: '',
  name: '',
  is_renewable: false,
  is_biomass: false,
  transaction_detail: {
    type: 'transaction_details',
    jsonApi: 'hasOne',
  },
};

const model = {
  name,
  shape,
};

export default model;
