const name = 'user_organization';

const shape = {
  notifications: '',
  permissions: '',
  active: false,
  name: '',
  user_organization_type: 'default',
  status: '',
  organization: {
    type: 'organization',
    jsonApi: 'hasOne',
  },
  user: {
    type: 'users',
    jsonApi: 'hasOne',
  },
  tickets: {
    type: 'tickets',
    jsonApi: 'hasMany',
  },
};

const options = {
  include: 'organization',
};

const model = {
  name,
  shape,
  options,
};

export default model;
