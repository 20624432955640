import createJsonApiInstance from './jsonApiInstance';
import createAxiosInstance from './axiosInstance';
import AccountAPI from './account';
import UserAPI from './user';
import AuthAPI from './auth';
import OrganizationAPI from './organization';
import ReportsAPI from './reports';
import GenerationAPI from './generation';
import CarbonPathwayAPI from './carbonPathway';
import CertificateAPI from './certificate';
import TransactionDetailAPI from './transactionDetail';
import FiltersAPI from './filters';
import DownloadAPI from './download';
import GeneratorAPI from './generator';
import GeneratorAggregateAPI from './generatorAggregate';
import GeneratorUnitAPI from './generatorUnit';
import RecurringTransferAPI from './recurringTransfer';
import UserTransactionAPI from './userTransaction';
import RetirementOptions from './retirementOptions';
import EligibilityAPI from './eligibility';
import FeatureFlagAPI from './featureFlag';
import FuelTypeAPI from './fuelType';
import ProgramAPI from './program';
import ProgramFuelAPI from './programFuel';
import ProgramParticipantInviteAPI from './programParticipantInvite';
import OrganizationAccountTypeAPI from './organizationAccountType';
import ResourceTypeAPI from './resourceType';
import GeneratorFuelAPI from './generatorFuel';
import ApiKeyAPI from './apiKey';
import NotesAPI from './notes';
import DashboardNotificationAPI from './dashboardNotification';
import TableSummaryAPI from './tableSummary';
import InterconnectedUtilityAPI from './interconnectedUtility';
import BalancingAuthorityAPI from './balancingAuthority';
import PseCodeAPI from './pseCodes';
import ETagsAPI from './eTags';
import ETagMatchesAPI from './etagMatches';
import TicketAPI from './ticket';
import EligibilityBondAPI from './eligibilityBond';
import FiltersConfigAPI from './filtersConfig';
import { RESOURCE_TYPES } from '../constants';

class API {
  constructor() {
    this.applyResourceType(RESOURCE_TYPES.rec);
  }

  applyResourceType(resourceType = RESOURCE_TYPES.rec) {
    this.jsonApiInstance = createJsonApiInstance(resourceType);
    this.axiosInstance = createAxiosInstance(resourceType);

    this.accountAPI = new AccountAPI(
      this.jsonApiInstance,
      this.axiosInstance,
      resourceType,
    );
    this.userAPI = new UserAPI(
      this.jsonApiInstance,
      this.axiosInstance,
      resourceType,
    );
    this.organizationAPI = new OrganizationAPI(
      this.jsonApiInstance,
      this.axiosInstance,
      resourceType,
    );
    this.reportsAPI = new ReportsAPI(
      this.jsonApiInstance,
      this.axiosInstance,
      resourceType,
    );
    this.authAPI = new AuthAPI(
      this.jsonApiInstance,
      this.axiosInstance,
      resourceType,
    );
    this.carbonPathwayAPI = new CarbonPathwayAPI(this.jsonApiInstance);
    this.certificateAPI = new CertificateAPI(
      this.jsonApiInstance,
      this.axiosInstance,
      resourceType,
    );
    this.generationAPI = new GenerationAPI(
      this.jsonApiInstance,
      this.axiosInstance,
      resourceType,
    );
    this.transactionDetailAPI = new TransactionDetailAPI(
      this.jsonApiInstance,
      this.axiosInstance,
      resourceType,
    );
    this.filtersAPI = new FiltersAPI(
      this.jsonApiInstance,
      this.axiosInstance,
      resourceType,
    );
    this.downloadAPI = new DownloadAPI(
      this.jsonApiInstance,
      this.axiosInstance,
      resourceType,
    );
    this.generatorAPI = new GeneratorAPI(
      this.jsonApiInstance,
      this.axiosInstance,
      resourceType,
    );
    this.generatorAggregateAPI = new GeneratorAggregateAPI(
      this.jsonApiInstance,
      this.axiosInstance,
      resourceType,
    );
    this.generatorUnitAPI = new GeneratorUnitAPI(
      this.jsonApiInstance,
      this.axiosInstance,
      resourceType,
    );
    this.recurringTransferAPI = new RecurringTransferAPI(
      this.jsonApiInstance,
      this.axiosInstance,
      resourceType,
    );
    this.userTransactionAPI = new UserTransactionAPI(
      this.jsonApiInstance,
      this.axiosInstance,
      resourceType,
    );
    this.retirementOptionsAPI = new RetirementOptions(
      this.jsonApiInstance,
      this.axiosInstance,
      resourceType,
    );
    this.eligibilityAPI = new EligibilityAPI(
      this.jsonApiInstance,
      this.axiosInstance,
      resourceType,
    );
    this.FeatureFlagAPI = new FeatureFlagAPI(
      this.jsonApiInstance,
      this.axiosInstance,
      resourceType,
    );
    this.fuelTypeAPI = new FuelTypeAPI(
      this.jsonApiInstance,
      this.axiosInstance,
      resourceType,
    );
    this.programAPI = new ProgramAPI(
      this.jsonApiInstance,
      this.axiosInstance,
      resourceType,
    );
    this.programFuelAPI = new ProgramFuelAPI(
      this.jsonApiInstance,
      this.axiosInstance,
      resourceType,
    );
    this.programParticipantInviteAPI = new ProgramParticipantInviteAPI(
      this.jsonApiInstance,
      this.axiosInstance,
      resourceType,
    );
    this.generatorFuelAPI = new GeneratorFuelAPI(
      this.jsonApiInstance,
      this.axiosInstance,
      resourceType,
    );
    this.organizationAccountTypeAPI = new OrganizationAccountTypeAPI(
      this.jsonApiInstance,
      this.axiosInstance,
      resourceType,
    );
    this.resourceTypeAPI = new ResourceTypeAPI(
      this.jsonApiInstance,
      this.axiosInstance,
      resourceType,
    );
    this.notesAPI = new NotesAPI(
      this.jsonApiInstance,
      this.axiosInstance,
      resourceType,
    );
    this.dashboardNotificationAPI = new DashboardNotificationAPI(
      this.jsonApiInstance,
      this.axiosInstance,
      resourceType,
    );
    this.apiKeyAPI = new ApiKeyAPI(
      this.jsonApiInstance,
      this.axiosInstance,
      resourceType,
    );
    this.tableSummaryAPI = new TableSummaryAPI(
      this.jsonApiInstance,
      this.axiosInstance,
      resourceType,
    );
    this.interconnectedUtilityAPI = new InterconnectedUtilityAPI(
      this.jsonApiInstance,
      this.axiosInstance,
      resourceType,
    );
    this.balancingAuthorityAPI = new BalancingAuthorityAPI(
      this.jsonApiInstance,
      this.axiosInstance,
      resourceType,
    );
    this.pseCodeApi = new PseCodeAPI(
      this.jsonApiInstance,
      this.axiosInstance,
      resourceType,
    );
    this.eTagsApi = new ETagsAPI(
      this.jsonApiInstance,
      this.axiosInstance,
      resourceType,
    );
    this.eTagMatchesApi = new ETagMatchesAPI(
      this.jsonApiInstance,
      this.axiosInstance,
      resourceType,
    );
    this.ticketApi = new TicketAPI(
      this.jsonApiInstance,
      this.axiosInstance,
      resourceType,
    );
    this.eligibilityBondApi = new EligibilityBondAPI(
      this.jsonApiInstance,
      this.axiosInstance,
      resourceType,
    );
    this.filtersConfigApi = new FiltersConfigAPI(
      this.jsonApiInstance,
      this.axiosInstance,
      resourceType,
    );
  }

  get account() {
    return this.accountAPI;
  }

  get user() {
    return this.userAPI;
  }

  get organization() {
    return this.organizationAPI;
  }

  get reports() {
    return this.reportsAPI;
  }

  get auth() {
    return this.authAPI;
  }

  get carbonPathway() {
    return this.carbonPathwayAPI;
  }

  get certificate() {
    return this.certificateAPI;
  }

  get transactionDetail() {
    return this.transactionDetailAPI;
  }

  get generation() {
    return this.generationAPI;
  }

  get filters() {
    return this.filtersAPI;
  }

  get download() {
    return this.downloadAPI;
  }

  get generator() {
    return this.generatorAPI;
  }

  get generatorAggregate() {
    return this.generatorAggregateAPI;
  }

  get generatorUnit() {
    return this.generatorUnitAPI;
  }

  get reccuringTransfer() {
    return this.recurringTransferAPI;
  }

  get userTransaction() {
    return this.userTransactionAPI;
  }

  get retirementOptions() {
    return this.retirementOptionsAPI;
  }

  get eligibility() {
    return this.eligibilityAPI;
  }

  get featureFlag() {
    return this.FeatureFlagAPI;
  }

  get fuelType() {
    return this.fuelTypeAPI;
  }

  get program() {
    return this.programAPI;
  }

  get programFuel() {
    return this.programFuelAPI;
  }

  get programParticipantInvite() {
    return this.programParticipantInviteAPI;
  }

  get organizationAccountType() {
    return this.organizationAccountTypeAPI;
  }

  get resourceType() {
    return this.resourceTypeAPI;
  }

  get generatorFuel() {
    return this.generatorFuelAPI;
  }

  get apiKey() {
    return this.apiKeyAPI;
  }

  get notes() {
    return this.notesAPI;
  }

  get dashboardNotification() {
    return this.dashboardNotificationAPI;
  }

  get tableSummary() {
    return this.tableSummaryAPI;
  }

  get interconnectedUtility() {
    return this.interconnectedUtilityAPI;
  }

  get balancingAuthority() {
    return this.balancingAuthorityAPI;
  }

  get pseCodes() {
    return this.pseCodeApi;
  }

  get eTags() {
    return this.eTagsApi;
  }

  get eTagMatches() {
    return this.eTagMatchesApi;
  }

  get ticket() {
    return this.ticketApi;
  }

  get eligibilityBond() {
    return this.eligibilityBondApi;
  }

  get filtersConfig() {
    return this.filtersConfigApi;
  }
}

const api = new API();

export default api;
