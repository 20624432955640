const name = 'generation_upload_report';

const shape = {
  created_at: '',
  status: '',
  document_url: '',
  filename: '',
  report_url: '',
  report_name: '',
  upload_errors: null,
  user: {
    type: 'user',
    jsonApi: 'hasOne',
  },
};

const model = {
  name,
  shape,
};

export default model;
