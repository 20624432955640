import { RESOURCE_TYPES } from '../../../../constants';
import filterPermissionsHelper from '../../../../utils/filterPermissions';
import SidebarItems from './Sidebar.items';

export const filterPermissions = (user, featureFlags = {}) => {
  const { resourceType = RESOURCE_TYPES.rec, permissions } = user || {};

  if (!permissions) {
    return [];
  }

  const sidebarItems = SidebarItems[resourceType].filter(
    ({ filterFn = null }) => !filterFn || filterFn(user, featureFlags),
  );

  const filteredSidebarItems = filterPermissionsHelper(
    sidebarItems,
    user,
    featureFlags,
  );

  return filteredSidebarItems;
};
