/* eslint-disable prettier/prettier */
const recTheme = {
  "light": {
    "primary": "#005172",
    "primary-main": "#005172",
    "primary-subtle": "#0099d8",
    "primary-light": "#0099d8",
    "primary-dark": "#00395d",
    "primary-darker": "#00090c",
    "secondary": "#a99260",
    "secondary-main": "#a99260",
    "secondary-subtle": "#cdbfa2",
    "secondary-light": "#cdbfa2",
    "secondary-dark": "#8b774b",
    "secondary-darker": "#6a5a39",
    "neutral-darker": "#2c405a",
    "navbar-fg": "#2c405a",
    "navbar-bg": "#ffffff",
    "navbar-border-color": "#e7eaed",
    "navbar-burger-fg": "#f2f2f2",
    "navbar-burger-bg": "#2c405a",
    "pageheader-fg": "#f2f2f2",
    "pageheader-bg": "#00395d",
    "pageheader-link": "#f2f2f2",
    "button-fg-primary": "#f2f2f2",
    "button-bg-primary": "#a99260",
    "button-fg-secondary": "#00395d",
    "button-bg-secondary": "#f2f2f2"
  },
  "dark": {
    "primary": "#005172",
    "primary-main": "#005172",
    "primary-subtle": "#0099d8",
    "primary-light": "#0099d8",
    "primary-dark": "#00395d",
    "primary-darker": "#00090c",
    "secondary": "#a99260",
    "secondary-main": "#a99260",
    "secondary-subtle": "#cdbfa2",
    "secondary-light": "#cdbfa2",
    "secondary-dark": "#8b774b",
    "secondary-darker": "#6a5a39",
    "navbar-fg": "#2c405a",
    "navbar-bg": "#ffffff",
    "navbar-border-color": "#e7eaed",
    "navbar-burger-fg": "#f2f2f2",
    "navbar-burger-bg": "#2c405a",
    "pageheader-fg": "#f2f2f2",
    "pageheader-bg": "#00395d",
    "pageheader-link": "#f2f2f2",
    "button-fg-primary": "#f2f2f2",
    "button-bg-primary": "#a99260",
    "button-fg-secondary": "#00395d",
    "button-bg-secondary": "#f2f2f2"
  }
};

export default recTheme;
