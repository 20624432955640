import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js';
import React from 'react';
import { createRoot } from 'react-dom/client';
import ReactGA4 from 'react-ga4';
import App from './components/App';
import * as serviceWorker from './serviceWorker';

import './styles/index.scss';

ReactGA4.initialize(
  process.env.REACT_APP_GOOGLE_ANALYTICS_4_TRACKING_KEY || 'NO-KEY-FOUND',
);

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
