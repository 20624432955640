import React from 'react';
import { useUser } from '../../../../hooks/user';
import { getUserRegion } from '../../../../utils/helpers';
import { LOGOS } from './Logo.constants';

const Logo = (props) => {
  const { user } = useUser();

  const region = getUserRegion(user);

  const LogoComponent = LOGOS[region] || LOGOS.default;

  return (
    <div className="Logo">
      <LogoComponent {...props} />
    </div>
  );
};

export default Logo;
