/* eslint-disable import/prefer-default-export */
import { useContext } from 'react';
import AppContext from '../components/App/App.context';

export const useApp = (stateDataKey) => {
  const { updateAppData, ...appData } = useContext(AppContext);

  const getAppState = () => {
    return appData?.state?.[stateDataKey] || null;
  };

  const setAppState = (stateData) => {
    if (stateDataKey) {
      let newState = appData.state ? { ...appData.state } : {};
      newState = {
        ...newState,
        [stateDataKey]: stateData,
      };

      updateAppData({ state: newState });
    }
  };

  return {
    appData,
    updateAppData,
    getAppState,
    setAppState,
  };
};
