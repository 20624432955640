const name = 'program';

const shape = {
  name: '',
  year: '',
  close_date: '',
  account_naming_convention: '',
  is_market: false,
  is_voluntary: false,
  is_internal: true,
  vintage_start: '',
  vintage_end: '',
  locations: [],
  administrator_name: '',
  eligibilities: {
    type: 'eligibilities',
    jsonApi: 'hasMany',
  },
  program_fuels: {
    type: 'program_fuels',
    jsonApi: 'hasMany',
  },
  generators: {
    type: 'generators',
    jsonApi: 'hasMany',
  },
  administrator: {
    type: 'organizations',
    jsonApi: 'hasOne',
  },
  program_participant_invites: {
    type: 'program_participant_invite',
    jsonApi: 'hasMany',
  },
  participants: {
    type: 'organizations',
    jsonApi: 'hasMany',
  },
};

const model = {
  name,
  shape,
};

export default model;
