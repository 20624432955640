const name = 'ticket';

const shape = {
  request_type: '',
  status: '',
  notes: '',
  created_at: '',
  updated_at: '',
  ended_at: '',
  resource_type: '',
  request_target_object: {
    jsonApi: 'hasOne',
  },
  organization: {
    type: 'organizations',
    jsonApi: 'hasOne',
  },
  request_user: {
    type: 'user_organizations',
    jsonApi: 'hasOne',
  },
  ended_by_user: {
    type: 'users',
    jsonApi: 'hasOne',
  },
  ticket_documents: {
    type: 'ticket_documents',
    jsonApi: 'hasMany',
  },
};

const model = {
  name,
  shape,
};

export default model;
