class BaseAPI {
  constructor(jsonApiInstance, axiosInstance, resourceType) {
    this.jsonApi = jsonApiInstance;
    this.axios = axiosInstance;
    this.resourceType = resourceType;
  }

  buildUrl(path, version = 'v1') {
    const fixedPath = path.startsWith('/') ? path.slice(1) : path;
    return `/${version}/public/${this.resourceType}/${fixedPath}`;
  }
}

export default BaseAPI;
