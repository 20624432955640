/* eslint-disable import/prefer-default-export */
import { get } from 'lodash';
import { DEFAULT_PERMISSIONS } from '../components/App/App.constants';
import constructPermissions from '../utils/constructPermissions';
import api from '../services/api';
import { useApp } from './app';
import User from '../entities/User';
import { REGIONS, RESOURCE_TYPES } from '../constants';

const getUser = async (userId) => {
  const { data: userData } = await api.user.getUser(userId);

  let region = REGIONS.mrets;

  // Set API ResourceType
  const resourceType =
    userData.current_organization?.resource_type || RESOURCE_TYPES.rec;
  api.applyResourceType(resourceType);

  const { data: userPreferences } = await api.user.getUserPreferences(userId);
  const currentOrgId = get(userData, 'current_organization.id');

  const { data: orgData } = await api.organization.getOrganization(
    currentOrgId,
  );

  if (orgData.region && REGIONS[orgData.region]) {
    region = REGIONS[orgData.region];
  }

  const { permissions: userPermissions, notifications } = userPreferences.data;

  const isAdmin = userData.super_admin || userData.regional_admin;
  let hasMultipleOrgs = isAdmin;
  if (!isAdmin) {
    const userOrgsCount = await api.user.getUserOrganizationsCount();
    hasMultipleOrgs = userOrgsCount > 1;
  }

  // It has user data, organization data, and the permissions and notifications between them
  const userOrganization = {
    ...userData,
    organization: orgData,
    permissions: userPermissions,
    notifications,
  };

  let permissions = DEFAULT_PERMISSIONS;
  if (userOrganization && orgData) {
    permissions = constructPermissions(
      userOrganization,
      orgData,
      DEFAULT_PERMISSIONS,
    );
  }

  const user = new User({
    id: userData.id,
    permissions,
    resourceType,
    region,
    userData,
    hasMultipleOrgs,
    userOrganization,
    notifications,
  });

  return user;
};

export const useUser = () => {
  const { appData, updateAppData } = useApp();

  const { user } = appData;

  const fetchUser = async (userId, update = false) => {
    let { user: usr, theme } = appData;
    if (!usr || update) {
      try {
        usr = await getUser(userId);
        theme = usr.resourceType;
      } catch (err) {
        // No user :/
      }
    }

    try {
      updateAppData({
        user: usr,
        theme,
        resourceType: usr.resourceType,
        region: usr.region,
      });
    } catch (err) {
      // No user :/
    }
  };

  const setUserId = async (userId) => {
    sessionStorage.setItem('userId', userId);
  };

  const getUserId = () => {
    return sessionStorage.getItem('userId');
  };

  const removeUserId = () => {
    sessionStorage.removeItem('userId');
  };

  const isLoggedIn = () => {
    return !!user;
  };

  const switchToOrg = async (org, userId = user.id) => {
    await api.user.updateUserData(userId, {
      admin_mode: false,
      current_organization: {
        id: org.id,
      },
    });

    try {
      const usr = await getUser(userId);
      const theme = usr.resourceType;
      updateAppData({
        user: usr,
        theme,
        resourceType: usr.resourceType,
        region: usr.region,
      });
    } catch (err) {
      // No user :/
    }
  };

  // toggle adminMode
  const toggleAdminMode = async () => {
    await api.user.updateUserData(user.id, {
      admin_mode: !user.permissions.adminModeEnabled,
    });

    const newUser = new User({
      ...user,
      permissions: {
        ...user.permissions,
        adminModeEnabled: !user.permissions.adminModeEnabled,
        notAdminModeEnabledOrRegulator:
          !user.permissions.notAdminModeEnabledOrRegulator,
        adminModeEnabledOrRegulator:
          !user.permissions.adminModeEnabledOrRegulator,
      },
    });

    updateAppData({ user: newUser });
  };

  const updateUser = async (newData) => {
    const newUser = new User({
      ...user,
      userData: {
        ...user.userData,
        ...newData,
      },
    });

    updateAppData({ user: newUser });
  };

  return {
    user,
    getUser,
    fetchUser,
    isLoggedIn,
    switchToOrg,
    toggleAdminMode,
    setUserId,
    getUserId,
    removeUserId,
    updateUser,
  };
};
