import React from 'react';

const DashboardIcon = () => {
  return (
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4795 21.5C5.54243 21.5 0.729492 16.6871 0.729492 10.75C0.729492 4.81294 5.54243 0 11.4795 0C17.4166 0 22.2295 4.81294 22.2295 10.75C22.2295 16.6871 17.4166 21.5 11.4795 21.5ZM2.25946 10C2.41962 8.00398 3.21342 6.18674 4.43957 4.74956C4.46687 4.79074 4.49866 4.82983 4.53495 4.86612L5.94916 6.28033C6.24206 6.57322 6.71693 6.57322 7.00982 6.28033C7.30272 5.98744 7.30272 5.51256 7.00982 5.21967L5.59561 3.80546C5.55932 3.76917 5.52023 3.73738 5.47905 3.71008C6.91623 2.48393 8.73348 1.69012 10.7295 1.52997V3.75C10.7295 4.16421 11.0653 4.5 11.4795 4.5C11.8937 4.5 12.2295 4.16421 12.2295 3.75V1.52997C14.2255 1.69012 16.0428 2.48393 17.4799 3.71008C17.4387 3.73738 17.3997 3.76917 17.3634 3.80546L15.9492 5.21967C15.6563 5.51256 15.6563 5.98744 15.9492 6.28033C16.2421 6.57322 16.7169 6.57322 17.0098 6.28033L18.424 4.86612C18.4603 4.82983 18.4921 4.79074 18.5194 4.74956C19.7456 6.18674 20.5394 8.00398 20.6995 10H18.4795C18.0653 10 17.7295 10.3358 17.7295 10.75C17.7295 11.1642 18.0653 11.5 18.4795 11.5H20.6995C20.3178 16.2579 16.3356 20 11.4795 20C6.62335 20 2.64122 16.2579 2.25946 11.5H4.47949C4.89371 11.5 5.22949 11.1642 5.22949 10.75C5.22949 10.3358 4.89371 10 4.47949 10H2.25946ZM11.4795 12.5C10.513 12.5 9.72949 11.7165 9.72949 10.75C9.72949 10.0519 10.1383 9.44927 10.7295 9.16841V6.75C10.7295 6.33579 11.0653 6 11.4795 6C11.8937 6 12.2295 6.33579 12.2295 6.75V9.16841C12.8207 9.44927 13.2295 10.0519 13.2295 10.75C13.2295 11.7165 12.446 12.5 11.4795 12.5Z"
        fill="white"
      />
    </svg>
  );
};

export default DashboardIcon;
