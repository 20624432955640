const name = 'interconnected_utility';

const shape = {
  name: '',
  created_at: '',
  state: '',
  ownership_type: '',
  nerc_regions: [],
};

const model = {
  name,
  shape,
};

export default model;
