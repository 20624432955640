import BaseAPI from '../base';
import { formatQueryData, getFieldsValues } from '../helpers';
import { ORGANIZATIONS_FIELDS } from './organization.constants';

class OrganizationAPI extends BaseAPI {
  async getAccountTypes() {
    const response = await this.jsonApi.findAll('organization_account_types');

    return response;
  }

  async getInvoices(queryData) {
    const newQueryData = formatQueryData(queryData);
    const response = await this.jsonApi.findAll('invoice', {
      ...newQueryData,
    });

    return response;
  }

  async getInvoice(invoiceId) {
    const response = await this.jsonApi.find('invoice', invoiceId);

    return response;
  }

  async getOrganization(orgId) {
    const response = await this.jsonApi.find('organization', orgId, {
      include: [
        'account_type',
        'contact',
        'billing_contact',
        'market',
        'notes.user',
        'pse_codes',
        'eligibilities',
        'primary_contact_user',
      ],
      fields: ORGANIZATIONS_FIELDS.show,
    });

    return response;
  }

  async getOrganizations(queryData, includeContacts = true) {
    const newQueryData = formatQueryData(queryData);

    const include = includeContacts
      ? ['account_type', 'contact', 'billing_contact']
      : ['account_type'];

    const fields = ORGANIZATIONS_FIELDS.index;

    const response = await this.jsonApi.findAll('organization', {
      ...newQueryData,
      include,
      fields: getFieldsValues(
        newQueryData.unique_by,
        fields,
        this.resourceType,
      ),
    });

    return response;
  }

  async createOrganization(payload) {
    return this.jsonApi.create('organization', payload);
  }

  async updateOrganization(orgId, params) {
    return this.jsonApi.update('organization', { id: orgId, ...params });
  }

  async updateOrganizationContact(contactId, params) {
    const contactData = {
      id: contactId,
      ...params,
    };
    if (contactData.links) {
      delete contactData.links;
    }
    return this.jsonApi.update('contact', contactData);
  }

  async getOrganizationDocuments(queryData) {
    const newQueryData = formatQueryData(queryData);

    const response = await this.jsonApi.findAll(
      'organization_documents',
      newQueryData,
    );

    return response;
  }

  async uploadOrganizationDocument(orgId, documentData) {
    const form = new FormData();
    form.append('document', documentData.file);
    form.append('description', documentData.description || '');
    form.append('organization_id', orgId);

    const headers = {
      'Content-Type': 'multipart/form-data',
    };

    const response = await this.axios.post(
      `/v1/public/rec/organization_documents`,
      form,
      { headers },
    );

    return {
      ...response,
      data: response.data.data,
    };
  }

  async updateOrganizationDocument(docId, params) {
    const docData = {
      id: docId,
      ...params,
    };
    if (docData.links) {
      delete docData.links;
    }
    return this.jsonApi.update('organization_document', docData);
  }

  async deleteOrganizationDocument(docId) {
    return this.jsonApi.destroy('organization_document', docId);
  }
}

export default OrganizationAPI;
