import React from 'react';

const MarketsIcon = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.00007 20H1.50007V11.1436L5.30535 8.51678L11.4795 11.7133L18.3581 2.18912C18.6006 1.85332 18.525 1.38451 18.1892 1.14199C17.8534 0.899472 17.3846 0.975088 17.1421 1.31088L11.0207 9.78667L5.19478 6.77042L1.50007 9.32092V0.75C1.50007 0.335786 1.16428 0 0.750069 0C0.335855 0 6.87174e-05 0.335786 6.87174e-05 0.75V10.7405C-2.28462e-05 10.7473 -2.29654e-05 10.7541 6.87174e-05 10.7608V20.75C6.87174e-05 21.1642 0.335855 21.5 0.750069 21.5H3.75007H6.75007H9.75007H12.7501H15.7501H18.7501H20.7501C21.1643 21.5 21.5001 21.1642 21.5001 20.75C21.5001 20.3358 21.1643 20 20.7501 20H19.5001V9.75C19.5001 9.33579 19.1643 9 18.7501 9H15.7501C15.3359 9 15.0001 9.33579 15.0001 9.75V20H13.5001V14.75C13.5001 14.3358 13.1643 14 12.7501 14H9.75007C9.33586 14 9.00007 14.3358 9.00007 14.75V20H7.50007V12.75C7.50007 12.3358 7.16428 12 6.75007 12H3.75007C3.33586 12 3.00007 12.3358 3.00007 12.75V20ZM4.50007 20H6.00007V13.5H4.50007V20ZM10.5001 20H12.0001V15.5H10.5001V20ZM16.5001 20H18.0001V10.5H16.5001V20Z"
        fill="white"
      />
    </svg>
  );
};

export default MarketsIcon;
