import BaseAPI from '../base';
import { formatQueryData, getFieldsValues, getIncludeValues } from '../helpers';
import {
  GENERATOR_UNITS,
  GENERATOR_UNITS_FIELDS,
} from './generatorUnit.constants';

class GeneratorUnitAPI extends BaseAPI {
  async getGeneratorUnits(queryData) {
    const newQueryData = formatQueryData(queryData);

    const response = await this.jsonApi.findAll('generator_unit', {
      ...newQueryData,
      include: getIncludeValues(
        newQueryData.unique_by,
        GENERATOR_UNITS[this.resourceType],
      ),
      fields: getFieldsValues(
        queryData.unique_by,
        GENERATOR_UNITS_FIELDS[this.resourceType],
        this.resourceType,
      ),
    });

    return response;
  }

  async getGeneratorUnit(unitId) {
    const response = await this.jsonApi.find('generator_unit', unitId, {
      include: GENERATOR_UNITS[this.resourceType].includeAll,
      fields: GENERATOR_UNITS[this.resourceType].fields,
    });

    return response;
  }

  async requestApprovalGeneratorUnit(unitId) {
    const response = await this.axios.put(
      this.buildUrl(`/generator_units/${unitId}/request_approval`),
    );

    return response;
  }

  async createGeneratorUnit(payload) {
    const { data: contact } = await this.jsonApi.create(
      'contact',
      payload.contact,
    );

    const generatorUnitPayload = {
      ...payload,
      contact: {
        id: contact.id,
      },
    };

    return this.jsonApi.create('generator_unit', generatorUnitPayload, {});
  }

  async updateGeneratorUnit(id, payload) {
    const contactPayload = { ...payload.contact };
    delete contactPayload.links;
    const { data: contact } = await this.jsonApi.update(
      'contact',
      contactPayload,
    );

    const generatorUnitPayload = {
      id,
      ...payload,
      contact: {
        id: contact.id,
      },
    };

    delete generatorUnitPayload.unit_id;
    delete generatorUnitPayload.links;
    delete generatorUnitPayload.created_at;
    delete generatorUnitPayload.updated_at;

    return this.jsonApi.update('generator_unit', generatorUnitPayload, {});
  }

  async inactivateUnit(generatorUnitId) {
    return this.jsonApi.customRequest(
      'PUT',
      `${this.jsonApi.urlFor({
        model: 'generator_unit',
        id: generatorUnitId,
      })}/deactivate`,
      'generator_unit',
    );
  }

  async reactivateUnit(generatorUnitId) {
    return this.jsonApi.customRequest(
      'PUT',
      `${this.jsonApi.urlFor({
        model: 'generator_unit',
        id: generatorUnitId,
      })}/reactivate`,
      'generator_unit',
    );
  }

  async acceptUnit(generatorUnitId, notes) {
    return this.jsonApi.customRequest(
      'PUT',
      `${this.jsonApi.urlFor({
        model: 'generator_unit',
        id: generatorUnitId,
      })}/approve`,
      'generator_unit',
      { notes },
    );
  }

  async rejectUnit(generatorUnitId, notes) {
    return this.jsonApi.customRequest(
      'PUT',
      `${this.jsonApi.urlFor({
        model: 'generator_unit',
        id: generatorUnitId,
      })}/reject`,
      'generator_unit',
      { notes },
    );
  }
}

export default GeneratorUnitAPI;
