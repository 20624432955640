import React from 'react';

const ProfileIcon = () => {
  return (
    <svg
      aria-hidden="true"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Icon Avatar</title>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0" />
        <path
          d="M12 22C6.4771525 22 2 17.5228475 2 12S6.4771525 2 12 2s10 4.4771525 10 10-4.4771525 10-10 10zm3-14c0-1.65-1.35-3-3-3S9 6.35 9 8c0 1.665 1.35 3 3 3s3-1.335 3-3zm-3 11c3.3137085 0 6-1.5670034 6-3.5S15.3137085 13 12 13s-6 .5670034-6 2.5S8.6862915 19 12 19z"
          className="fill-white-hover-dark"
        />
      </g>
    </svg>
  );
};

export default ProfileIcon;
