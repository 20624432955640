const name = 'report_export_request';

const shape = {
  recipient_name: '',
  recipient_email: '',
  report_name: '',
  table_query: {},
  table_columns: '',
  file_name: '',
  file_url: '',
  status: '',
  notes: '',
  requested_date: '',
  ticket: {
    type: 'tickets',
    jsonApi: 'hasOne',
  },
};

const model = {
  name,
  shape,
};

export default model;
