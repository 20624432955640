import React from 'react';
import PropTypes from 'prop-types';

const WRegisLogo = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      width={width}
      height={height}
      viewBox="0 0 2464 764"
      style={{ enableBackground: 'new 0 0 2464 764' }}
      xmlSpace="preserve"
    >
      <g>
        <path
          className="st0"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#1A517E"
          d="M1454.37,273.47l-66.31,246.6h-53.88l-41.79-167.85h-0.69l-41.1,167.85h-54.92l-65.27-246.6h54.22   l39.03,167.85h0.69l42.83-167.85h50.77l42.14,169.92h0.69l40.41-169.92H1454.37L1454.37,273.47z M1560.3,520.07v-246.6h184.43   v45.59h-130.2v52.84h119.5v42.13h-119.5v60.44h132.97v45.59H1560.3L1560.3,520.07z M2066.38,356.36h-52.5   c-1.62-12.2-7.6-22.45-17.96-30.74c-10.36-8.29-22.33-12.43-35.92-12.43c-22.8,0-40.04,8.77-51.81,26.25   c-10.1,14.98-15.19,34.42-15.19,58.37c0,23.02,5.04,41.93,15.19,56.64c11.94,17.29,29.24,25.9,51.81,25.9   c16.12,0,29.14-5.18,39.03-15.54c9.22-9.66,14.85-22.91,16.93-39.72h52.5c-2.76,30.62-13.99,55.09-33.67,73.39   c-19.68,18.3-44.61,27.46-74.78,27.46c-37.07,0-66.74-12.47-89.11-37.3c-21.38-23.74-32.12-53.99-32.12-90.83   c0-37.76,10.81-68.54,32.12-92.56c22.19-25.01,51.8-37.65,89.11-37.65c28.09,0,51.8,7.74,71.15,23.14   C2051.4,306.87,2063.16,328.72,2066.38,356.36L2066.38,356.36z M2389.93,356.36h-52.5c-1.61-12.2-7.6-22.45-17.96-30.74   c-10.36-8.29-22.33-12.43-35.92-12.43c-22.79,0-40.04,8.78-51.81,26.25c-10.09,14.98-15.2,34.42-15.2,58.37   c0,23.02,5.04,41.93,15.2,56.64c11.94,17.29,29.24,25.9,51.81,25.9c16.12,0,29.14-5.18,39.03-15.54   c9.21-9.66,14.85-22.91,16.92-39.72h52.5c-2.76,30.62-13.99,55.09-33.67,73.39c-19.68,18.3-44.61,27.46-74.78,27.46   c-37.07,0-66.74-12.47-89.1-37.3c-21.38-23.73-32.12-53.99-32.12-90.83c0-37.76,10.81-68.55,32.12-92.56   c22.19-25.01,51.81-37.65,89.1-37.65c28.09,0,51.81,7.74,71.15,23.14C2374.95,306.86,2386.71,328.72,2389.93,356.36L2389.93,356.36   z"
        />
        <path
          className="st0"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#1A517E"
          d="M438.14,553.88l0.02,0.03l27.99-47.33l45.78-77.25l218.5,249.28L540.04,349.67L512.5,302l-27.97,47.28   l-45.78,77.29L299.67,185.64l-9.39-16.26h-18.46H126.13H70.39l27.91,48.37l228.87,396.57l37.12,64.29l37.64-63.57L438.14,553.88   L438.14,553.88z M782.88,575.02l199.6-390.69h-55.73H775.5h-18.46l-9.37,16.24l-90.85,173.19L782.88,575.02L782.88,575.02z"
        />
        <polygon
          className="st1"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#C9AA60"
          points="413.87,277.88 511.25,166.25 734.18,567.55 762.04,615.81 803.72,534.23 676.97,331.88 676.97,331.89    515.76,74.5 470.98,163.88 432.87,102.93 432.86,102.96 432.86,102.93 362.23,220.24 415.83,166.64 430.77,151.71 456.92,191.94    413.87,277.88  "
        />
      </g>
    </svg>
  );
};

WRegisLogo.defaultProps = {
  width: '120',
  height: '31',
};

WRegisLogo.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

export default WRegisLogo;
