import FileSaver from 'file-saver';

const saveFileAs = (filename, content, contentType) => {
  try {
    const file = new File([content], filename, { type: contentType });
    FileSaver.saveAs(file);
  } catch (err) {
    const blob = new Blob([content], { type: contentType });
    FileSaver.saveAs(blob, filename);
  }
};

export default saveFileAs;
