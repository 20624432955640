const name = 'user';

const shape = {
  first_name: '',
  last_name: '',
  full_name: '',
  email: '',
  email_confirmation: '',
  phone: '',
  resource_type: '',
  organization_id: '',
  permissions: {},
  admin_mode: false,
  admin_mode_permission: false,
  super_admin: false,
  regional_admin: false,
  can_emulate_all: false,
  country_code: '',
  login_type: '',
  mfa: '',
  is_mfa_enabled: false,
  preferences: {},
  user_types: {},
  user_organizations: {
    type: 'user_organizations',
    jsonApi: 'hasMany',
  },
  current_organization: {
    type: 'organizations',
    jsonApi: 'hasOne',
  },
  user_organization_status: '',
  regulator: '',
};

const model = {
  name,
  shape,
};

export default model;
