import BaseAPI from './base';

class NotesAPI extends BaseAPI {
  async createNotes(payload) {
    const response = await this.jsonApi.create('note', payload, {
      include: ['user'],
    });

    return response;
  }

  async updateNotes(payload) {
    const response = await this.jsonApi.update('note', payload, {
      include: ['user'],
    });

    return response;
  }
}

export default NotesAPI;
