import DashboardIcon from './Icon/DashboardIcon';
import CertificatesIcon from './Icon/CertificatesIcon';
import TransactionsIcon from './Icon/TransactionsIcon';
import AccountsIcon from './Icon/AccountsIcon';
import GeneratorsIcon from './Icon/GeneratorsIcon';
import GenerationIcon from './Icon/GenerationIcon';
import ReportsIcon from './Icon/ReportsIcon';
import HelpIcon from './Icon/HelpIcon';
import DocumentationIcon from './Icon/DocumentationIcon';
import ProgramsIcon from './Icon/ProgramsIcon';
import MarketsIcon from './Icon/MarketsIcon';
import eTagsIcon from './Icon/eTagsIcon';
import { REGIONS } from '../../../../constants';

const rec = [
  {
    path: `/dashboard`,
    icon: DashboardIcon,
    label: 'Dashboard',
    permissions: [],
  },
  {
    path: `/certificates`,
    icon: CertificatesIcon,
    label: 'Certificates',
    permissions: [
      {
        type: 'showIf',
        value: 'viewRecsMenu',
      },
    ],
  },
  {
    path: `/user-organizations`,
    icon: AccountsIcon,
    label: 'Users',
    permissions: [
      {
        type: 'showIf',
        value: 'adminModeEnabled',
      },
    ],
  },
  {
    path: `/organizations`,
    icon: AccountsIcon,
    label: 'Organizations',
    permissions: [
      {
        type: 'showIf',
        value: 'adminModeEnabled',
      },
    ],
  },
  {
    path: `/transfers`,
    icon: TransactionsIcon,
    label: 'Transactions',
    permissions: [
      {
        type: 'hideIf',
        value: 'organizationRegulator',
      },
      {
        type: 'showIf',
        value: 'readTransactionsAndHasAccounts',
      },
    ],
  },
  {
    path: `/accounts`,
    alternatePath: `/account`,
    icon: AccountsIcon,
    label: 'Accounts',
    permissions: [
      {
        type: 'showIf',
        value: 'viewAccounts',
      },
      {
        type: 'hideIf',
        value: 'isMarketAdmin',
      },
    ],
  },
  {
    path: `/generators`,
    alternatePath: `/generator`,
    icon: GeneratorsIcon,
    label: 'Generators',
    permissions: [
      {
        type: 'showIf',
        value: ['viewGeneratorsMenu', 'canReadMarkets'],
      },
    ],
  },
  {
    path: `/dggs`,
    alternatePath: `/dgg`,
    icon: GeneratorsIcon,
    label: 'DG Groups',
    organization: {
      can_register_dggs: true,
    },
    accountType: {
      owns_projects: true,
    },
    permissions: [
      {
        type: 'showIf',
        value: ['viewGeneratorsMenu', 'canReadMarkets', 'adminModeEnabled'],
      },
    ],
  },
  {
    path: `/generation`,
    icon: GenerationIcon,
    label: 'Generation',
    permissions: [
      {
        type: 'hideIf',
        value: 'isMarketAdmin',
      },
      {
        type: 'showIf',
        value: 'viewGenerationsMenu',
      },
    ],
  },
  {
    path: `/etags`,
    alternatePath: `/etag`,
    icon: eTagsIcon,
    label: 'e-Tags',
    permissions: [
      {
        type: 'showIf',
        value: ['viewETags', 'readTransactionsAndHasAccounts'],
        strict: true,
      },
      {
        type: 'hideIf',
        value: 'organizationRegulator',
      },
      {
        type: 'featureFlag',
        showByFeatureFlag: (featureFlags) => featureFlags?.Etag?.isActive,
      },
    ],
  },
  {
    path: `/eligibilities`,
    alternatePath: `/eligibility`,
    icon: ProgramsIcon,
    label: 'Eligibilities',
    permissions: [
      {
        type: 'showIf',
        value: 'programAdministrator',
      },
    ],
  },
  {
    path: `/programs`,
    alternatePath: `/program`,
    icon: ProgramsIcon,
    label: 'Programs',
    permissions: [
      {
        type: 'hideIf',
        value: 'isMarketAdmin',
      },
      {
        type: 'showIf',
        value: 'viewProgramsMenu',
      },
    ],
  },
  {
    path: '/markets',
    alternatePath: '/market',
    icon: MarketsIcon,
    label: 'Markets',
    permissions: [
      {
        type: 'showIf',
        value: 'canReadMarkets',
      },
      {
        type: 'hideIf',
        value: 'isMarketAdmin',
      },
    ],
  },
  {
    path: `/reports`,
    icon: ReportsIcon,
    label: 'Reports',
    permissions: [],
    filterFn: (user, featureFlags) => {
      if (user.region === REGIONS.mrets) {
        return true;
      }

      const isFeatureFlagActive =
        featureFlags?.['report-export-requests']?.isActive || false;

      return isFeatureFlagActive && user.hasPermission('canReadTransactions');
    },
  },
  {
    path: `/help`,
    icon: HelpIcon,
    label: 'Help',
    permissions: [],
    region: REGIONS.mrets,
  },
  {
    path: `/docs`,
    icon: DocumentationIcon,
    label: 'Documentation',
    region: REGIONS.mrets,
    permissions: [
      {
        type: 'showIf',
        value: 'viewAPIDocumentation',
      },
    ],
  },
];

const rtc = [
  {
    path: `/dashboard`,
    icon: DashboardIcon,
    label: 'Dashboard',
    permissions: [],
  },
  {
    path: `/organizations`,
    icon: AccountsIcon,
    label: 'Organizations',
    permissions: [
      {
        type: 'showIf',
        value: 'adminModeEnabled',
      },
    ],
  },
  {
    path: `/certificates`,
    icon: CertificatesIcon,
    label: 'Certificates',
    permissions: [
      {
        type: 'showIf',
        value: 'viewRecsMenu',
      },
    ],
  },
  {
    path: `/transfers`,
    icon: TransactionsIcon,
    label: 'Transactions',
    permissions: [
      {
        type: 'hideIf',
        value: 'organizationRegulator',
      },
      {
        type: 'showIf',
        value: 'readTransactionsAndHasAccounts',
      },
    ],
  },
  {
    path: `/accounts`,
    icon: AccountsIcon,
    label: 'Accounts',
    permissions: [
      {
        type: 'showIf',
        value: 'viewAccounts',
      },
    ],
  },
  {
    path: `/generators`,
    icon: GeneratorsIcon,
    label: 'Generators',
    permissions: [
      {
        type: 'showIf',
        value: ['viewAccounts', 'canReadMarkets'],
      },
    ],
  },
  {
    path: `/generation`,
    icon: GenerationIcon,
    label: 'Generation',
    permissions: [
      {
        type: 'showIf',
        value: 'viewGenerationsMenu',
      },
    ],
  },
  {
    path: `/eligibilities`,
    alternatePath: `/eligibility`,
    icon: ProgramsIcon,
    label: 'Eligibilities',
    permissions: [
      {
        type: 'showIf',
        value: 'programAdministrator',
      },
    ],
  },
  {
    path: `/programs`,
    icon: ProgramsIcon,
    label: 'Programs',
    permissions: [
      {
        type: 'showIf',
        value: 'viewProgramsMenu',
      },
    ],
  },
  {
    path: `/help`,
    icon: HelpIcon,
    label: 'Help',
    permissions: [],
  },
  {
    path: `/docs`,
    icon: DocumentationIcon,
    label: 'Documentation',
    permissions: [
      {
        type: 'showIf',
        value: 'viewAPIDocumentation',
      },
    ],
  },
];

const SIDEBAR_ITEMS = {
  rtc,
  rec,
};

export default SIDEBAR_ITEMS;
