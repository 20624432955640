const name = 'generator';

const shape = {
  additional_owners: null,
  aggregating_unit_count: 0,
  annual_review_date: '',
  approval_date: '',
  approved_as_a_wi_displacement_facility: false,
  biomass_net_generation: null,
  capacity_factor: 0,
  commenced_operation_date: '',
  control_area_operator: '',
  generator_pipeline_connected: false,
  country: '',
  county: '',
  court_regulator_rights_assign: false,
  court_regulator_rights_assign_date: '',
  created_at: '',
  eia_number: '',
  facility_name: '',
  facility_type: '',
  first_eligible_issuance: '',
  generation_technology: '',
  generation_classification: '',
  gen_type: '',
  has_ppa: false,
  hydro_3_year_avg: null,
  is_single_owner_facility: false,
  is_multi_fuel: false,
  is_wi_retail_elec_provider: false,
  is_revenue_quality_meter: false,
  last_meter_certification: '',
  latitude: null,
  longitude: null,
  max_annual_energy: null,
  meter_id: '',
  meter_manufacturer: '',
  meter_type: '',
  mrets_id: '',
  image_url: '',
  name: '',
  nameplate_capacity: '',
  original_registry_id: '',
  organization_name: '',
  ownership_type: [],
  publish_contact: false,
  registration_rights_assign: false,
  registration_rights_assign_date: '',
  reporting_unit_id: '',
  reporting_interval: '',
  repower_dates_and_added_capacities: [],
  retail_elec_providers: null,
  state_province: '',
  eligibility: '',
  status: '',
  status_notes: '',
  units_count: 0,
  status_reason: '',
  submission_date: '',
  annual_production: [],
  updated_at: '',
  uses_hydro_3_year_avg: false,
  wi_rrc_program_registration_date: null,
  wi_rrc_unit_id: null,
  qualifying_facility: false,
  qualifying_facility_id: '',
  balancing_authority_identifier: '',
  interconnected_utility_identifier: '',
  // CoGeneration starts
  wregis_thermal_enabled: false,
  main_gen_id: '',
  wt_gen_id: '',
  wregis_thermal_generator: {
    type: 'generators',
    jsonApi: 'hasOne',
  },
  main_generator: {
    type: 'generators',
    jsonApi: 'hasOne',
  },
  // CoGeneration ends

  // Relationships
  generator_aggregate: {
    type: 'generator_aggregates',
    jsonApi: 'hasOne',
  },
  balancing_authority: {
    type: 'balancing_authorities',
    jsonApi: 'hasOne',
  },
  interconnected_utility: {
    type: 'interconnected_utilities',
    jsonApi: 'hasOne',
  },
  reporting_entity: {
    type: 'organizations',
    jsonApi: 'hasOne',
  },
  issue_to_account: {
    type: 'accounts',
    jsonApi: 'hasOne',
  },
  organization: {
    type: 'organizations',
    jsonApi: 'hasOne',
  },
  owner: {
    type: 'contact',
    jsonApi: 'hasOne',
  },
  operator: {
    type: 'contact',
    jsonApi: 'hasOne',
  },
  generator_units: {
    type: 'generator_unit',
    jsonApi: 'hasMany',
  },
  generator_fuels: {
    type: 'generator_fuel',
    jsonApi: 'hasMany',
  },
  generation_validation_curves: {
    type: 'generation_validation_curve',
    jsonApi: 'hasMany',
  },
  submission_user: {
    type: 'user',
    jsonApi: 'hasOne',
  },
  notes: {
    type: 'note',
    jsonApi: 'hasMany',
  },
};

const model = {
  name,
  shape,
};

export default model;
