/* eslint-disable prettier/prettier */
/* eslint-disable camelcase */
import common from './common';
import mrets__rec from './mrets/rec';
import mrets__rtc from './mrets/rtc';
import wregis__rec from './wregis/rec';
import wregis__rtc from './wregis/rtc';


const themes = {
  common,
  mrets__rec,
  mrets__rtc,
  wregis__rec,
  wregis__rtc,
  
};

export default themes;
