import BaseAPI from '../base';
import { formatQueryData } from '../helpers';

class BalancingAuthorityAPI extends BaseAPI {
  async getBalancingAuthorities(queryData) {
    const newQueryData = formatQueryData(queryData);

    const response = await this.jsonApi.findAll('balancing_authority', {
      ...newQueryData,
    });

    return response;
  }
}

export default BalancingAuthorityAPI;
