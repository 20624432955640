import BaseAPI from './base';
import { queryDataToQueryParams } from './helpers';

class FiltersAPI extends BaseAPI {
  async getFilterOptions({ source, name, search = '' }, queryData = {}) {
    const queryParamsSuffix = queryDataToQueryParams(queryData, {
      page: 1,
      limit: 100,
    });

    const response = await this.axios.get(
      `/api/${source}/filter_options?filter_options=${name}&paginated=1&search=${search}&${queryParamsSuffix}`,
    );

    return response;
  }
}

export default FiltersAPI;
