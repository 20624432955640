/* eslint-disable prettier/prettier */
const recTheme = {
  "light": {
    "primary": "#77b2f4",
    "primary-main": "#77b2f4",
    "primary-subtle": "#b9dafe",
    "primary-light": "#b9dafe",
    "primary-dark": "#4283cb",
    "primary-darker": "#326eb1",
    "secondary": "#71d7a2",
    "secondary-main": "#71d7a2",
    "secondary-subtle": "#aae7c7",
    "secondary-light": "#aae7c7",
    "secondary-dark": "#3ea770",
    "secondary-darker": "#2c9f63",
    "neutral-darker": "#2c405a",
    "navbar-fg": "#ffffff",
    "navbar-bg": "#2c405a",
    "navbar-border-color": "rgba(0, 0, 0, 0)",
    "navbar-burger-fg": "#ffffff",
    "navbar-burger-bg": "#2c405a",
    "pageheader-fg": "#2c405a",
    "pageheader-bg": "#ffffff",
    "button-fg-primary": "#17263a",
    "button-bg-primary": "#71d7a2",
    "button-fg-secondary": "#ffffff",
    "button-bg-secondary": "#5d7390"
  },
  "dark": {
    "primary": "#77b2f4",
    "primary-main": "#77b2f4",
    "primary-subtle": "#b9dafe",
    "primary-light": "#b9dafe",
    "primary-dark": "#4283cb",
    "primary-darker": "#326eb1",
    "secondary": "#71d7a2",
    "secondary-main": "#71d7a2",
    "secondary-subtle": "#aae7c7",
    "secondary-light": "#aae7c7",
    "secondary-dark": "#3ea770",
    "secondary-darker": "#2c9f63",
    "navbar-fg": "#ffffff",
    "navbar-bg": "#2c405a",
    "navbar-border-color": "rgba(0, 0, 0, 0)",
    "navbar-burger-fg": "#ffffff",
    "navbar-burger-bg": "#2c405a",
    "pageheader-fg": "#2c405a",
    "pageheader-bg": "#ffffff",
    "button-fg-primary": "#17263a",
    "button-bg-primary": "#71d7a2",
    "button-fg-secondary": "#ffffff",
    "button-bg-secondary": "#5d7390"
  }
};

export default recTheme;
