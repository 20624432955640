import BaseAPI from './base';
import { FUEL_TYPE } from './constants';
import { formatQueryData } from './helpers';

class FuelTypeAPI extends BaseAPI {
  getModelName(name = 'fuel_type') {
    return FUEL_TYPE.models[this.resourceType][name];
  }

  async getFuelTypes(queryData) {
    const newQueryData = formatQueryData(queryData);

    const modelName = this.getModelName('fuel_type');
    const response = await this.jsonApi.findAll(modelName, {
      ...newQueryData,
    });
    return response;
  }

  async getFuelSources(queryData = {}) {
    const newQueryData = formatQueryData(queryData);

    const modelName = this.getModelName('fuel_source');
    const response = await this.jsonApi.findAll(modelName, {
      ...newQueryData,
    });

    return response;
  }
}

export default FuelTypeAPI;
