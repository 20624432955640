const name = 'generator';

const shape = {
  additional_owners: null,
  aggregating_unit_count: 0,
  annual_review_date: '',
  approval_date: '',
  approved_as_a_wi_displacement_facility: false,
  biomass_net_generation: null,
  capacity_factor: 0,
  commenced_operation_date: '',
  control_area_operator: '',
  country: '',
  county: '',
  court_regulator_rights_assign: false,
  agreed_to_not_part_federal_program: false,
  agreed_to_conditions_and_documentation: false,
  agreed_to_accurate_information: false,
  agreed_to_inform_changes: false,
  created_at: '',
  eia_number: '',
  facility_name: '',
  facility_type: '',
  generation_technology: '',
  hydro_3_year_avg: null,
  is_single_owner_facility: false,
  is_multi_fuel: false,
  is_wi_retail_elec_provider: false,
  is_revenue_quality_meter: false,
  last_meter_certification: '',
  latitude: null,
  longitude: null,
  max_annual_energy: null,
  meter_id: '',
  meter_manufacturer: '',
  meter_type: '',
  mrets_id: '',
  image_url: '',
  name: '',
  nameplate_capacity: '',
  licensed_operator: '',
  operator_license_number: '',
  ownership_type: [],
  publish_contact: false,
  registration_rights_assign: false,
  reporting_unit_id: '',
  repower_dates_and_added_capacities: [],
  retail_elec_providers: null,
  state_province: '',
  eligibility: '',
  status: '',
  status_notes: '',
  status_reason: '',
  submission_date: '',
  annual_production: [],
  updated_at: '',
  uses_hydro_3_year_avg: false,
  wi_rrc_program_registration_date: null,
  wi_rrc_unit_id: null,
  organization_name: '',
  generator_pipeline_connected: false,
  injection_site: null,
  injection_site_description: '',
  injection_site_name: '',
  injection_site_lat: null,
  injection_site_lng: null,
  maximum_plant_capacity: '',
  maximum_plant_output: '',
  registered_for_rins_or_lcfs: false,
  rins_or_lcfs_description: '',
  reporting_entity: {
    type: 'organizations',
    jsonApi: 'hasOne',
  },
  issue_to_account: {
    type: 'accounts',
    jsonApi: 'hasOne',
  },
  organization: {
    type: 'organizations',
    jsonApi: 'hasOne',
  },
  owner: {
    type: 'contact',
    jsonApi: 'hasOne',
  },
  operator: {
    type: 'contact',
    jsonApi: 'hasOne',
  },
  third_party_engineering_verificator: {
    type: 'contact',
    jsonApi: 'hasOne',
  },
  generator_fuels: {
    type: 'generator_fuel',
    jsonApi: 'hasMany',
  },
  submission_user: {
    type: 'user',
    jsonApi: 'hasOne',
  },
  notes: {
    type: 'note',
    jsonApi: 'hasMany',
  },
};

const model = {
  name,
  shape,
};

export default model;
