import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@mui/material';
import { useKeyboardEvent } from '../../../../hooks/keyboardEvent';

const OrganizationMenuOptions = ({
  options,
  displayChip,
  onChange,
  onEscape,
  value,
}) => {
  // Org Selector
  const [selectedOrgId, setSelectedOrgId] = useState(value);

  useEffect(() => {
    setSelectedOrgId(value);
  }, [value]);

  const onOrganizationClick = (org) => (event) => {
    const { checked } = event.target;
    if (checked) {
      setSelectedOrgId(org.id);
      onChange(org);
    }
  };

  useKeyboardEvent('Escape', onEscape);

  return (
    <div className="OrganizationMenuOptions OrganizationMenuOptions__Container">
      <ul className="OrganizationMenuOptions__List OrganizationMenuList">
        {options.map((organization, index) => (
          <li
            key={organization.id}
            className="OrganizationMenuOptions__ListItem OrganizationMenuListItem"
          >
            <label htmlFor={organization.id} className="OrganizationMenuLabel">
              <input
                tabIndex={index}
                id={organization.id}
                type="radio"
                checked={organization.id === selectedOrgId}
                value={organization.id}
                onChange={onOrganizationClick(organization)}
                className="OrganizationMenuInput"
              />
              <div className="OrganizationMenuOptions__ListItem__OrganizationName">
                {organization.name}
              </div>
              {organization.qre && (
                <Chip
                  className="OrganizationMenuOptions__ListItem__Chip OrganizationMenuOptions__ListItem__Chip--qre"
                  label="QRE"
                  variant="outlined"
                  size="small"
                />
              )}
              {displayChip && (
                <Chip
                  className={`OrganizationMenuOptions__ListItem__Chip OrganizationMenuOptions__ListItem__Chip--${organization.resource_type}`}
                  label={organization.resource_type}
                  variant="outlined"
                  size="small"
                />
              )}
            </label>
          </li>
        ))}
      </ul>
    </div>
  );
};

OrganizationMenuOptions.defaultProps = {
  options: [],
  displayChip: false,
};

OrganizationMenuOptions.propTypes = {
  options: PropTypes.array,
  displayChip: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onEscape: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
};

export default OrganizationMenuOptions;
