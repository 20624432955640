const name = 'contact';

const shape = {
  job_title: '',
  street_1: '',
  street_2: '',
  city: '',
  state_province: '',
  postal_code: '',
  name: '',
  po_number: '',
  country: '',
  email: '',
  phone: '',
  fax: '',
  url: '',
  organization: {
    type: 'organizations',
    jsonApi: 'hasOne',
  },
};

const model = {
  name,
  shape,
};

export default model;
