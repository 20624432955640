import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import Logo from './Logo';
import MastHead from './MastHead';
import Content from './Content';
import Sidebar from './Sidebar';
import Burger from './Burger';
import OrganizationsMenu from './OrganizationsMenu';
import AdminSwitch from './AdminSwitch';
import ProfileSettingsMenu from './ProfileSettingsMenu';

const Main = ({ children }) => {
  const [open, setOpen] = useState(true);
  const toggleOpen = () => {
    setOpen(!open);
  };

  const className = classnames('MainLayout MainLayout__Container', {
    'MainLayout--open': open,
    'MainLayout--closed': !open,
  });

  return (
    <div className={className}>
      <MastHead>
        <div className="flex-auto flex items-center">
          <Burger open={open} onClick={toggleOpen} />
          <Link to="/" className="flex items-center ph3 h-100 mr3">
            <Logo />
          </Link>
          <OrganizationsMenu />
          <AdminSwitch />
        </div>
        <div className="flex-auto-1-1 flex items-center justify-end relative">
          <ProfileSettingsMenu />
        </div>
      </MastHead>
      <Content open={open}>{children}</Content>
      <Sidebar open={open} />
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Main;
