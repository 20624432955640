import React from 'react';
import Spinner from '../Spinner';

const Loading = () => (
  <div className="loading-splash-screen transition-opacity rt flex flex-column justify-center items-center fixed top-0 left-0 right-0 bottom-0 bg-dark-gray z-999">
    <Spinner />
    <h1 className="white f4 fw4 font-smoothing neutral-darker">
      Loading M-RETS…
    </h1>
  </div>
);

export default Loading;
