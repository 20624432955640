const name = 'invoice';

const shape = {
  balance: '0.0',
  billing_status: '',
  created_at: '',
  due_date: '',
  notes: null,
  period_end: '',
  quickbooks_id: '',
  period_start: '',
  reason: '',
  status: '',
  total_amount: '0.0',
};

const model = {
  name,
  shape,
};

export default model;
