const name = 'account';

const shape = {
  name: '',
  account_type: '',
  issued_recs_sum: 0,
  created_at: '',
  short_id: '',
  issuing_generators_count: 0,
  status: '',
  program: {
    type: 'programs',
    jsonApi: 'hasOne',
  },
  organization: {
    type: 'organizations',
    jsonApi: 'hasOne',
  },
  certificate_quantities: {
    type: 'certificate_quantities',
    jsonApi: 'hasMany',
  },
  issuing_generators: {
    type: 'generators',
    jsonApi: 'hasMany',
  },
};

const model = {
  name,
  shape,
};

export default model;
