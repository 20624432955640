const name = 'user_transaction';

const shape = {
  ended_at: '',
  started_at: '',
  transaction_type: '',
  status: 0,
  retirement_type: '',
  retirement_reason: '',
  retirement_kind: '',
  retired_quarter: '',
  notes: '',
  admin_notes: '',
  retired_to: '',
  compliance_period: '',
  destination_accounts: [],
  hourly_claim: false,
  export_system: '',
  export_organization_name: '',
  export_organization_id: '',
  to_account: {
    type: 'accounts',
    jsonApi: 'hasOne',
  },
  transaction_details: {
    type: 'transaction_details',
    jsonApi: 'hasMany',
  },
  user: {
    type: 'users',
    jsonApi: 'hasOne',
  },
  retirement_option: {
    type: 'retirement_options',
    jsonApi: 'hasOne',
  },
};

const model = {
  name,
  shape,
};

export default model;
