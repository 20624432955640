const name = 'recurring_transfer_destination';

const shape = {
  destination_id: '',
  destination_name: '',
  destination_type: '',
  percentage: '',
  external_destiny_id: '',
  external_destiny_org: '',
  external_notes: '',
  external_system: '',
};

const model = {
  name,
  shape,
};

export default model;
