import { AUTH_STATUS } from '../components/App/App.constants';
import { useApp } from './app';
import { useMfa } from './mfa';

export const useAuth = () => {
  const { appData, updateAppData } = useApp();
  const { validMfa } = useMfa();

  const setToken = (token = null) => {
    sessionStorage.setItem('token', token);

    updateAppData({
      auth: {
        token,
        status: token ? AUTH_STATUS.authorized : AUTH_STATUS.unauthorized,
      },
    });
  };

  const removeToken = () => {
    sessionStorage.removeItem('token');
  };

  const isAuthenticated = () => {
    const { auth = {} } = appData;
    return auth.status === AUTH_STATUS.authorized && validMfa();
  };

  const authenticate = () => {
    if (appData.auth.status !== AUTH_STATUS.authorized) {
      updateAppData({
        auth: {
          status: isAuthenticated()
            ? AUTH_STATUS.authorized
            : AUTH_STATUS.unauthorized,
        },
      });
    }
  };

  return {
    authenticate,
    setToken,
    isAuthenticated,
    removeToken,
  };
};
