import React from 'react';
import PropTypes from 'prop-types';
import Main from '../Main';
import Public from '../Public';
import { useAuth } from '../../../hooks/auth';

import './Adaptive.css';

const Adaptive = ({ config, children }) => {
  const { isAuthenticated } = useAuth();
  const Layout = isAuthenticated() ? Main : Public;

  return (
    <Layout>
      {children}
      {config.appName !== 'production' && (
        <div className="Layout__Info">
          {!!config.appVersion && (
            <div className="Info__Version">
              App Version:
              <span className="Version__Bold">{config.appVersion}</span>
            </div>
          )}
          {!!config.appName && (
            <div className="Info__Name">
              App Name:
              <span className="Name__Bold">{config.appName}</span>
            </div>
          )}
          {!!config.appApiRoot && (
            <div className="Info__API__Root">
              App API Root:
              <span className="API__Root__Bold">{config.appApiRoot}</span>
            </div>
          )}
        </div>
      )}
    </Layout>
  );
};

Adaptive.propTypes = {
  children: PropTypes.node.isRequired,
  config: PropTypes.object.isRequired,
};

export default Adaptive;
