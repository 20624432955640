const name = 'organization_account_type';

const shape = {
  name: '',
  id: '',
  type: '',
  label: '',
  fee: '',
  fee_label: '',
  created_at: '',
  update_at: '',
  resource_type: {
    type: 'resource_type',
    jsonApi: 'hasOne',
  },
  has_accounts: false,
  views_external_projects: false,
  has_markets: false,
  owns_projects: false,
  retire_recs: false,
  import: false,
  reserve_recs: false,
  has_programs: false,
  view_system_reports: false,
  api_access: false,
  upload_generation: false,
  participates_in_programs: false,
  description: '',
  requires_admin_approval: true,
  extra_question: false,
  extra_question_text: '',
};

const model = {
  name,
  shape,
};

export default model;
