import { useIntl } from 'react-intl';
import { useApp } from './app';

export const useI18N = () => {
  const { appData, updateAppData } = useApp();
  const intl = useIntl();

  const { messages, locale, resourceType, region } = appData;

  const i18nText = (key, values = {}, defaultMessage = '') => {
    return intl.formatMessage(
      {
        id: key,
        defaultMessage: defaultMessage || key,
      },
      values,
    );
  };

  const changeLocale = (localeCode = locale) => {
    updateAppData({
      locale: localeCode,
    });
  };

  const changeResourceType = (resourceTypeCode = resourceType) => {
    updateAppData({
      resourceType: resourceTypeCode,
    });
  };

  return {
    messages,
    locale,
    resourceType,
    region,
    changeLocale,
    changeResourceType,
    i18nText,
  };
};
