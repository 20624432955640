const name = 'api_key';

const shape = {
  auth_token: '',
  status: '',
  created_at: '',
  environment: '',
};

const model = {
  name,
  shape,
};

export default model;
