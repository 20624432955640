import BaseAPI from './base';
import { formatQueryData, getIncludeValues } from './helpers';

class ETagMatchesAPI extends BaseAPI {
  async getETagMatches(queryData) {
    const newQueryData = formatQueryData(queryData);

    const includes = {
      includeAll: [
        'etag',
        'transaction_detail.user_transaction.user',
        'transaction_detail.certificate.generator',
      ],
      includeUniqueBy: {
        'etags.etag_id': ['etag'],
        'generators.mrets_id': ['transaction_detail.certificate.generator'],
        'etags.generator_name': ['etag'],
        'user_transactions.transaction_type': [
          'transaction_detail.user_transaction',
        ],
        'users.email': ['transaction_detail.user_transaction.user'],
      },
    };

    const params = {
      ...newQueryData,
      include: getIncludeValues(newQueryData.unique_by, includes),
      fields: {
        etag_matches: ['quantity', 'etag', 'transaction_detail'],
        etags: ['etag_id', 'start_date', 'stop_date', 'generator_name'],
        transaction_details: [
          'user_transaction',
          'certificate',
          'serial_number_start',
          'serial_number_end',
        ],
        user_transactions: ['ended_at', 'transaction_type', 'user'],
        users: ['email'],
        certificates: [
          'vintage_date',
          'serial_number_base',
          'serial_number_start',
          'serial_number_end',
          'generator',
        ],
        generators: ['mrets_id', 'name'],
      },
    };

    const response = await this.jsonApi.findAll('etag_matches', params);

    return response;
  }
}

export default ETagMatchesAPI;
