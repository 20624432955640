import commonModels from './common';
import recModels from './rec';
import rtcModels from './rtc';

const models = {
  rec: {
    ...commonModels,
    ...recModels,
  },
  rtc: {
    ...commonModels,
    ...rtcModels,
  },
};

export default models;
