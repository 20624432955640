import { useApp } from './app';
import api from '../services/api';

export const useMfa = () => {
  const { appData, updateAppData } = useApp();

  const fetchMfaSetting = async () => {
    const response = await api.user.getSecuritySettings();
    setMfaSettings(response.data);
  };

  const buildMfaSettings = (mfaSettings) => {
    let mfaSetts = null;
    if (mfaSettings) {
      mfaSetts = {
        mfaEnabled: mfaSettings.mfa_enabled,
        mfaPhoneVerified: mfaSettings.mfa_phone_verified,
        mfaSetup: mfaSettings.mfa_setup,
        mfaValid: mfaSettings.mfa_valid,
      };
    }
    return mfaSetts;
  };

  const setMfaSettings = (mfaSettings = {}) => {
    const settings = buildMfaSettings(mfaSettings);
    updateAppData({ mfaSettings: settings });
    sessionStorage.setItem('mfaEnabled', settings.mfaEnabled);
    sessionStorage.setItem('mfaValid', settings.mfaValid);
  };

  const getMfaSettings = () => {
    return appData.mfaSettings;
  };

  const validMfa = () => {
    let valid = false;
    const mfaEnabled = sessionStorage.getItem('mfaEnabled');
    const mfaValid = sessionStorage.getItem('mfaValid');
    if (
      mfaEnabled !== null &&
      mfaValid !== null &&
      (mfaEnabled === 'false' || (mfaEnabled === 'true' && mfaValid === 'true'))
    ) {
      valid = true;
    }
    return valid;
  };

  const removeMfa = () => {
    sessionStorage.removeItem('mfaEnabled');
    sessionStorage.removeItem('mfaValid');
  };

  return {
    removeMfa,
    validMfa,
    setMfaSettings,
    getMfaSettings,
    fetchMfaSetting,
  };
};
