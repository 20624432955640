import BaseAPI from '../base';
import { formatQueryData } from '../helpers';
import { PROGRAM_DETAIL_PARAMS } from './program.constants';

class ProgramAPI extends BaseAPI {
  async createProgram(payload) {
    return this.jsonApi.create('program', payload);
  }

  async updateProgram(id, payload) {
    return this.jsonApi.update('program', { id, ...payload });
  }

  async getPrograms(queryData) {
    const newQueryData = formatQueryData(queryData);

    const response = await this.jsonApi.findAll('program', {
      ...newQueryData,
      include: ['administrator'],
    });

    return response;
  }

  async getProgram(programId, type = 'default') {
    const params =
      PROGRAM_DETAIL_PARAMS[type]?.[this.resourceType] ||
      PROGRAM_DETAIL_PARAMS.default;

    const response = await this.jsonApi.find('program', programId, params);

    return response;
  }

  async getCertificateQuantitiesByFuelType(programId, status = 'retired') {
    return this.axios.get(
      this.buildUrl(
        `/programs/${programId}/certs_by_fuel_type?status=${status}`,
      ),
    );
  }

  async withdrawParticipant(programId, participantId) {
    const url = `${this.jsonApi.urlFor({
      model: 'program',
      id: programId,
    })}/withdraw_participant`;

    return this.jsonApi.customRequest('POST', url, 'program', {
      participant_id: participantId,
    });
  }
}

export default ProgramAPI;
