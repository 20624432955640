import BaseAPI from './base';
import { formatQueryData } from './helpers';

class CarbonPathwayAPI extends BaseAPI {
  async getCarbonPathways(queryData) {
    const newQueryData = formatQueryData(queryData);

    const response = await this.jsonApi.findAll('carbon_pathway', newQueryData);

    return response;
  }
}

export default CarbonPathwayAPI;
