const name = 'resource_type';

const shape = {
  name: '',
  id: '',
  type: '',
  slug: '',
};

const model = {
  name,
  shape,
};

export default model;
