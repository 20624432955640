const name = 'fuel_source';

const shape = {
  description: '',
  fuel_type: {
    type: 'fuel_type',
    jsonApi: 'hasOne',
  },
};

const model = {
  name,
  shape,
};

export default model;
