/* eslint-disable react/jsx-no-constructed-context-values */
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider as RollbarProvider, ErrorBoundary } from '@rollbar/react';
import { IntlProvider } from 'react-intl';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { SnackbarProvider } from 'notistack';
import themes from './themes';
import muiThemeGenerator from './themes/mui';
import App from './App.component';
import AppContext from './App.context';
import {
  APP_DEFAULT_VALUES,
  AUTH_STATUS,
  rollbarConfig,
} from './App.constants';
import { getMessages } from '../../i18n';
import api from '../../services/api';
import { getUserRegion, getRegionByDomain } from '../../utils/helpers';
import { FeatureFlagContextWrapper } from '../shared/FeatureFlagContext/FeatureFlagContext.component';
import { REGIONS } from '../../constants';

const AppContainer = () => {
  const [appData, setAppData] = useState({
    isAppLoading: false,
    resourceType: APP_DEFAULT_VALUES.resourceType,
    theme: APP_DEFAULT_VALUES.theme,
    region: getUserRegion(),
    themeType: APP_DEFAULT_VALUES.themeType,
    locale: APP_DEFAULT_VALUES.locale,
    messages: null,
    auth: {
      status: sessionStorage.getItem('token')
        ? AUTH_STATUS.authorized
        : AUTH_STATUS.initial,
    },
    user: null,
  });

  const updateAppData = (newData) => {
    setAppData((prevState) => ({
      ...prevState,
      ...newData,
    }));
  };

  const fetchMessages = async () => {
    let messages = {};

    try {
      messages = await getMessages(appData);
    } catch (err) {
      // Messages didn't load, we have default messages for this case
    }

    updateAppData({
      messages,
    });
  };

  useEffect(() => {
    fetchMessages();
    api.applyResourceType(appData.resourceType);
  }, [appData.locale, appData.resourceType, appData.region]);

  // This useEffect is for ZenDesk script creation.
  // This code also appends the script to the body of the main html
  useEffect(() => {
    const script = document.createElement('script');
    const zendDeskKey = process.env.REACT_APP_ZENDESK_KEY || '';
    const regionByDomain = getRegionByDomain();
    script.id = 'ze-snippet';
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${zendDeskKey}`;
    script.async = true;

    if (zendDeskKey && regionByDomain === REGIONS.mrets) {
      document.body.appendChild(script);
    }

    return () => {
      if (zendDeskKey && regionByDomain === REGIONS.mrets) {
        document.body.removeChild(script);
      }
    };
  }, []);

  if (!appData.messages) {
    return null;
  }

  const appContextValue = {
    ...appData,
    updateAppData,
  };

  const currentThemeKey = `${appData.region}__${appData.theme}`;
  const currentTheme = {
    ...themes.common,
    ...themes[currentThemeKey][appData.themeType],
  };

  const muiTheme = muiThemeGenerator(currentTheme, appData.themeType);

  return (
    <AppContext.Provider value={appContextValue}>
      <FeatureFlagContextWrapper>
        <RollbarProvider config={rollbarConfig}>
          <ErrorBoundary>
            <IntlProvider
              messages={appData.messages}
              locale={appData.locale}
              defaultLocale={APP_DEFAULT_VALUES.locale}
            >
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={muiTheme}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <SnackbarProvider
                      maxSnack={1}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                    >
                      <Router>
                        <App />
                      </Router>
                    </SnackbarProvider>
                  </LocalizationProvider>
                </ThemeProvider>
              </StyledEngineProvider>
            </IntlProvider>
          </ErrorBoundary>
        </RollbarProvider>
      </FeatureFlagContextWrapper>
    </AppContext.Provider>
  );
};

export default AppContainer;
