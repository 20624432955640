export const ORGANIZATIONS_FIELDS = {
  show: {
    organizations: [
      'account_type',
      'billing_contact',
      'can_register_dggs',
      'is_wregis_thermal_qre',
      'etag_indemnity_agreement',
      'contact',
      'created_at',
      'effective_date',
      'effective_date_override',
      'friendly_id',
      'has_generators_to_review',
      'has_market_accounts',
      'is_wi_service_provider',
      'is_ieso_service_provider',
      'customer_has_load',
      'market',
      'mfa',
      'name',
      'notes',
      'qre',
      'ontario_customer_account',
      'organization_type',
      'pse_codes',
      'quickbooks_id',
      'read_ontario_rules',
      'region',
      'resource_type',
      'status',
      'subscription_renewal_month',
      'updated_at',
      'pse_codes',
      'api_access_by_org',
      'eligibilities',
      'primary_contact_user',
    ],
  },
  index: {
    organizations: [
      'account_type',
      'billing_contact',
      'can_register_dggs',
      'is_wregis_thermal_qre',
      'contact',
      'created_at',
      'effective_date',
      'friendly_id',
      'has_market_accounts',
      'is_wi_service_provider',
      'market',
      'mfa',
      'name',
      'qre',
      'notes',
      'organization_type',
      'quickbooks_id',
      'region',
      'resource_type',
      'status',
      'subscription_renewal_month',
      'updated_at',
      'is_ieso_service_provider',
      'customer_has_load',
      'read_ontario_rules',
      'ontario_customer_account',
    ],
  },
};
