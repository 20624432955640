const name = 'generation_entry';

const shape = {
  organization_name: '',
  generation_period_start: '',
  generation_period_end: '',
  date_of_action: '',
  rollback_eligible: false,
  status: '',
  status_control: {},
  fuel_allocation: [],
  fuel_allocation_type: '',
  fuel_type_names: '',
  notes: '',
  fuel: '',
  document_url: '',
  document_name: '',
  quantity: '',
  quantity_unit: [],
  reported_source: '',
  certificates: {
    type: 'certificates',
    jsonApi: 'hasMany',
  },
  generator: {
    type: 'generators',
    jsonApi: 'hasOne',
  },
  organization: {
    type: 'organization',
    jsonApi: 'hasOne',
  },
};

const model = {
  name,
  shape,
};

export default model;
