import { useState } from 'react';
import { useApp } from './app';

export const useLoading = () => {
  const { appData, updateAppData } = useApp();

  const { isAppLoading } = appData;

  const appDoLoad = (value = !isAppLoading) => {
    updateAppData({
      isAppLoading: value,
    });
  };

  const doLoad = (initialState = false) => {
    const [isLoading, setIsLoading] = useState(initialState);

    const toggleIsLoading = () => {
      setIsLoading(!isLoading);
    };

    return { isLoading, setIsLoading, toggleIsLoading };
  };

  return {
    isAppLoading,
    appDoLoad,
    doLoad,
  };
};
