const name = 'program_fuel';

const shape = {
  updated_at: '',
  created_at: '',
  fuel_sources: {
    type: 'fuel_sources',
    jsonApi: 'hasMany',
  },
  fuel_type: {
    type: 'fuel_types',
    jsonApi: 'hasOne',
  },
  program: {
    type: 'programs',
    jsonApi: 'hasOne',
  },
};

const model = {
  name,
  shape,
};

export default model;
