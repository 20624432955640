const name = 'eligibility_bond';

const shape = {
  details: {},
  related_generators: 0,
  start_date: '',
  verified_date: '',
  good_thru_date: '',
  certification_number: '',
  eligible: false,
  fuel_source: {
    type: 'fuel_sources',
    jsonApi: 'hasOne',
  },
  eligibility: {
    type: 'eligibilities',
    jsonApi: 'hasOne',
  },
  eligibility_bondable: {
    jsonApi: 'hasOne', // Polymorphic Relationship - https://github.com/twg/devour#polymorphic-relationships
  },
};

const model = {
  name,
  shape,
};

export default model;
