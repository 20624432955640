import BaseAPI from './base';
import { formatQueryData } from './helpers';

class UserAPI extends BaseAPI {
  async getUsers(queryData) {
    const newQueryData = formatQueryData(queryData);
    const response = await this.jsonApi.findAll('user', {
      ...newQueryData,
      include: [
        'user_organizations.organization',
        'user_organizations.tickets.ticket_documents',
        'user_organizations.tickets.request_user',
      ],
    });

    return response;
  }

  async createUser(userData) {
    const response = await this.jsonApi.create('user', {
      ...userData,
      include: ['current_organization', 'user_organizations'],
    });
    return response;
  }

  async getUser(userId, organizationId = null) {
    const response = await this.jsonApi.find('user', userId, {
      current_org_id: organizationId,
      include: [
        'current_organization.market.certificate_quantities',
        'user_organizations.tickets',
        'user_organizations.organization',
      ],
      fields: {
        organizations:
          'id,name,account_type,resource_type,mfa,has_generators_to_review,market',
        programs: ['certificate_quantities'],
        certificate_quantities: ['id'],
      },
    });
    return response;
  }

  async getUserPreferences(userId) {
    const response = await this.axios.get(
      this.buildUrl(`/users/${userId}/preferences`),
    );
    return response;
  }

  async updateUserPreferences(userId, organizationId, preferences) {
    const response = await this.axios.put(
      this.buildUrl(
        organizationId
          ? `/users/${userId}/preferences?organization_id=${organizationId}`
          : `/users/${userId}/preferences`,
      ),
      {
        data: {
          attributes: {
            ...preferences,
          },
        },
      },
    );
    return response;
  }

  async getUserOrganizations(page = 1, searchValue = '') {
    const response = await this.jsonApi.findAll('organization', {
      filter: {
        current_user_organizations: true,
        name: searchValue,
      },
      sort: 'name',
      page: {
        number: page,
        size: 25,
      },
      fields: {
        organizations: ['name', 'qre', 'region', 'resource_type'],
      },
    });
    return response;
  }

  async getUserOrganizationsCount() {
    const response = await this.jsonApi.findAll('organization', {
      filter: { current_user_organizations: true },
      page: { size: 1 },
    });

    return response.meta.record_count;
  }

  async updateUserData(userId, params) {
    return this.jsonApi.update('user', { id: userId, ...params });
  }

  async registerMfaPhone(countryCode, phoneNumber, mfaOption) {
    const payload = {
      country_code: countryCode,
      phone: phoneNumber,
    };
    if (mfaOption) {
      payload.mfa = mfaOption;
    }

    return this.axios.post('/api/security/register', {
      country_code: countryCode,
      phone: phoneNumber,
      mfa: mfaOption,
    });
  }

  async verifyToken(token) {
    return this.axios.post('/api/security/verify', {
      token,
    });
  }

  async requestSmsToken() {
    return this.axios.get('/api/security/sms');
  }

  async requestVoiceToken() {
    return this.axios.get('/api/security/voice');
  }

  async getSecuritySettings() {
    return this.axios.get('/api/security/settings');
  }
}

export default UserAPI;
