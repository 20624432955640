import BaseAPI from './base';
import { formatQueryData } from './helpers';

class EligibilityAPI extends BaseAPI {
  async getEligibilities(queryData) {
    const newQueryData = formatQueryData(queryData);
    const response = await this.jsonApi.findAll('eligibility', {
      ...newQueryData,
    });
    return response;
  }

  async getEligibility(id) {
    const response = await this.jsonApi.find('eligibility', id, {});
    return response;
  }
}

export default EligibilityAPI;
