const name = 'recurring_transfer';

const shape = {
  max_quantity: '',
  percentage: '',
  transaction_type: '',
  is_irrevocable: '',
  vintage_start: '',
  vintage_end: '',
  status: '',
  notes: '',
  organization: {
    type: 'organizations',
    jsonApi: 'hasOne',
  },
  generator_fuel: {
    type: 'generator_fuels',
    jsonApi: 'hasOne',
  },
  recurring_transfer_destinations: {
    type: 'recurring_transfer_destinations',
    jsonApi: 'hasMany',
  },
  recurring_transfer_destinations_attributes: [],
};

const model = {
  name,
  shape,
};

export default model;
