import React from 'react';
import { useUser } from '../../hooks/user';
import { getUserRegion } from '../../utils/helpers';
import { SPINNERS } from './Spinner.constants';

import './Spinner.scss';

const Spinner = (props) => {
  const { user } = useUser();

  const region = getUserRegion(user);

  const SpinnerComponent = SPINNERS[region] || SPINNERS.default;

  return (
    <div className="Spinner">
      <SpinnerComponent {...props} />
    </div>
  );
};

export default Spinner;
