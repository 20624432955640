import BaseAPI from './base';

class PseCodeAPI extends BaseAPI {
  async createPseCode(payload) {
    return this.jsonApi.create('pse_code', payload, {
      include: ['organization'],
    });
  }

  async deletePseCode(pseCodeId) {
    return this.jsonApi.destroy('pse_code', pseCodeId);
  }
}

export default PseCodeAPI;
