import axios from 'axios';
import get from 'lodash/get';
import Qs from 'qs';
import { errorMiddleware } from './middleware';
import { getRegionByDomain } from '../utils/helpers';
import { REGIONS_NAMES } from '../constants';

const createAxiosInstance = () => {
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_ROOT,
    paramsSerializer: (params) => {
      return Qs.stringify(params, { arrayFormat: 'brackets' });
    },
  });

  axiosInstance.interceptors.request.use(
    (config) => {
      const initialHeaders = get(config, 'header', {});
      const token = sessionStorage.getItem('token');
      const region = REGIONS_NAMES[getRegionByDomain()];
      let headers = {
        ...initialHeaders,
        'X-Region': region,
      };
      if (token) {
        headers = {
          ...headers,
          Authorization: token,
        };
      }
      return {
        ...config,
        headers,
      };
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  axiosInstance.interceptors.response.use((response) => {
    return response;
  }, errorMiddleware);

  return axiosInstance;
};

export default createAxiosInstance;
