const error401 = (error) => {
  if (window.location.pathname !== '/login') {
    sessionStorage.setItem('expired', 'true');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('mfaEnabled');
    sessionStorage.removeItem('mfaValid');
    window.location.href = '/login';
  }
  return Promise.reject(error);
};

const error500 = (error) => {
  if (window.location.pathname !== '/500') {
    window.location.href = '/500';
    return Promise.reject(error);
  }
  return null;
};

const errorsMap = {
  401: error401,
  500: error500,
};

export const errorMiddleware = (error) => {
  const errorFn = errorsMap[error?.response?.status || 'default'];
  if (errorFn) {
    errorFn(error);
  }
  return Promise.reject(error);
};
