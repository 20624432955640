import { useState, useEffect } from 'react';

export const useDebounce = () => {
  const [debouncedId, setDebouncedId] = useState(null);

  const debounce = (callback, timeout = 400) => {
    if (debouncedId) {
      clearTimeout(debouncedId);
    }

    const newDebouncedId = setTimeout(callback, timeout);

    setDebouncedId(newDebouncedId);

    return newDebouncedId;
  };

  useEffect(() => {
    return () => {
      if (debouncedId) {
        clearTimeout(debouncedId);
      }
    };
  }, []);

  return {
    debouncedId,
    debounce,
  };
};
