import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Burger.scss';

const Burger = ({ open, onClick }) => {
  const className = classNames('BurgerButton', {
    'BurgerButton--open': open,
  });

  return (
    <button className={className} type="button" open={open} onClick={onClick}>
      <span />
      <span />
      <span />
    </button>
  );
};

Burger.defaultProps = {
  open: false,
};

Burger.propTypes = {
  open: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default Burger;
