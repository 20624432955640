const name = 'organization';

const shape = {
  name: '',
  short_id: '',
  friendly_id: '',
  account_type_id: '',
  effective_date: '',
  effective_date_override: '',
  etag_indemnity_agreement: false,
  status: '',
  organization_type: '',
  resource_type_id: '',
  contact_attributes: {},
  billing_contact_attributes: {},
  user_attributes: {},
  resource_type: '',
  region: '',
  qre: false,
  has_market_accounts: false,
  account_type_name: '',
  quickbooks_id: '',
  subscription_renewal_month: '',
  has_generators_to_review: false,
  can_register_dggs: false,
  is_wregis_thermal_qre: false,
  is_wi_service_provider: null,
  mfa: false,
  api_access_by_org: false,
  is_ieso_service_provider: false,
  customer_has_load: false,
  read_ontario_rules: false,
  ontario_customer_account: false,
  account_type: {
    type: 'organization_account_types',
    jsonApi: 'hasOne',
  },
  contact: {
    type: 'contact',
    jsonApi: 'hasOne',
  },
  primary_contact_user: {
    type: 'users',
    jsonApi: 'hasOne',
  },
  billing_contact: {
    type: 'contact',
    jsonApi: 'hasOne',
  },
  market: {
    type: 'program',
    jsonApi: 'hasOne',
  },
  pse_codes: {
    type: 'pse_code',
    jsonApi: 'hasMany',
  },
  notes: {
    type: 'note',
    jsonApi: 'hasMany',
  },
  eligibility_bonds: {
    type: 'eligibility_bonds',
    jsonApi: 'hasMany',
  },
  eligibilities: {
    type: 'eligibilities',
    jsonApi: 'hasMany',
  },
};

const model = {
  name,
  shape,
};

export default model;
