const name = 'etag_match';

const shape = {
  quantity: '',
  quantity_unit: '',
  etag: {
    type: 'etags',
    jsonApi: 'hasOne',
  },
  transaction_detail: {
    type: 'transaction_details',
    jsonApi: 'hasOne',
  },
};

const model = {
  name,
  shape,
};

export default model;
