/* eslint-disable import/prefer-default-export */

export const items = [
  {
    label: 'Profile',
    to: '/profile',
  },
  {
    label: 'Organization Info',
    to: `/organization/info`,
  },
];
