import { useContext } from 'react';
import {
  FeatureFlagContext,
  CreateResetFlagsAction,
} from '../components/shared/FeatureFlagContext/FeatureFlagContext.component';

export const useFeatureFlags = () => {
  const { state: featureFlags, dispatch } = useContext(FeatureFlagContext);

  const ResetFeatureFlags = () => {
    dispatch(CreateResetFlagsAction());
  };

  return { featureFlags: featureFlags || {}, ResetFeatureFlags };
};
