const name = 'transaction_detail';

const shape = {
  quantity: 0,
  quantity_modifier: '',
  serial_number_start: 0,
  serial_number_end: 0,
  created_at: '',
  updated_at: '',
  is_outgoing: false,
  is_incoming: false,
  from_account: {
    type: 'accounts',
    jsonApi: 'hasOne',
  },
  from_organization: {
    type: 'organizations',
    jsonApi: 'hasOne',
  },
  user_transaction: {
    type: 'user_transactions',
    jsonApi: 'hasOne',
  },
  to_account: {
    type: 'accounts',
    jsonApi: 'hasOne',
  },
  to_organization: {
    type: 'organizations',
    jsonApi: 'hasOne',
  },
  certificate: {
    type: 'certificates',
    jsonApi: 'hasOne',
  },
  etag_quantities: {
    type: 'etag_quantities',
    jsonApi: 'hasMany',
  },
};

const model = {
  name,
  shape,
};

export default model;
