import React from 'react';

const AccountsIcon = () => {
  return (
    <svg
      width="28"
      height="21"
      viewBox="0 0 28 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.69533 10.886C5.3469 11.3859 5.70463 12.0712 6.31401 12.0712H7.87778L7.59515 14.9551C7.52006 15.7213 8.50628 16.0943 8.95701 15.4702L12.9582 9.92974C13.3183 9.43103 12.962 8.73414 12.3468 8.73414H10.4743L10.5541 6.33971C10.5791 5.58907 9.61119 5.26724 9.18175 5.88342L5.69533 10.886ZM8.55957 10.5578H7.37381L8.87446 8.4046L8.84842 9.18592C8.83071 9.71738 9.25674 10.1578 9.7885 10.1578H11.257L9.38153 12.7549L9.49568 11.5902C9.54988 11.0371 9.1153 10.5578 8.55957 10.5578Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.407 8.02941H19.6103C18.1605 8.02941 16.9853 9.20466 16.9853 10.6544C16.9853 12.1042 18.1605 13.2794 19.6103 13.2794H25.407L25.4768 13.239C25.5732 13.1833 25.6324 13.083 25.6324 12.9706V8.33823C25.6324 8.22587 25.5732 8.12551 25.4768 8.06978L25.407 8.02941ZM19.6103 14.8235C17.3078 14.8235 15.4412 12.957 15.4412 10.6544C15.4412 8.35187 17.3078 6.48529 19.6103 6.48529H24.7059V3.08824C24.7059 2.23544 24.0146 1.54412 23.1618 1.54412H3.08824C2.23544 1.54412 1.54412 2.23544 1.54412 3.08824V17.9118C1.54412 18.7646 2.23544 19.4559 3.08823 19.4559H23.1618C24.0146 19.4559 24.7059 18.7646 24.7059 17.9118V14.8235H19.6103ZM23.1618 0C24.8673 0 26.25 1.38265 26.25 3.08824V6.73318C26.8038 7.05357 27.1765 7.65239 27.1765 8.33823V12.9706C27.1765 13.6564 26.8038 14.2553 26.25 14.5756V17.9118C26.25 19.6173 24.8673 21 23.1618 21H3.08823C1.38265 21 0 19.6174 0 17.9118V3.08824C0 1.38265 1.38265 0 3.08824 0H23.1618Z"
        fill="white"
      />
      <path
        d="M20.3825 10.6544C20.3825 11.2513 19.8985 11.7353 19.3016 11.7353C18.7046 11.7353 18.2207 11.2513 18.2207 10.6544C18.2207 10.0574 18.7046 9.57349 19.3016 9.57349C19.8985 9.57349 20.3825 10.0574 20.3825 10.6544Z"
        fill="white"
      />
    </svg>
  );
};

export default AccountsIcon;
