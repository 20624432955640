import BaseAPI from './base';
import { formatQueryData } from './helpers';

class OrganizationAccountTypeAPI extends BaseAPI {
  async getOrganizationAccountTypes(queryData = {}) {
    const newQueryData = formatQueryData(queryData);

    return this.jsonApi.findAll('organization_account_types', {
      ...newQueryData,
      include: ['resource_type'],
    });
  }
}

export default OrganizationAccountTypeAPI;
