import { createTheme, adaptV4Theme } from '@mui/material/styles';
import commonTheme from './common';

const getColorsForType = (theme, type = 'primary') => {
  const themeKeys = Object.keys(theme);

  const keys = themeKeys.filter((key) => key.startsWith(type));

  let colorsForType = null;

  if (keys.length) {
    colorsForType = keys.reduce((prev, currentKey) => {
      // eslint-disable-next-line
      let [_, key] = currentKey.split('-');

      if (!key) {
        key = 'main';
      }

      if (key === 'contrast') {
        key = 'contrastText';
      }

      return {
        ...prev,
        [key]: theme[currentKey],
      };
    }, {});
  }

  return colorsForType;
};

const getPalette = (theme) => {
  const palette = {
    error: {
      main: theme.red,
    },
  };

  const primary = getColorsForType(theme, 'primary');
  const secondary = getColorsForType(theme, 'secondary');
  const error = getColorsForType(theme, 'error');
  const warning = getColorsForType(theme, 'warning');
  const info = getColorsForType(theme, 'info');
  const success = getColorsForType(theme, 'success');

  if (primary) {
    palette.primary = primary;
  }

  if (secondary) {
    palette.secondary = secondary;
  }

  if (error) {
    palette.error = error;
  }

  if (warning) {
    palette.warning = warning;
  }

  if (info) {
    palette.info = info;
  }

  if (success) {
    palette.success = success;
  }

  return palette;
};

const getTypography = (theme) => {
  const typography = {};

  const fontFamily = theme['font-family'];

  if (fontFamily) {
    typography.fontFamily = fontFamily
      .split(',')
      .map((font) => font.trim().replace('"', ''))
      .join(',');
  } else {
    typography.fontFamily = commonTheme['nunito-sans']
      .split(',')
      .map((font) => `'${font.trim()}'`)
      .join(',');
  }

  return typography;
};

const muiThemeGenerator = (theme, type) => {
  const palette = getPalette(theme);
  const typography = getTypography(theme);

  return createTheme(
    adaptV4Theme({
      palette,
      type,
      typography,
      overrides: {
        MuiDialog: {
          paper: {
            borderRadius: '0',
            boxShadow: theme['shadow-2'],
          },
        },
        MuiTab: {
          root: {
            paddingLeft: theme['spacing-medium-2q'],
            paddingRight: theme['spacing-medium-2q'],
            textTransform: 'none',
            fontFamily: 'inherit',
            fontSize: theme['type-scale-f5'],
            transition: 'border-color .2s ease-in-out',
            'border-bottom': '4px solid transparent',
            '&:focus,&:hover': {
              'border-bottom-color': theme['mid-gray'],
            },
            '@media (min-width: 600px)': {
              minWidth: 'auto',
            },
          },
          textColorInherit: {
            '&$selected': {
              fontWeight: 700,
            },
          },
        },
        MuiTabs: {
          indicator: {
            height: '4px',
            backgroundColor: theme.secondary,
          },
        },
        MuiInput: {
          input: {
            '&[type="text"]': {
              boxSizing: 'content-box',
            },
          },
        },
        MuiButtonBase: {
          root: {
            margin: null,
            '&:focus': {
              outline: 'none',
            },
          },
        },
      },
    }),
  );
};

export default muiThemeGenerator;
