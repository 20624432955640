import BaseAPI from './base';
import { MIME_TYPES } from './constants';
import { formatQueryData, queryDataToQueryParams } from './helpers';

class ReportsAPI extends BaseAPI {
  async getCertificates(resource, queryParams) {
    const queryParamsString = queryDataToQueryParams(queryParams);
    const response = await this.axios.get(
      this.buildUrl(`/reports/certificates/${resource}?${queryParamsString}`),
    );

    return response.data;
  }

  async createIesoReportRequest(reportType, payload) {
    const response = await this.axios.post(
      this.buildUrl(`/ieso_reports/${reportType}`),
      payload,
    );

    return response.data;
  }

  async createReportExportRequest(payload, queryData, modelName, format) {
    const response = await this.jsonApi.create(
      'report_export_request',
      payload,
    );

    const rerID = response.data.id;
    const queryParamsSuffix = queryDataToQueryParams({
      ...queryData,
      report_export_request_id: rerID,
    });

    const uri = this.jsonApi.urlFor({ model: modelName });
    const contentType = MIME_TYPES[format.toLowerCase()] || MIME_TYPES.default;

    await this.axios.get(`${uri}.${format}?${queryParamsSuffix}`, {
      headers: {
        Accept: contentType,
      },
    });

    return response;
  }

  async acceptReportExportRequest(rerID) {
    const response = await this.axios.put(
      this.buildUrl(`/report_export_requests/${rerID}/accept`),
    );
    return response;
  }

  async rejectReportExportRequest(rerID, notes) {
    const response = await this.axios.put(
      this.buildUrl(`/report_export_requests/${rerID}/reject`),
      {
        data: {
          attributes: {
            notes,
          },
        },
      },
    );
    return response;
  }

  async getReportExportRequests(queryParams) {
    const newQueryData = formatQueryData(queryParams);

    const response = await this.jsonApi.findAll('report_export_requests', {
      ...newQueryData,
      include: [
        'ticket',
        'ticket.request_user',
        'ticket.organization',
        'ticket.ended_by_user',
      ],
    });

    return response;
  }

  getReportURL(path) {
    return this.buildUrl(`/reports/${path}`);
  }
}

export default ReportsAPI;
