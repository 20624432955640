import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import MainNav from '../../../shared/MainNav';

import './Sidebar.scss';

const Sidebar = ({ open, items }) => {
  const className = cn(
    'Sidebar Sidebar__Container fixed bottom-0 top-3 overflow-auto font-smoothing translateZ0 transition-left',
    {
      'left-0 Sidebar--open': open,
      'left--14_375 Sidebar--closed': !open,
    },
  );

  return (
    <div className={className}>
      <MainNav items={items} />
    </div>
  );
};

Sidebar.defaultProps = {
  open: true,
  items: [],
};

Sidebar.propTypes = {
  open: PropTypes.bool,
  items: PropTypes.array,
};

export default Sidebar;
