/* eslint-disable import/prefer-default-export */
import themes from '../components/App/themes';
import { useApp } from './app';

export const useAppTheme = () => {
  const { appData, updateAppData } = useApp();
  const { region } = appData;
  const theme = themes[name];
  const name = appData.theme;
  const type = appData.themeType;

  const changeTheme = (
    themeName = name,
    themeType = type,
    themeRegion = region,
  ) => {
    updateAppData({
      region: themeRegion,
      theme: themeName,
      themeType,
    });
  };

  return {
    theme,
    name,
    region,
    type,
    changeTheme,
  };
};
