import generatorUnit from './generatorUnit';
import eTag from './eTag';
import eTagQuantity from './eTagQuantity';
import etagMatch from './etagMatch';

const recModels = {
  generatorUnit,
  eTag,
  eTagQuantity,
  etagMatch,
};

export default recModels;
