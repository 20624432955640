export const getMessages = async ({ resourceType, region, locale }) => {
  const commonMessagesModule = await import(
    `./common/${locale.toLowerCase()}.json`
  );
  const resourceTypeMessagesModule = await import(
    `./${resourceType.toLowerCase()}/${locale.toLowerCase()}.json`
  );

  let regionResourceTypeMessagesModule = {
    default: {},
  };

  let regionCommonMessagesModule = {
    default: {},
  };

  try {
    regionCommonMessagesModule = await import(
      `./${region.toLowerCase()}/common/${locale.toLowerCase()}.json`
    );

    regionResourceTypeMessagesModule = await import(
      `./${region.toLowerCase()}/${resourceType.toLowerCase()}/${locale.toLowerCase()}.json`
    );
  } catch (err) {
    // do nothing
  }

  const commonMessages = commonMessagesModule.default;
  const resourceTypeMessages = resourceTypeMessagesModule.default;
  const regionCommonMessages = regionCommonMessagesModule.default;
  const regionResourceTypeMessages = regionResourceTypeMessagesModule.default;

  return {
    ...commonMessages,
    ...regionCommonMessages,
    ...resourceTypeMessages,
    ...regionResourceTypeMessages,
  };
};
