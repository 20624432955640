import React, { useEffect, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import api from '../../../services/api';

export const FeatureFlagContext = React.createContext(null);

export const CreateSetFlagsAction = (value) => ({ type: 'setFlags', value });
export const CreateResetFlagsAction = () => ({ type: 'resetFlags' });

function reducer(state, action) {
  switch (action.type) {
    case 'setFlags':
      return { ...action.value };
    case 'resetFlags':
      return null;
    default:
      throw new Error('FeatureFlagContextWrapper Action type not valid!');
  }
}

export const FeatureFlagContextWrapper = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, null);
  const [isCallingApi, setIsCallingApi] = useState(false);

  useEffect(() => {
    const getFeatureFlags = async () => {
      const flags = await api.featureFlag.getFeatureFlagsDict();
      dispatch(CreateSetFlagsAction(flags));
      setIsCallingApi(false);
    };

    if (!state && !isCallingApi.current) {
      setIsCallingApi(true);
      getFeatureFlags();
    }
  }, [state]);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <FeatureFlagContext.Provider value={{ state, dispatch }}>
      {children}
    </FeatureFlagContext.Provider>
  );
};

FeatureFlagContextWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default {
  FeatureFlagContextWrapper,
  FeatureFlagContext,
  CreateSetFlagsAction,
  CreateResetFlagsAction,
};
