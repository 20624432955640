const name = 'generator_unit';

const shape = {
  unit_id: '',
  name: '',
  revenue_meter_id: '',
  commenced_operation_date: '',
  nameplate_capacity: 0,
  expansion_indicator: false,
  status: '',
  created_at: '',
  updated_at: '',
  generator: {
    type: 'generators',
    jsonApi: 'hasOne',
  },
  contact: {
    type: 'contacts',
    jsonApi: 'hasOne',
  },
};

const model = {
  name,
  shape,
};

export default model;
