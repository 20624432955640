import React from 'react';

const TransactionsIcon = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.699 6.75768H1.79895C1.38473 6.75768 1.04895 6.42189 1.04895 6.00768C1.04895 5.59346 1.38473 5.25768 1.79895 5.25768H17.699L13.7217 1.28033C13.4288 0.987437 13.4288 0.512563 13.7217 0.21967C14.0146 -0.0732233 14.4895 -0.0732233 14.7824 0.21967L20.04 5.47735C20.3329 5.77024 20.3329 6.24511 20.04 6.53801L14.7824 11.7957C14.4895 12.0886 14.0146 12.0886 13.7217 11.7957C13.4288 11.5028 13.4288 11.0279 13.7217 10.735L17.699 6.75768ZM19.1903 15.4533C19.6045 15.4533 19.9403 15.1175 19.9403 14.7033C19.9403 14.2891 19.6045 13.9533 19.1903 13.9533H3.29015L7.2675 9.97598C7.56039 9.68309 7.56039 9.20821 7.2675 8.91532C6.97461 8.62243 6.49973 8.62243 6.20684 8.91532L0.949162 14.173C0.656269 14.4659 0.656269 14.9408 0.949162 15.2337L6.20684 20.4913C6.49973 20.7842 6.97461 20.7842 7.2675 20.4913C7.56039 20.1984 7.56039 19.7236 7.2675 19.4307L3.29015 15.4533H19.1903Z"
        fill="white"
      />
    </svg>
  );
};

export default TransactionsIcon;
