import BaseAPI from '../base';
import { formatQueryData, getFieldsValues } from '../helpers';

class AccountAPI extends BaseAPI {
  async getAccount(id) {
    const response = await this.jsonApi.find('account', id, {
      include: [
        'organization',
        'program',
        'program.administrator',
        'issuing_generators',
      ],
      fields: {
        accounts: [
          'name',
          'account_type',
          'issued_recs_sum',
          'created_at',
          'short_id',
          'issuing_generators_count',
          'status',
          'organization',
          'program',
          'issuing_generators',
        ],
        organizations: ['name', 'id'],
        programs: ['name', 'id', 'is_market', 'administrator'],
        generators: ['name', 'id'],
      },
    });
    return response;
  }

  async createAccount(payload) {
    return this.jsonApi.create('account', payload);
  }

  async updateAccount(accountId, payload) {
    return this.jsonApi.update('account', { id: accountId, ...payload });
  }

  async closeAccount(accountId) {
    const response = await this.jsonApi.customRequest(
      'PATCH',
      `${this.jsonApi.urlFor({ model: 'account', id: accountId })}/close`,
      'account',
      {
        include: [
          'organization',
          'program',
          'program.administrator',
          'issuing_generators',
        ],
        'fields[organizations]': ['name', 'id'],
        'fields[accounts]': [
          'issued_recs_sum',
          'issuing_generators_count',
          'organization',
          'program',
          'issuing_generators',
        ],
        'fields[programs]': ['name', 'id', 'is_market', 'administrator'],
        'fields[generators]': ['name', 'id'],
      },
    );
    return response;
  }

  async getAccounts(queryData) {
    const newQueryData = formatQueryData(queryData);
    const fields = {
      accounts: [
        'name',
        'short_id',
        'created_at',
        'status',
        'issuing_generators_count',
        'issued_recs_sum',
        'organization',
        'account_type',
      ],
      programs: ['name'],
      organizations: ['name'],
    };
    const response = await this.jsonApi.findAll('account', {
      ...newQueryData,
      include: ['program', 'organization'],
      fields: getFieldsValues(
        newQueryData.unique_by,
        fields,
        this.resourceType,
      ),
    });

    return response;
  }
}

export default AccountAPI;
