const name = 'calc';

const shape = {
  result: '',
};

const model = {
  name,
  shape,
};

export default model;
