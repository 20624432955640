import { DEFAULT_PERMISSIONS } from '../components/App/App.constants';
import { REGIONS, RESOURCE_TYPES } from '../constants';

export default class User {
  constructor(data = {}) {
    this.permissions = data.permissions || DEFAULT_PERMISSIONS;
    this.resourceType = data.resourceType || RESOURCE_TYPES.rec;
    this.region = data.region || REGIONS.mrets;
    this.id = data.id || undefined;
    this.userData = data.userData || {};
    this.userName = data.userName || { firstName: '', lastName: '' };
    this.email = data.email || '';
    this.organizations = data.organizations || [];
    this.hasMultipleOrgs = data.hasMultipleOrgs || false;
    this.userOrganization = data.userOrganization || undefined;
    this.notifications = data.notifications || {};
    this.isWregis = data.region === REGIONS.WREGIS;
  }

  get fullName() {
    let fullname = '';

    if (this.userData && this.userData.first_name && this.userData.last_name) {
      fullname = `${this.userData.first_name} ${this.userData.last_name}`;
    }

    return fullname;
  }

  isAccountType(property, expectedValue) {
    return (
      this.userOrganization?.organization?.account_type?.[property] ===
      expectedValue
    );
  }

  isOrgAttribute(property, expectedValue) {
    return this.userOrganization?.organization?.[property] === expectedValue;
  }

  isUserType(property, expectedValue = true) {
    return this.userData?.user_types?.[property] === expectedValue;
  }

  isQRE() {
    return (
      this.isUserType('reporting_entity') && this.isOrgAttribute('qre', true)
    );
  }

  isSuperAdmin() {
    return this.permissions?.superAdmin;
  }

  isRegionalAdmin() {
    return this.permissions?.regionalAdmin;
  }

  isAdmin() {
    return this.isSuperAdmin() || this.isRegionalAdmin();
  }

  isAdminModeEnabled() {
    return this.permissions?.adminModeEnabled;
  }

  hasPermission(permission) {
    return this.permissions[permission];
  }

  updatePermissions(permissions = {}) {
    this.permissions = {
      ...this.permissions,
      ...permissions,
    };
  }

  updateOrganization(organization, partial = true) {
    let organizationData = organization;

    // Organizations lists
    const organizationIndex = this.organizations.findIndex(
      ({ id }) => organization.id === id,
    );

    if (organizationIndex >= 0) {
      if (partial) {
        organizationData = {
          ...this.userOrganization.organization,
          ...organization,
        };
      }

      this.organizations[organizationIndex] = organizationData;
    } else {
      this.organizations.push(organizationData);
    }

    // UserOrganization
    this.userOrganization.organization = organizationData;
  }
}
