import React from 'react';
import PropTypes from 'prop-types';
import MainNavMenuItem from './MainNavMenuItem.component';

import './MainNav.scss';

const MainNav = ({ items }) => (
  <nav className="MainNav">
    <ul>
      {items.map((item) => (
        <li key={item.path}>
          <MainNavMenuItem {...item} />
        </li>
      ))}
    </ul>
  </nav>
);

MainNav.defaultProps = {
  items: [],
};

MainNav.propTypes = {
  items: PropTypes.array,
};

export default MainNav;
