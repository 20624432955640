/* eslint-disable import/prefer-default-export */
import { useEffect } from 'react';

export const useKeyboardEvent = (key, callback) => {
  useEffect(() => {
    const handler = (event) => {
      if (event.key === key) {
        callback();
      }
    };
    window.addEventListener('keydown', handler);
    return () => {
      window.removeEventListener('keydown', handler);
    };

    // eslint-disable-next-line
  }, []);
};
