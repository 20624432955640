const name = 'program_participant_invite';

const shape = {
  answer: '',
  program: {
    type: 'programs',
    jsonApi: 'hasOne',
  },
  organization: {
    type: 'organizations',
    jsonApi: 'hasOne',
  },
};

const model = {
  name,
  shape,
};

export default model;
