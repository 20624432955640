const name = 'certificate_quantity';

const shape = {
  quantity: 0,
  organization_name: '',
  serial_number_start: 0,
  serial_number_end: 0,
  rrc_quantity: '',
  created_at: '',
  updated_at: '',
  notes: '',
  status: '',
  is_hourly_claimable: false,
  has_etag_quantities: false,
  certificate: {
    type: 'certificates',
    jsonApi: 'hasOne',
  },
  account: {
    type: 'accounts',
    jsonApi: 'hasOne',
  },
  transaction_detail: {
    type: 'transaction_details',
    jsonApi: 'hasOne',
  },
  etag_quantities: {
    type: 'etag_quantities',
    jsonApi: 'hasMany',
  },
};

const model = {
  name,
  shape,
};

export default model;
