import React from 'react';

const ReportsIcon = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.75 0H14.2666C14.4555 0 14.6374 0.071261 14.776 0.199546L21.2594 6.19955C21.4128 6.3415 21.5 6.54101 21.5 6.75V18.75C21.5 20.2688 20.2688 21.5 18.75 21.5H2.75C1.23122 21.5 0 20.2688 0 18.75V2.75C0 1.23122 1.23122 0 2.75 0ZM19.5904 6.69873L15.0166 2.46596V6.69873H19.5904ZM13.5166 1.5V7.44873C13.5166 7.86294 13.8524 8.19873 14.2666 8.19873H20V18.75C20 19.4404 19.4404 20 18.75 20H2.75C2.05964 20 1.5 19.4404 1.5 18.75V2.75C1.5 2.05964 2.05964 1.5 2.75 1.5H13.5166Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 17.25C3 16.8358 3.33579 16.5 3.75 16.5H17.25C17.6642 16.5 18 16.8358 18 17.25C18 17.6642 17.6642 18 17.25 18H3.75C3.33579 18 3 17.6642 3 17.25Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.1501 17.75C4.73588 17.75 4.4001 17.4142 4.4001 17V13C4.4001 12.5858 4.73588 12.25 5.1501 12.25C5.56431 12.25 5.9001 12.5858 5.9001 13V17C5.9001 17.4142 5.56431 17.75 5.1501 17.75Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.3 17.75C6.88579 17.75 6.55 17.4142 6.55 17V14C6.55 13.5858 6.88579 13.25 7.3 13.25C7.71421 13.25 8.05 13.5858 8.05 14V17C8.05 17.4142 7.71421 17.75 7.3 17.75Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.4499 17.75C9.03569 17.75 8.6999 17.4142 8.6999 17V12C8.6999 11.5858 9.03569 11.25 9.4499 11.25C9.86412 11.25 10.1999 11.5858 10.1999 12V17C10.1999 17.4142 9.86412 17.75 9.4499 17.75Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5998 17.75C11.1856 17.75 10.8498 17.4142 10.8498 17V11C10.8498 10.5858 11.1856 10.25 11.5998 10.25C12.014 10.25 12.3498 10.5858 12.3498 11V17C12.3498 17.4142 12.014 17.75 11.5998 17.75Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7497 17.75C13.3355 17.75 12.9997 17.4142 12.9997 17V14C12.9997 13.5858 13.3355 13.25 13.7497 13.25C14.1639 13.25 14.4997 13.5858 14.4997 14V17C14.4997 17.4142 14.1639 17.75 13.7497 17.75Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8996 17.75C15.4854 17.75 15.1496 17.4142 15.1496 17V12C15.1496 11.5858 15.4854 11.25 15.8996 11.25C16.3138 11.25 16.6496 11.5858 16.6496 12V17C16.6496 17.4142 16.3138 17.75 15.8996 17.75Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.25 5C3.25 5.41421 3.58579 5.75 4 5.75H6.5C6.91421 5.75 7.25 5.41421 7.25 5C7.25 4.58579 6.91421 4.25 6.5 4.25H4C3.58579 4.25 3.25 4.58579 3.25 5Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.25 7.25C3.25 7.66421 3.58579 8 4 8H8.5C8.91421 8 9.25 7.66421 9.25 7.25C9.25 6.83579 8.91421 6.5 8.5 6.5H4C3.58579 6.5 3.25 6.83579 3.25 7.25Z"
        fill="white"
      />
    </svg>
  );
};

export default ReportsIcon;
