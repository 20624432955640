import { get } from 'lodash';
import React from 'react';
import { useUser } from '../../../../hooks/user';
import AdminSwitch from './AdminSwitch.component';

const AdminSwitchContainer = () => {
  const { user, toggleAdminMode } = useUser();

  if (!user || !get(user.permissions, 'adminMode')) {
    return null;
  }

  return (
    <AdminSwitch
      isChecked={!!get(user.permissions, 'adminModeEnabled')}
      toggleAdminMode={toggleAdminMode}
    />
  );
};

export default AdminSwitchContainer;
