const name = 'certificate_hourly_data';

const shape = {
  amount: 0,
  day: '',
  day_of_week: null,
  hour: 0,
  peak: false,
};

const model = {
  name,
  shape,
};

export default model;
