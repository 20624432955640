const name = 'note';

const shape = {
  content: '',
  visibility: '',
  created_at: '',
  owner: {
    jsonApi: 'hasOne',
  },
  user: {
    jsonApi: 'hasOne',
    type: 'users',
  },
};

const model = {
  name,
  shape,
};

export default model;
