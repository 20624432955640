import React from 'react';

const LockIcon = () => {
  return (
    <svg
      className="mr1"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 8.667V6.762C17 4.132 14.76 2 12 2S7 4.133 7 6.762v1.905H6c-1.1 0-2 .857-2 1.904v9.525C4 21.143 4.9 22 6 22h12c1.1 0 2-.857 2-1.905V10.57c0-1.046-.9-1.903-2-1.903h-1zm-5 8.57c-1.1 0-2-.856-2-1.904 0-1.047.9-1.904 2-1.904s2 .856 2 1.903c0 1.048-.9 1.905-2 1.905zm3.1-8.57H8.9V6.762c0-1.63 1.39-2.952 3.1-2.952 1.71 0 3.1 1.323 3.1 2.952v1.905z"
        fill="#333"
      />
    </svg>
  );
};
export default LockIcon;
