export const PROGRAM_DETAIL_PARAMS = {
  default: {
    include: [
      'participants',
      'program_participant_invites.organization',
      'administrator',
    ],
  },
  program: {
    rec: {
      include: [
        'participants',
        'program_participant_invites.organization',
        'administrator',
        'eligibilities',
        'generators',
        'program_fuels',
        'program_fuels.fuel_type',
      ],
      fields: {
        generators: ['name', 'facility_name'],
        program_fuels: ['fuel_type'],
        fuel_type: ['code', 'name'],
      },
    },
    rtc: {
      include: [
        'participants',
        'program_participant_invites.organization',
        'administrator',
        'eligibilities',
        'generators',
        'program_fuels',
        'program_fuels.thermal_resource',
        'program_fuels.feedstocks',
      ],
      fields: {
        generators: ['name', 'facility_name'],
        program_fuels: ['thermal_resource', 'feedstocks'],
        thermal_resource: ['code', 'name'],
        feedstock: ['description'],
      },
    },
  },
};
