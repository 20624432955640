const name = 'ticket_message';

const shape = {
  message: '',
  created_at: '',
  updated_at: '',
  ticket: {
    type: 'tickets',
    jsonApi: 'hasOne',
  },
  user: {
    type: 'users',
    jsonApi: 'hasOne',
  },
  ticket_documents: {
    type: 'ticket_documents',
    jsonApi: 'hasMany',
  },
};

const model = {
  name,
  shape,
};

export default model;
