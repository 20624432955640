const name = 'generator_fuel';

const shape = {
  label: '',
  updated_at: '',
  created_at: '',
  autogenerated: false,
  remainder_mwh: 0.0,
  feedstock: {
    type: 'feedstocks',
    jsonApi: 'hasOne',
  },
  carbon_pathways: {
    type: 'carbon_pathways',
    jsonApi: 'hasMany',
  },
  eligibilities: {
    type: 'eligibilities',
    jsonApi: 'hasMany',
  },
  eligibility_bonds: {
    type: 'eligibility_bonds',
    jsonApi: 'hasMany',
  },
  generator: {
    type: 'generators',
    jsonApi: 'hasOne',
  },
};

const model = {
  name,
  shape,
};

export default model;
