import React from 'react';

const GeneratorsIcon = () => {
  return (
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4795 21.5C5.54243 21.5 0.729492 16.6871 0.729492 10.75C0.729492 4.81294 5.54243 0 11.4795 0C17.4166 0 22.2295 4.81294 22.2295 10.75C22.2295 16.6871 17.4166 21.5 11.4795 21.5ZM11.4795 20C16.5881 20 20.7295 15.8586 20.7295 10.75C20.7295 5.64137 16.5881 1.5 11.4795 1.5C6.37086 1.5 2.22949 5.64137 2.22949 10.75C2.22949 15.8586 6.37086 20 11.4795 20ZM7.47949 12.963C6.87343 12.963 6.51765 12.2814 6.86418 11.7841L12.0654 4.32117C12.4925 3.70835 13.4551 4.02842 13.4302 4.77498L13.2983 8.73457H16.4795C17.0913 8.73457 17.4457 9.42767 17.0875 9.92367L11.1184 18.1891C10.6701 18.8098 9.68928 18.4389 9.76396 17.6768L10.2259 12.963H7.47949ZM8.91636 11.463H11.053C11.4962 11.463 11.8427 11.8451 11.7995 12.2861L11.5276 15.0605L15.0127 10.2346H12.5229C12.0989 10.2346 11.7592 9.88336 11.7733 9.45959L11.8467 7.2584L8.91636 11.463Z"
        fill="white"
      />
    </svg>
  );
};

export default GeneratorsIcon;
