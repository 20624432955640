import { RESOURCE_TYPES } from '../../constants.js';

export const INCOMING_TRANSACTION_TYPES = [
  'issuance',
  'import',
  'export',
  'external_transfer',
  'external transfer',
];

export const OUTGOING_TRANSACTION_TYPES = [
  'issuance rollback',
  'export',
  'external_transfer',
  'external transfer',
];

export const FEEDSTOCK_ON_TRANSACTIONS = {
  id: 'feedstock_name',
  label: 'table.columns.fuelSource',
  draggable: true,
  formatterType: 'default',
  valuePath: 'certificate.generator_fuel.feedstock.description',
  sortPath: 'certificate.generator_fuel.feedstock.description',
  filter: {
    type: 'list',
    name: 'feedstocks',
    filterKey: 'feedstock',
    uniqueBy: 'fuel_sources.description',
    optionLabel: 'certificate.generator_fuel.feedstock.description',
    optionId: 'certificate.generator_fuel.feedstock.id',
  },
  resourceType: RESOURCE_TYPES.rtc,
};

export const THERMAL_RESOURCE_ON_TRANSACTIONS = {
  id: 'thermal_resource_name',
  label: 'table.columns.fuelType',
  draggable: true,
  valuePath: 'certificate.thermal_resource.name',
  sortPath: 'certificate.thermal_resource.name',
  formatterType: 'default',
  filter: {
    name: 'thermal_resources',
    type: 'list',
    filterKey: 'thermal_resource',
    optionId: 'certificate.thermal_resource.id',
    optionLabel: 'certificate.thermal_resource.name',
    uniqueBy: 'fuel_types.name',
  },
  resourceType: RESOURCE_TYPES.rtc,
};
