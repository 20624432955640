import React from 'react';

const eTagsIcon = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.75 0.460571H2.75C1.23122 0.460571 0 1.69179 0 3.21057V19.2106C0 20.7294 1.23122 21.9606 2.75 21.9606H18.75C20.2688 21.9606 21.5 20.7294 21.5 19.2106V3.21057C21.5 1.69179 20.2688 0.460571 18.75 0.460571ZM1.5 3.21057C1.5 2.52022 2.05964 1.96057 2.75 1.96057H18.75C19.4404 1.96057 20 2.52022 20 3.21057V19.2106C20 19.9009 19.4404 20.4606 18.75 20.4606H2.75C2.05964 20.4606 1.5 19.9009 1.5 19.2106V3.21057Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.13599 12.2463C7.78946 12.7436 8.14524 13.4252 8.7513 13.4252H11.4977L11.0358 18.1391C10.9611 18.9011 11.9419 19.272 12.3902 18.6513L18.3593 10.3859C18.7175 9.88988 18.3631 9.19678 17.7513 9.19678H14.5701L14.7021 5.23719C14.7269 4.49063 13.7643 4.17056 13.3372 4.78338L8.13599 12.2463ZM12.3248 11.9252H10.1882L13.1185 7.72061L13.0451 9.9218C13.031 10.3456 13.3707 10.6968 13.7947 10.6968H16.2845L12.7994 15.5227L13.0713 12.7483C13.1145 12.3073 12.768 11.9252 12.3248 11.9252Z"
        fill="white"
      />
      <path
        d="M8.434 7.01857H4.771C4.815 7.51724 4.96533 7.88757 5.222 8.12957C5.47867 8.37157 5.84533 8.49257 6.322 8.49257C6.916 8.49257 7.45867 8.3019 7.95 7.92057L8.346 8.86657C8.09667 9.0719 7.78133 9.2369 7.4 9.36157C7.026 9.48624 6.64833 9.54857 6.267 9.54857C5.387 9.54857 4.69033 9.29924 4.177 8.80057C3.671 8.3019 3.418 7.6199 3.418 6.75457C3.418 6.20457 3.528 5.7169 3.748 5.29157C3.968 4.86624 4.276 4.53624 4.672 4.30157C5.07533 4.0669 5.53 3.94957 6.036 3.94957C6.77667 3.94957 7.35967 4.1879 7.785 4.66457C8.21767 5.14124 8.434 5.79757 8.434 6.63357V7.01857ZM6.069 4.91757C5.70233 4.91757 5.40533 5.0349 5.178 5.26957C4.958 5.50424 4.82233 5.84157 4.771 6.28157H7.257C7.24233 5.83424 7.13233 5.4969 6.927 5.26957C6.72167 5.0349 6.43567 4.91757 6.069 4.91757Z"
        fill="white"
      />
    </svg>
  );
};

export default eTagsIcon;
