/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import SharedAdminSwitch from '../../../shared/AdminSwitch';

const AdminSwitch = ({ isChecked, toggleAdminMode }) => (
  <div className="button-reset db relative h3 ph3 toggle admin-toggle flex items-center minw4_5">
    <label htmlFor="adminMode" className="mb0 mr2">
      Admin Mode
    </label>
    <span>
      <SharedAdminSwitch
        id="adminMode"
        checked={isChecked}
        onToggle={toggleAdminMode}
      />
    </span>
  </div>
);

AdminSwitch.defaultProps = {
  isChecked: false,
};

AdminSwitch.propTypes = {
  isChecked: PropTypes.bool,
  toggleAdminMode: PropTypes.func.isRequired,
};

export default AdminSwitch;
