import BaseAPI from './base';
import { formatQueryData, getFieldsValues, getIncludeValues } from './helpers';
import { MIME_TYPES, CERTIFICATES_TABLE_CONFIG } from './constants';

class CertificateAPI extends BaseAPI {
  async getCertificateQuantities(queryData, type = 'active') {
    const includes = CERTIFICATES_TABLE_CONFIG[type].includes || {};
    const fields = CERTIFICATES_TABLE_CONFIG[type].fields || {};
    const newQueryData = formatQueryData(queryData);
    const response = await this.jsonApi.findAll('certificate_quantity', {
      ...newQueryData,
      include: getIncludeValues(
        newQueryData.unique_by,
        includes[this.resourceType],
      ),
      fields: getFieldsValues(
        newQueryData.unique_by,
        fields,
        this.resourceType,
      ),
    });

    return response;
  }

  async unretireCertificate(certificateQuantityId, notes = '') {
    const url = `${this.jsonApi.urlFor({
      model: 'certificate_quantity',
      id: certificateQuantityId,
    })}/unretire`;

    return this.jsonApi.customRequest(
      'PATCH',
      url,
      'certificate_quantity',
      {},
      { notes },
    );
  }

  async unreserveCertificate(certificateQuantityId, notes = '') {
    const url = `${this.jsonApi.urlFor({
      model: 'certificate_quantity',
      id: certificateQuantityId,
    })}/unreserve`;

    return this.jsonApi.customRequest(
      'PATCH',
      url,
      'certificate_quantity',
      {},
      { notes },
    );
  }

  async encumberCertificate(certificateQuantityId) {
    const url = `${this.jsonApi.urlFor({
      model: 'certificate_quantity',
      id: certificateQuantityId,
    })}/encumber`;

    return this.jsonApi.customRequest('PATCH', url, 'certificate_quantity');
  }

  async unencumberCertificate(certificateQuantityId) {
    const url = `${this.jsonApi.urlFor({
      model: 'certificate_quantity',
      id: certificateQuantityId,
    })}/unencumber`;

    return this.jsonApi.customRequest('PATCH', url, 'certificate_quantity');
  }

  async getCertificateReportByTimeUnit(
    certificateIds,
    {
      timeUnit = 'by_hour_and_day',
      timeZone = 'UTC',
      operation = 'agg',
      extension = null,
    },
  ) {
    let url = this.buildUrl(`/certificates/reports/${timeUnit}`);

    let requestOptions = {};
    if (extension) {
      url = `${url}.${extension}`;

      const contentType =
        MIME_TYPES[extension.toLowerCase()] || MIME_TYPES.default;

      requestOptions = {
        headers: {
          Accept: contentType,
        },
        responseType: 'blob',
      };
    }

    const certificateIdsParams = certificateIds.join('&certificate_ids[]=');

    url = `${url}?certificate_ids[]=${certificateIdsParams}&operation=${operation}&time_zone=${timeZone}&time_unit=${timeUnit}`;

    return this.axios.get(url, requestOptions);
  }

  async importCertificate(payload) {
    const response = await this.axios.post(
      this.buildUrl(`/certificates/admin_import`),
      payload,
    );

    return response;
  }
}

export default CertificateAPI;
