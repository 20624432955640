const name = 'ticket_document';

const shape = {
  document_type: '',
  created_at: '',
  updated_at: '',
  document_url: '',
  document_name: '',
  ticket_target_object: {
    jsonApi: 'hasOne',
  },
};

const model = {
  name,
  shape,
};

export default model;
