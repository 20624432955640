import { REGIONS, RESOURCE_TYPES } from '../../constants';

export const APP_DEFAULT_VALUES = {
  locale: 'en',
  resourceType: RESOURCE_TYPES.rec,
  theme: RESOURCE_TYPES.rec,
  region: REGIONS.mrets,
  themeType: 'light',
};

export const AUTH_STATUS = {
  initial: 'initial',
  authorized: 'authorized',
  unauthorized: 'unauthorized',
};

export const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_CLIENT_ACCESS_TOKEN,
  environment: process.env.REACT_APP_ROLLBAR_ENV,
};

export const DEFAULT_PERMISSIONS = {
  adminMode: false,
  adminModeEnabled: false,
  readGenerators: false,
  superAdmin: false,
  regionalAdmin: false,
  manageGenerators: false,
  manageTransactions: false,
  readOrganization: false,
  readTransactions: false,
  readRetirements: false,
  readReserves: false,
  readTransactionsAndHasAccounts: false,
  managePrograms: false,
  hasExternalPrograms: false,
  hasGeneratorsToReview: false,
  organizationRegulator: false,
  systemReportsAccess: false,
  viewRecsMenu: false,
  viewGeneratorsMenu: false,
  adminModeEnabledOrRegulator: false,
  notAdminModeEnabledOrRegulator: false,
  viewGenerationsMenu: false,
  organizationOwnsGenerator: false,
  viewProgramsMenu: false,
  viewAPIDocumentation: false,
  viewAccounts: false,
  viewCarbonPathways: false,
  viewDocumentDownload: false,
};

export const API_DOCS_DOMAINS = {
  production: 'https://docs.mrets.org',
  sandbox: 'https://docs.sandbox.mrets.org',
  staging: 'https://docs.staging.mrets.org',
};
