import React from 'react';
import PropTypes from 'prop-types';
import { Switch as MuiSwitch } from '@mui/material';
import classNames from 'classnames';

import './AdminSwitch.scss';

const AdminSwitch = ({ isChecked, onToggle, className, ...otherProps }) => {
  const switchClassName = classNames('AdminSwitch', className, {
    'AdminSwitch--checked': isChecked,
  });

  return (
    <MuiSwitch
      type="checkbox"
      name="admin-toggle"
      className={switchClassName}
      checked={isChecked}
      onChange={onToggle}
      {...otherProps}
    />
  );
};

AdminSwitch.defaultProps = {
  isChecked: false,
  className: '',
};

AdminSwitch.propTypes = {
  isChecked: PropTypes.bool,
  className: PropTypes.string,
  onToggle: PropTypes.func.isRequired,
};

export default AdminSwitch;
