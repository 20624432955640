import React from 'react';

const HelpIcon = () => {
  return (
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.47949 0H19.4795C20.9983 0 22.2295 1.23122 22.2295 2.75V18.75C22.2295 20.2688 20.9983 21.5 19.4795 21.5H3.47949C1.96071 21.5 0.729492 20.2688 0.729492 18.75V2.75C0.729492 1.23122 1.96071 0 3.47949 0ZM3.47949 1.5C2.78914 1.5 2.22949 2.05964 2.22949 2.75V18.75C2.22949 19.4404 2.78914 20 3.47949 20H19.4795C20.1698 20 20.7295 19.4404 20.7295 18.75V2.75C20.7295 2.05964 20.1698 1.5 19.4795 1.5H3.47949ZM4.84233 5.36287C4.84233 5.77709 5.17811 6.11287 5.59233 6.11287H11.5923C12.0065 6.11287 12.3423 5.77709 12.3423 5.36287C12.3423 4.94866 12.0065 4.61287 11.5923 4.61287H5.59233C5.17811 4.61287 4.84233 4.94866 4.84233 5.36287ZM5.59233 9.11287C5.17811 9.11287 4.84233 8.77709 4.84233 8.36287C4.84233 7.94866 5.17811 7.61287 5.59233 7.61287H8.59233C9.00654 7.61287 9.34233 7.94866 9.34233 8.36287C9.34233 8.77709 9.00654 9.11287 8.59233 9.11287H5.59233ZM11.4795 13.4753C10.8734 13.4753 10.5177 12.7937 10.8642 12.2965L14.3316 7.32117C14.7587 6.70835 15.7214 7.02842 15.6965 7.77498L15.6172 10.1564H17.4795C18.0913 10.1564 18.4457 10.8495 18.0875 11.3455L14.1081 16.8558C13.6598 17.4765 12.679 17.1055 12.7537 16.3435L13.0348 13.4753H11.4795ZM12.9164 11.9753H13.8619C14.305 11.9753 14.6515 12.3574 14.6083 12.7985L14.5173 13.7272L16.0127 11.6564H14.8418C14.4178 11.6564 14.0781 11.3052 14.0922 10.8814L14.1129 10.2584L12.9164 11.9753Z"
        fill="white"
      />
    </svg>
  );
};

export default HelpIcon;
