const name = 'eligibility';

const shape = {
  slug: '',
  short_name: '',
  long_name: '',
  details_schema: {},
};

const model = {
  name,
  shape,
};

export default model;
