const name = 'retirement_option';

const shape = {
  compliance_period: '',
  description: '',
  main_reason: '',
  name: '',
  quarter: '',
  reason: '',
  resource_type: '',
  retired_to: '',
};

const model = {
  name,
  shape,
};

export default model;
