import carbonPathway from './carbonPathway';
import certificate from './certificate';
import certificateQuantity from './certificateQuantity';
import feedstock from './feedstock';
import generator from './generator';
import generatorFuel from './generatorFuel';
import generationEntry from './generationEntry';
import program from './program';
import programFuel from './programFuel';
import thermalResource from './thermalResource';
import transactionDetail from './transactionDetail';

const rtcModels = {
  carbonPathway,
  certificate,
  certificateQuantity,
  feedstock,
  generator,
  generatorFuel,
  generationEntry,
  program,
  programFuel,
  thermalResource,
  transactionDetail,
};

export default rtcModels;
