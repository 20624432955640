import BaseAPI from './base';
import saveFileAs from '../utils/saveFileAs';
import { parseDateToString } from '../utils/helpers';
import { USER_TRANSACTION, USER_TRANSACTION_FIELDS } from './constants';
import { formatTransactionType } from '../components/Transfers/helpers';

class UserTransactionAPI extends BaseAPI {
  async createUserTransaction(payload) {
    return this.jsonApi.create('user_transaction', payload);
  }

  async enqueueUserTransaction(userTransactionId) {
    return this.jsonApi.customRequest(
      'PATCH',
      `${this.jsonApi.urlFor({
        model: 'user_transaction',
        id: userTransactionId,
      })}/enqueue`,
      'user_transaction',
    );
  }

  async withdrawUserTransaction(userTransactionId) {
    return this.jsonApi.customRequest(
      'PUT',
      `${this.jsonApi.urlFor({
        model: 'user_transaction',
        id: userTransactionId,
      })}/withdraw`,
      'user_transaction',
    );
  }

  async acceptUserTransaction(userTransactionId, payload) {
    return this.jsonApi.customRequest(
      'PUT',
      `${this.jsonApi.urlFor({
        model: 'user_transaction',
        id: userTransactionId,
      })}/accept`,
      'user_transaction',
      {},
      {
        data: {
          attributes: payload,
        },
      },
    );
  }

  async rejectUserTransaction(userTransactionId, payload) {
    return this.jsonApi.customRequest(
      'PUT',
      `${this.jsonApi.urlFor({
        model: 'user_transaction',
        id: userTransactionId,
      })}/reject`,
      'user_transaction',
      {},
      {
        data: {
          attributes: payload,
        },
      },
    );
  }

  async getReceipt(userTransactionId, transactionType = 'transfer') {
    const headers = {
      Accept: 'application/pdf',
    };

    const response = await this.axios.get(
      this.buildUrl(`/user_transactions/${userTransactionId}/receipt.pdf`),
      { headers, responseType: 'blob' },
    );

    const transactionTypeFormatted = formatTransactionType(transactionType);
    const date = parseDateToString(new Date());

    saveFileAs(
      `${transactionTypeFormatted}-${date}.pdf`,
      response.data,
      'application/pdf',
    );
  }

  async getUserTransaction(userTransactionId) {
    const response = await this.jsonApi.find(
      'user_transaction',
      userTransactionId,
      {
        include: USER_TRANSACTION[this.resourceType].includeAll,
        fields: USER_TRANSACTION_FIELDS[this.resourceType],
      },
    );

    return response;
  }

  async postMassCertificateTransferUpload(formDataValues) {
    const form = new FormData();
    form.append('file', formDataValues.file);

    const response = await this.axios.post(
      this.buildUrl(`/user_transactions/mass_transfer`),
      form,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return {
      data: response.data.data,
    };
  }

  async postEtagMatchingUpload(formDataValues) {
    const form = new FormData();
    form.append('file', formDataValues.file);

    const response = await this.axios.post(
      this.buildUrl(`/user_transactions/import`),
      form,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return {
      data: response.data.data,
    };
  }
}

export default UserTransactionAPI;
