import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useKeyboardEvent } from '../../../../hooks/keyboardEvent';
import LockIcon from './LockIcon';

const DynamicLink = (props) => {
  const { to } = props;
  const El = to ? Link : 'a';

  return <El {...props} />;
};

DynamicLink.propTypes = {
  to: PropTypes.string,
};

DynamicLink.defaultProps = {
  to: undefined,
};

const Options = ({ onClose, items, onLogout }) => {
  useKeyboardEvent('Escape', onClose);

  return (
    <ul
      id="menu-list-grow"
      className="menu-appear js-user-profile-menu db pa0 pv1 ma0 list w5_5 maxw4_5 maxh5_5 bg-white dark-gray overflow-auto f5 lh-copy br1"
    >
      {items.map(({ label, href, to }) => (
        <li key={href || to} className="db ma0 pa0">
          <DynamicLink
            to={to}
            href={href}
            onClick={onClose}
            className="db pv2 ph3 dark-gray no-underline hover-bg-mid-gray flex items-center"
          >
            {label}
          </DynamicLink>
        </li>
      ))}
      <li key="logout" className="db ma0 pa0">
        <DynamicLink
          href="/"
          onClick={onLogout}
          className="db pv2 ph3 dark-gray no-underline hover-bg-mid-gray flex items-center"
        >
          <React.Fragment>
            <LockIcon />
            Sign out
          </React.Fragment>
        </DynamicLink>
      </li>
    </ul>
  );
};

Options.propTypes = {
  onClose: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default Options;
