const name = 'generator_document';

const shape = {
  document_type: '',
  notes: '',
  document: '',
  document_url: '',
  document_name: '',
  uploaded_by: '',
  created_at: '',
  updated_at: '',
  generator: {
    type: 'generators',
    jsonApi: 'hasOne',
  },
};

const model = {
  name,
  shape,
};

export default model;
