import React, { Suspense, lazy, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory,
} from 'react-router-dom';
import ReactGA4 from 'react-ga4';
import config from '../../config';
import { API_DOCS_DOMAINS } from './App.constants';
import { useAuth } from '../../hooks/auth';
import { useLoading } from '../../hooks/loading';
import { useAppTheme } from '../../hooks/theme';
import { useUser } from '../../hooks/user';
import Adaptive from '../layouts/Adaptive';
import Loading from '../Loading/Loading';

import './App.css';

const Accounts = lazy(() => import('../../pages/Accounts'));
const Account = lazy(() => import('../../pages/Account'));
const Certificates = lazy(() => import('../../pages/Certificates'));
const Dashboard = lazy(() => import('../../pages/Dashboard'));
const ETags = lazy(() => import('../../pages/eTags'));
const Generation = lazy(() => import('../../pages/Generation'));
const Help = lazy(() => import('../../pages/Help'));
const Home = lazy(() => import('../../pages/Home'));
const Login = lazy(() => import('../../pages/Login'));
const Organization = lazy(() => import('../../pages/Organization'));
const Organizations = lazy(() => import('../../pages/Organizations'));
const Programs = lazy(() => import('../../pages/Programs'));
const Generators = lazy(() => import('../../pages/Generators'));
const DGGs = lazy(() => import('../../pages/DGGs'));
const Register = lazy(() => import('../../pages/Register'));
const Reports = lazy(() => import('../../pages/Reports'));
const Transfers = lazy(() => import('../../pages/Transfers'));
const UserOrganizations = lazy(() => import('../../pages/UserOrganizations'));
const UserProfileEdit = lazy(() => import('../../pages/UserProfile/Edit'));
const UserProfileShow = lazy(() => import('../../pages/UserProfile/Show'));
const Markets = lazy(() => import('../../pages/Markets'));
const Notifications = lazy(() => import('../../pages/Notifications'));
const Eligibilities = lazy(() => import('../../pages/Eligibilities'));
const NotFound = lazy(() => import('../../pages/ErrorPages/404.component'));
const Unauthorized = lazy(() => import('../../pages/ErrorPages/401.component'));
const InternalServer = lazy(() =>
  import('../../pages/ErrorPages/500.component'),
);
const FeatureFlags = lazy(() => import('../../pages/FeatureFlags'));

const App = () => {
  const {
    name: themeName,
    region: themeRegion,
    type: themeType,
  } = useAppTheme();
  const { isAppLoading } = useLoading();
  const { isAuthenticated } = useAuth();
  const { user, fetchUser, getUserId } = useUser();
  const userId = getUserId();
  const userIsAuthenticated = isAuthenticated();

  const trackPageView = () => {
    ReactGA4.send({ hitType: 'pageview', page: window.location.pathname });
  };

  const onFetchUser = async () => {
    await fetchUser(userId);
    ReactGA4.gtag('set', 'user_properties', {
      userId: (user && user.id) || userId,
      userName: user && user.fullName,
    });
  };

  const history = useHistory();

  const setGA = async () => {
    if (userIsAuthenticated) {
      onFetchUser();
    }
  };

  useEffect(() => {
    trackPageView(); // catches the init load
    history.listen(trackPageView); // tracks subsuquent loads
  }, [history]);

  useEffect(() => {
    setGA();
  }, []);

  useEffect(() => {
    document.body.classList.add(themeName);
    document.body.classList.add(themeType);
    document.body.classList.add(themeRegion);

    return () => {
      document.body.classList.remove(themeName);
      document.body.classList.remove(themeType);
      document.body.classList.remove(themeRegion);
    };
  }, [themeName, themeType, themeRegion]);

  const isAdminModeEnabled = user?.permissions?.adminModeEnabled ? 'yes' : 'no';
  const userCurrentOrganizationId =
    user?.userOrganization?.current_organization?.id ||
    'not-user-current-org-id';

  const appKey = `${userCurrentOrganizationId}__${isAdminModeEnabled}`;
  const apiDocsUrl =
    API_DOCS_DOMAINS[config.appName] || API_DOCS_DOMAINS.staging;

  return (
    <div
      key={appKey}
      className={`App App__Container ${themeRegion} ${themeName} ${themeType} App--${themeName}`}
    >
      <Router>
        <Adaptive config={config}>
          <Suspense fallback={<Loading />}>
            {!userIsAuthenticated && (
              <Switch>
                <Route path="/login" component={Login} />
                <Route path="/reports/public" component={Reports} />
                <Route path="/register" component={Register} />
                <Route
                  path="/docs"
                  component={() => {
                    window.location.replace(apiDocsUrl);
                    return null;
                  }}
                />
                <Redirect to="/login" />
              </Switch>
            )}

            {userIsAuthenticated && (
              <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/accounts" component={Accounts} />
                <Route path="/account" component={Account} />
                <Route path="/certificates" component={Certificates} />
                <Route path="/dashboard" component={Dashboard} />
                <Route path="/etags" component={ETags} />
                <Route path="/featureflags" component={FeatureFlags} />
                <Route path="/generation" component={Generation} />
                <Route path="/help" component={Help} />
                <Route path="/organization" component={Organization} />
                <Route path="/organizations" component={Organizations} />
                <Route path="/program" component={Programs} />
                <Route path="/programs" component={Programs} />
                <Route path="/generator" component={Generators} />
                <Route path="/generators" component={Generators} />
                <Route path="/projects" component={Generators} />
                <Route path="/dgg" component={DGGs} />
                <Route path="/dggs" component={DGGs} />
                <Route path="/reports" component={Reports} />
                <Route path="/transfers" component={Transfers} />
                <Route path="/eligibilities" component={Eligibilities} />
                <Route path="/eligibility" component={Eligibilities} />
                <Route
                  path="/user-organizations"
                  component={UserOrganizations}
                />
                <Route path="/profile/edit" component={UserProfileEdit} />
                <Route path="/market" component={Markets} />
                <Route path="/markets" component={Markets} />
                <Route exact path="/profile" component={UserProfileShow} />
                <Route
                  path="/docs"
                  component={() => {
                    window.location.replace(apiDocsUrl);
                    return null;
                  }}
                />
                <Route path="/notifications" component={Notifications} />
                <Redirect path="/login" to="/dashboard" />
                <Route path="/404" component={NotFound} />
                <Route path="/401" component={Unauthorized} />
                <Route path="/500" component={InternalServer} />
                <Redirect from="*" to="/404" />
              </Switch>
            )}
          </Suspense>
        </Adaptive>
      </Router>

      {isAppLoading && <Loading />}
    </div>
  );
};

export default App;
