const name = 'program_fuel';

const shape = {
  updated_at: '',
  created_at: '',
  feedstocks: {
    type: 'feedstocks',
    jsonApi: 'hasMany',
  },
  thermal_resource: {
    type: 'thermal_resources',
    jsonApi: 'hasOne',
  },
  program: {
    type: 'programs',
    jsonApi: 'hasOne',
  },
};

const model = {
  name,
  shape,
};

export default model;
