import BaseAPI from './base';
import { formatQueryData } from './helpers';

class TableSummaryAPI extends BaseAPI {
  async getTableSummaryData(model, queryData) {
    const newQueryData = formatQueryData(queryData);
    const response = await this.jsonApi.customRequest(
      'GET',
      `${this.jsonApi.urlFor({ model })}.calc`,
      'calc',
      newQueryData,
    );

    return response;
  }
}

export default TableSummaryAPI;
