const name = 'document';

const shape = {
  created_at: '',
  document_name: '',
  document_url: '',
  notes: '',
  uploaded_by: '',
};

const model = {
  name,
  shape,
};

export default model;
