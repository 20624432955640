const name = 'balancing_authority';

const shape = {
  name: '',
  created_at: '',
  code: '',
};

const model = {
  name,
  shape,
};

export default model;
