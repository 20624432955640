const name = 'pse_code';

const shape = {
  code: '',
  created_at: '',
  updated_at: '',
  organization: {
    type: 'organizations',
    jsonApi: 'hasOne',
  },
};

const model = {
  name,
  shape,
};

export default model;
