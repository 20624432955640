import BaseAPI from './base';
import { formatQueryData } from './helpers';

class DashboardNotificationAPI extends BaseAPI {
  async getDashboardNotifications(queryData) {
    const newQueryData = formatQueryData(queryData);

    const response = await this.jsonApi.findAll('dashboard_notification', {
      ...newQueryData,
    });

    return response;
  }

  async openDashboardNotification(dashboardNotificationId) {
    const url = `${this.jsonApi.urlFor({
      model: 'dashboard_notification',
      id: dashboardNotificationId,
    })}/open`;

    return this.jsonApi.customRequest('POST', url, 'dashboard_notification');
  }
}

export default DashboardNotificationAPI;
