const name = 'generation_validation_curve';

const shape = {
  jan: 0,
  feb: 0,
  mar: 0,
  apr: 0,
  may: 0,
  jun: 0,
  jul: 0,
  aug: 0,
  sep: 0,
  oct: 0,
  nov: 0,
  dec: 0,
  created_at: '',
  generator: {
    type: 'generators',
    jsonApi: 'hasOne',
  },
};

const model = {
  name,
  shape,
};

export default model;
