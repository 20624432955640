import BaseAPI from './base';
import { formatQueryData, getIncludeValues } from './helpers';
import { RECURRING_TRANSFER, RECURRING_TRANSFER_FIELDS } from './constants';

class RecurringTransferAPI extends BaseAPI {
  async getRecurringTransfers(queryData) {
    const newQueryData = formatQueryData(queryData);
    const response = await this.jsonApi.findAll('recurring_transfers', {
      ...newQueryData,
      include: getIncludeValues(
        newQueryData.unique_by,
        RECURRING_TRANSFER[this.resourceType],
      ),
      fields: RECURRING_TRANSFER_FIELDS[this.resourceType],
    });

    return response;
  }

  async getRecurringTransfer(id) {
    const response = await this.jsonApi.find('recurring_transfer', id, {
      include: RECURRING_TRANSFER[this.resourceType].includeAll,
      fields: RECURRING_TRANSFER_FIELDS[this.resourceType],
    });
    return response;
  }

  async createRecurringTransfer(payload) {
    return this.jsonApi.create('recurring_transfer', payload);
  }

  async updateRecurringTransfer(id, payload) {
    return this.jsonApi.update('recurring_transfer', {
      id,
      ...payload,
    });
  }

  async rejectRecurringTransfer(recurringTransferId) {
    const url = `${this.jsonApi.urlFor({
      model: 'recurring_transfer',
      id: recurringTransferId,
    })}/reject`;

    return this.jsonApi.customRequest('PATCH', url, 'recurring_transfer');
  }

  async acceptRecurringTransfer(recurringTransferId) {
    const url = `${this.jsonApi.urlFor({
      model: 'recurring_transfer',
      id: recurringTransferId,
    })}/accept`;

    return this.jsonApi.customRequest('PATCH', url, 'recurring_transfer');
  }
}

export default RecurringTransferAPI;
