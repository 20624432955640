export const GENERATOR_UNITS = {
  rec: {
    includeAll: ['generator', 'contact', 'generator.organization'],
    includeUniqueBy: {
      'contacts.name': ['contact'],
      'contacts.country': ['contact'],
      'contacts.state_province': ['contact'],
      'generator.name': ['generator'],
      'generator.status': ['generator'],
      'generator.facility_name': ['generator'],
      'generator.mrets_id': ['generator'],
      'generator.organization': ['generator'],
    },
  },
};

export const GENERATOR_UNITS_FIELDS = {
  rec: {
    contacts: [
      'street_1',
      'street_2',
      'city',
      'country',
      'state_province',
      'postal_code',
    ],
    generator: ['name', 'facility_name', 'mrets_id', 'organization'],
    organization: ['name'],
  },
};
