import React, { useEffect, useRef, useState } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Popper from '@mui/material/Popper';
import { ButtonBase } from '@mui/material';
import { useUser } from '../../../../hooks/user';
import ProfileIcon from './ProfileIcon';
import CaretDownIcon from './CaretDownIcon';
import Options from './Options';
import { items } from './ProfileSettingsMenu.constants';
import { useAuth } from '../../../../hooks/auth';
import { useMfa } from '../../../../hooks/mfa';

import './ProfileSettingsMenu.scss';

const ProfileSettingsMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const anchorRef = useRef(null);
  const { user, removeUserId } = useUser();
  const open = Boolean(anchorEl);
  const popperId = open ? 'profile-menu-popper' : undefined;
  const { removeToken } = useAuth();
  const { removeMfa } = useMfa();

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      const node = anchorRef.current;
      node.focus();
    }

    prevOpen.current = open;
  }, [open]);

  if (!user) {
    return null;
  }

  const handleToggle = (event) => {
    event.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    removeToken();
    removeMfa();
    removeUserId();
  };

  return (
    <React.Fragment>
      <ButtonBase
        aria-describedby={popperId}
        type="button"
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        className="ProfileSettingsMenu__Button button-reset db h3 ph3 f5 fw4 lh-copy v-mid flex items-center hover-dark-gray hover-bg-mid-gray hover-fill-dark-gray hover-stroke-dark-gray bg-color-transition"
      >
        <ProfileIcon />
        <div className="maxw-nametext minw3_5 truncate ml2">
          {user.fullName}
        </div>
        <CaretDownIcon />
      </ButtonBase>
      <Popper
        anchorEl={anchorEl}
        disablePortal
        className="z-5"
        id={popperId}
        open={open}
        placement="top-end"
      >
        <ClickAwayListener onClickAway={handleClose}>
          <div className="ProfileSettingsMenuCard">
            <Options
              items={items}
              onClose={handleClose}
              onLogout={handleLogout}
            />
          </div>
        </ClickAwayListener>
      </Popper>
    </React.Fragment>
  );
};

export default ProfileSettingsMenu;
