const name = 'generator_aggregate';

const shape = {
  meter_id: '',
  mrets_id: '',
  system_id: '',
  reporting_unit_id: '',
  last_generator_split: {},
  created_at: '',
  updated_at: '',
  organization: {
    type: 'organizations',
    jsonApi: 'hasOne',
  },
  qre: {
    type: 'organizations',
    jsonApi: 'hasOne',
  },
  generators: {
    type: 'generators',
    jsonApi: 'hasMany',
  },
};

const model = {
  name,
  shape,
};

export default model;
