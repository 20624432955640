import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, useLocation } from 'react-router-dom';

import './MainNavMenuItem.scss';

const MainNavMenuItem = ({ icon: Icon, label, path, alternatePath }) => {
  const location = useLocation();
  const rootPath = `/${location?.pathname?.split('/')[1]}`;

  const isActive = () => {
    return path === rootPath || alternatePath === rootPath;
  };

  return (
    <NavLink to={path} className="MainNavMenuItem" isActive={isActive}>
      {Icon && (
        <span className="flex mr3 MainNavMenuItem__Icon">
          <Icon />
        </span>
      )}
      {label}
    </NavLink>
  );
};

MainNavMenuItem.defaultProps = {
  icon: null,
  alternatePath: '',
};

MainNavMenuItem.propTypes = {
  icon: PropTypes.any,
  label: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  alternatePath: PropTypes.string,
};

export default MainNavMenuItem;
