import BaseAPI from '../base';
import {
  formatQueryData,
  getFieldsValues,
  getIncludeValues,
  getSearchParams,
} from '../helpers';
import {
  GENERATION_INCLUDES,
  GENERATION_FIELDS,
  ANNUAL_PRODUCTION_INCLUDES,
  ANNUAL_PRODUCTION_FIELDS,
  VALIDATION_CURVES_FIELDS,
  VALIDATION_CURVES_INCLUDES,
} from './generation.constants';

class GenerationAPI extends BaseAPI {
  async getGenerationActivityLog(queryData, queryParameters) {
    const newQueryData = formatQueryData(queryData);
    const searchParams = getSearchParams(newQueryData, queryParameters);

    const params = {
      ...newQueryData,
      ...searchParams,
    };

    const response = await this.jsonApi.findAll('generation_entry', params);
    return response;
  }

  async getGenerationAnnualProduction(queryData) {
    const newQueryData = formatQueryData(queryData);
    const params = {
      ...newQueryData,
      include: getIncludeValues(
        newQueryData.unique_by,
        ANNUAL_PRODUCTION_INCLUDES[this.resourceType],
      ),
      fields: getFieldsValues(
        newQueryData.unique_by,
        ANNUAL_PRODUCTION_FIELDS[this.resourceType],
        this.resourceType,
      ),
      // fields: ANNUAL_PRODUCTION_FIELDS[this.resourceType],
    };

    const response = await this.jsonApi.findAll('generator', params);
    return response;
  }

  async getGenerationValidationCurves(queryData) {
    const newQueryData = formatQueryData(queryData);
    const params = {
      ...newQueryData,
      include: getIncludeValues(
        newQueryData.unique_by,
        VALIDATION_CURVES_INCLUDES[this.resourceType],
      ),
      fields: getFieldsValues(
        newQueryData.unique_by,
        VALIDATION_CURVES_FIELDS[this.resourceType],
        this.resourceType,
      ),
      // fields: VALIDATION_CURVES_FIELDS[this.resourceType],
    };

    const response = await this.jsonApi.findAll(
      'generation_validation_curve',
      params,
    );
    return response;
  }

  async createGenerationValidationCurves(payload) {
    const response = await this.jsonApi.create(
      'generation_validation_curve',
      payload,
      {
        include: VALIDATION_CURVES_INCLUDES[this.resourceType].includeAll,
      },
    );
    return response;
  }

  async updateGenerationValidationCurves(payload) {
    const response = await this.jsonApi.update(
      'generation_validation_curve',
      payload,
      {
        include: VALIDATION_CURVES_INCLUDES[this.resourceType].includeAll,
      },
    );

    return response;
  }

  async uploadGenerationValidationCurves(formDataValues) {
    const form = new FormData();
    form.append('file', formDataValues.file);

    const response = await this.axios.post(
      `v1/public/${this.resourceType}/generation_validation_curves/import`,
      form,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return {
      data: response.data.data,
    };
  }

  async getGenerationPending(queryData, queryParameters) {
    let response;
    const newQueryData = formatQueryData(queryData);
    const searchParams = getSearchParams(newQueryData, queryParameters);

    try {
      response = await this.jsonApi.findAll('generation_entry', {
        ...newQueryData,
        ...searchParams,
      });
    } catch (e) {
      // no pending
    }
    return response;
  }

  async getGenerationUploads(queryData) {
    const newQueryData = formatQueryData(queryData);

    const response = await this.jsonApi.findAll('generation_upload_report', {
      ...newQueryData,
      include: ['user'],
      fields: {
        user: ['first_name', 'last_name', 'email'],
      },
    });
    return response;
  }

  async postGenerationUpload(formDataValues) {
    const form = new FormData();
    form.append('file', formDataValues.file);
    form.append('type', formDataValues.type);
    form.append('interval', formDataValues.interval);
    form.append('time_zone', formDataValues.timeZone);
    form.append('upload_file', true);
    form.append('unit', formDataValues.unit);

    const response = await this.axios.post(
      `v1/public/${this.resourceType}/generation_entries/import`,
      form,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return {
      data: response.data.data,
    };
  }

  async postFuelGenerationUpload(formDataValues) {
    const form = new FormData();
    form.append('file', formDataValues.file);

    const response = await this.axios.post(
      `v1/public/${this.resourceType}/generation_entries/import_generation_allocation`,
      form,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return {
      data: response.data.data,
    };
  }

  async fetchGenerationReport(generationId) {
    const response = await this.axios.get(
      `v1/public/${this.resourceType}/generation_entries/${generationId}/report`,
    );

    return response;
  }

  async postGenerationRollback(generationId, params) {
    const response = await this.axios.post(
      `v1/public/${this.resourceType}/generation_entries/${generationId}/rollback`,
      params,
    );

    return response;
  }

  async createGenerationEntry(payload) {
    return this.jsonApi.create('generation_entry', payload);
  }

  async updateGenerationEntryAllocation(generationEntryId, allocationPayload) {
    return this.jsonApi.customRequest(
      'PUT',
      `${this.jsonApi.urlFor({
        model: 'generation_entry',
        id: generationEntryId,
      })}/update_allocation`,
      'generation_entry',
      {},
      allocationPayload,
    );
  }

  async acceptGenerationEntry(generationEntryId, notes) {
    return this.jsonApi.customRequest(
      'PUT',
      `${this.jsonApi.urlFor({
        model: 'generation_entry',
        id: generationEntryId,
      })}/accept`,
      'generation_entry',
      {},
      { data: { attributes: { notes } } }, // MRETS-6601
    );
  }

  async rejectGenerationEntry(generationEntryId, notes) {
    return this.jsonApi.customRequest(
      'PUT',
      `${this.jsonApi.urlFor({
        model: 'generation_entry',
        id: generationEntryId,
      })}/reject`,
      'generation_entry',
      {},
      { data: { attributes: { notes } } }, // MRETS-6601
    );
  }

  async disputeGenerationEntry(generationEntryId, notes) {
    return this.jsonApi.customRequest(
      'PUT',
      `${this.jsonApi.urlFor({
        model: 'generation_entry',
        id: generationEntryId,
      })}/dispute`,
      'generation_entry',
      {},
      { data: { attributes: { notes } } }, // MRETS-6601
    );
  }

  async acceptDisputedGenerationEntry(generationEntryId) {
    const url = `${this.jsonApi.urlFor({
      model: 'generation_entry',
      id: generationEntryId,
    })}/accept_disputed`;

    return this.jsonApi.customRequest('PUT', url, 'generation_entry');
  }

  async completeHoldingPeriodGenerationEntry(generationEntryId, notes) {
    return this.jsonApi.customRequest(
      'PUT',
      `${this.jsonApi.urlFor({
        model: 'generation_entry',
        id: generationEntryId,
      })}/complete_holding_period`,
      'generation_entry',
      {},
      { data: { attributes: { notes } } }, // MRETS-6601
    );
  }

  async enqueueGenerationEntry(generationEntryId) {
    return this.jsonApi.customRequest(
      'PATCH',
      `${this.jsonApi.urlFor({
        model: 'generation_entry',
        id: generationEntryId,
      })}/enqueue`,
      'generation_entry',
    );
  }

  async uploadGenerationEntryDocument(generationEntryId, file) {
    const form = new FormData();
    form.append('file', file);

    const response = await this.axios.post(
      `v1/public/${this.resourceType}/generation_entries/${generationEntryId}/document`,
      form,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return {
      data: response.data.data,
    };
  }

  async getGenerationEntries(queryData) {
    const newQueryData = formatQueryData(queryData);
    const response = await this.jsonApi.findAll('generation_entry', {
      ...newQueryData,
      include: getIncludeValues(
        newQueryData.unique_by,
        GENERATION_INCLUDES[this.resourceType],
      ),
      fields: getFieldsValues(
        newQueryData.unique_by,
        GENERATION_FIELDS[this.resourceType],
        this.resourceType,
      ),
    });

    return response;
  }

  async getGenerationEntry(generationId) {
    const response = await this.jsonApi.find('generation_entry', generationId, {
      include: GENERATION_INCLUDES[this.resourceType].includeAll,
      fields: GENERATION_FIELDS[this.resourceType],
    });

    return response;
  }
}

export default GenerationAPI;
