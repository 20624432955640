const name = 'etag';

const shape = {
  etag_id: '',
  start_date: '',
  stop_date: '',
  load: 0,
  load_control_area: '',
  generator_control_area: '',
  total: 0,
  total_unit: '',
  matched: 0,
  matched_unit: '',
  transferred: 0,
  transferred_unit: '',
  remaining: 0,
  remaining_unit: '',
  miscellaneous_token: '',
  generator: {
    type: 'generators',
    jsonApi: 'hasOne',
  },
  importing_organization: {
    type: 'organizations',
    jsonApi: 'hasOne',
  },
};

const model = {
  name,
  shape,
};

export default model;
